import TYPES from '../actions/actionTypes';
import {setSessionStorage, setStorage} from "../services/utils";
import {LocalStorageKey} from "../local_storage_key";

const initialState = {
  ticket: undefined,
  ticketId: undefined,
  isLoading: false,
};

export default function Ticket(state = initialState, action) {
  switch (action.type) {
    case TYPES.GET_TICKET_FAIL:
    case TYPES.UPDATE_TICKET_FAIL:
      return { ...state, ticket: action.data, isLoading: false };

    case TYPES.GET_TICKET_SUCCESS:
      setSessionStorage(LocalStorageKey.kTicketAuth, action.auth);
      return { ...state, ticket: action.data};

    case TYPES.SET_TICKET_ID:
      return { ...state, ticketId: action.data };

    case TYPES.UPDATE_TICKET_SUCCESS:
      return { ...state, isLoading: false };

    case TYPES.UPDATE_TICKET:
      return { ...state, isLoading: true };

    default:
      return state;
  }
}
