import TYPES from "./actionTypes";
import ApiService from "../services/api";

export function getSettingsApi() {
    return async dispatch => {
        const resp = await ApiService.get(`api/customer/setting`,false)

        if (resp.code === '500') {
            dispatch({
                type: TYPES.GET_SETTINGS_FAIL,
            });

            return;
        }
        dispatch({
            type: TYPES.GET_SETTINGS,
            data: {...resp}
        })
    };
}