import ApiService from '../services/api';
import TYPES from './actionTypes';
import {getSessionStorage, getStorage} from "../services/utils";
import {LocalStorageKey} from "../local_storage_key";

export function getTicket({ merchantId, ticketId, auth, onSuccess}) {
  return async dispatch => {
    let extraHeader;

    if (auth && typeof auth !== 'undefined' && auth !== 'undefined') {
      extraHeader = { auth };
    }

    const resp = await ApiService.get(
      `api/customer/ticket/${merchantId}/${ticketId}`,true,false, extraHeader
    );

    if (resp.code !== '200') {
      dispatch({ type: TYPES.GET_TICKET_FAIL, data: { ...resp } });
    } else {
      const { data, auth } = resp;

      dispatch({ type: TYPES.SET_TICKET_ID, data: ticketId });
      dispatch({ type: TYPES.GET_TICKET_SUCCESS, data: data, auth: auth });
      if (onSuccess) {
        onSuccess(auth);
      }
    }
  };
}

export function updateTicket({data, merchantId, ticket: {ticketId}, customParam1, customParam2, customParam3, auth}) {

  let userId = null
  let userName = null
  let userContact = null
  if(auth.user.me){
    if(auth.user.me.name){
      userId = auth.user.me.id;
    }
    if(auth.user.me.name){
      userName = auth.user.me.name;
    }
    if(auth.user.me.phoneNo){
      userContact = auth.user.me.phoneNo.startsWith('+') ? auth.user.me.phoneNo.replace('+', '%2b') : `%2b${auth.user.me.phoneNo}`;
    }
  }

  const generatePosTicketItem = ({
                                   uuid,
                                   id,
                                   name,
                                   price,
                                   remark,
                                   quantity,
                                   addOnForTicket,
                                   printerId,
                                   printerId2,
                                   combo,
                                   subItems,
                                   allowDiscount,
                                   sstOverride,
                                   serviceChargeOverride,
                                   docketName,
                                   createdDate,
                                 }) => ({
    uuid,
    id: id,
    isSaved: false,
    name: name,
    price:
        price -
        addOnForTicket.map(a => a.price).reduce((pre, cur) => cur + pre, 0.0),
    quantity,
    remark,
    selectedAddOns: addOnForTicket,
    printerId,
    printerId2,
    isSelfOrdering: true,
    combo,
    subItems,
    ticketUpdateTime: Date.now(),
    allowDiscount,
    sstOverride,
    serviceChargeOverride,
    docketName,
    createdDate,
  });

  const newTicketItemList = data.map(generatePosTicketItem);

  const requestObject = {
    ticketItemList: newTicketItemList,
  };

  return async dispatch => {
    dispatch({ type: TYPES.UPDATE_TICKET });
    let extraHeader;
    const kTicketAuth = getSessionStorage(LocalStorageKey.kTicketAuth);
    if(kTicketAuth && typeof kTicketAuth !== 'undefined' && kTicketAuth !== 'undefined'){
      extraHeader = {
        auth: kTicketAuth
      };
    }

    let param = '';

    if (customParam1) {
      param = param + 'customParam1=' + customParam1
    }

    if (customParam2) {
      param = param + (param ? '&' : '') + 'customParam2=' + customParam2
    }

    if (customParam3) {
      param = param + (param ? '&' : '') + 'customParam3=' + customParam3
    }

    if (userContact && userId && userName) {
      param = param + (param ? '&' : '') + `customerId=${userId}&customerName=${userName}&customerContact=${userContact}`
    }

    const resp = await ApiService.post(
      `api/customer/ticket/${merchantId}/${ticketId}${param ? '?' + param : ''}`,
      requestObject,
        null,
      extraHeader
    );

    if (resp.code !== '200') {
      dispatch({ type: TYPES.UPDATE_TICKET_FAIL, data: { ...resp } });
      return resp;
    } else {
      dispatch({ type: TYPES.CLEAR_ALL, merchantId });
      dispatch({ type: TYPES.UPDATE_SESSION_STORAGE });
      dispatch({ type: TYPES.UPDATE_TICKET_SUCCESS });
      return resp;
    }
  };
}
