import PropTypes from 'prop-types';
import React from 'react';

const LoginTextInput = ({ className, margin, ...props }) => {
  const defaultClass = `
  rounded-lg 
  m-auto 
  bg-white 
  focus:shadow-lg 
  focus:border-transparent 
  border 
  border-solid 
  border-white 
  font-bold 
  text-aliments block 
  transition 
  duration-500 
  ease-in-out
  `;

  return (
    <input
      className={`${defaultClass} ${className}`}
      pattern='\d*'
      style={{
        height: '62px',
        padding: '0 20px 0 23px',
        borderRadius: '20px',
        margin: margin,
      }}
      placeholder='Phone number'
      type='text'
      {...props}
    />
  );
};

LoginTextInput.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
    margin: PropTypes.string,
};

export default LoginTextInput;
