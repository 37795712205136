import PropTypes from 'prop-types';
import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as globalAction from '../../actions/globalAction';
import * as menuAction from '../../actions/menuAction';
import Button from '../../component/button/button';
import { Loading } from '../../component/loading/loading';
import OperatingHoursComponent from '../../component/operatingHours/operatingHours';
import {
  getQueryFromPropsOrCache,
  isClosingNow,
  path,
  setImageUrlDimension,
  setStorage,
  showPreOrderTime,
} from '../../services/utils';
import './landingPage.scss';
import LandingPageButton from './landingPageButton';
import ModalComponent from '../../component/modal/modal';
import SelectPickUpDialog from '../../component/selectPickUpDialog/selectPickUpDialog';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FBPixel, GA } from '../../reducers/menu';
import { PreOrderSession } from '../../services/constants';
import PreOrderModal from '../checkout/preOrderTime/preOrderModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class LandingPage extends Component {
  state = {
    showOperatingHour: false,
    isClosing: false,
    openPickUpModal: false,
    openPreOrderModal: false,
    selectedSlot: {
      isAsap: true,
      start: new Date(),
      end: new Date().setHours(new Date().getHours() + 1, 0),
      isToday: true,
      tabIndex: 0,
    },
  };

  pickUpType = '';

  constructor(props) {
    super(props);

    const parsedQuery = getQueryFromPropsOrCache(props, ['merchantId']);
    const _orderSlot = JSON.parse(
      sessionStorage.getItem(PreOrderSession.preOrderSlot),
    );

    if (_orderSlot) {
      _orderSlot.start = new Date(_orderSlot.start);
      _orderSlot.end = new Date(_orderSlot.end);

      this.state = {
        ...this.state,
        selectedSlot: _orderSlot,
      };
    }

    // Save merchantId to redux
    const { merchantId } = parsedQuery;
    if (merchantId) {
      props.globalAction.saveParsedQuery(parsedQuery);
    }

    this.getMenu(merchantId);
  }

  componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    snapshot: SS,
  ) {
    GA.sendPageView('Landing Page');
    FBPixel.sendPageView('Landing Page');
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const merchant = path('menu.menuList.merchant')(nextProps);

    if (merchant) {
      this.isMerchantClose(merchant);
    }
  }

  isMerchantClose(merchant, slot) {
    let preOrder = slot ? slot : this.state.selectedSlot;
    //to prevent user from entering when coming from outlet.
    const isClosing = isClosingNow(
      merchant.operatingHours,
      false,
      merchant.supportPreOrder && !preOrder.isAsap ? preOrder.start : null,
    );
    const temporaryClose =
      merchant.pauseTill && merchant.pauseTill >= preOrder.start;
    this.setState({ isClosing: isClosing || temporaryClose });
  }

  toggleOperatingHours = () => {
    this.setState({ showOperatingHour: !this.state.showOperatingHour });
  };

  togglePreOrderModal = () => {
    this.setState({
      openPreOrderModal: !this.state.openPreOrderModal,
    });
  };

  render() {
    const merchant = path('menu.menuList.merchant')(this.props);
    if (!merchant) return <Loading>Loading...</Loading>;
    const {
      isClosing,
      openPickUpModal,
      openPreOrderModal,
      selectedSlot,
    } = this.state;
    const temporaryClose =
      merchant.pauseTill && merchant.pauseTill >= selectedSlot.start;

    // const logoStyle = { backgroundImage: `url(${merchant.primeLogo})` };

    const { supportTakeAway, supportDriveThru, supportDelivery } = merchant;
    return (
      <div
        className='landing-page'
        style={{
          backgroundImage: `url(${setImageUrlDimension(
            merchant.landingBackgroundImg,
            512,
          )})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'auto 100%',
          backgroundPosition: 'center',
          boxShadow: 'inset 0 0 0 2000px rgba(0,0,0,0.5)',
        }}
      >
        <div className='center'>
          <div className='center-contain'>
            <div className='logo'>
              <LazyLoadImage
                style={{
                  paddingBottom: '10px',
                  height: '150px',
                  width: '150px',
                  borderRadius: '22px',
                  objectFit: 'cover',
                }}
                wrapperClassName='img-merchant'
                src={setImageUrlDimension(merchant.primeLogo, 200)}
                effect='blur'
              />
            </div>
            <div className='title'>{merchant.name}</div>
            <div style={{ height: '24px' }} />

            <div className='flex flex-row justify-center'>
              {!isClosing && !!supportTakeAway && (
                <LandingPageButton
                  title={'PICK UP'}
                  src={'/image/take_away.png'}
                  onClick={() =>
                    this.goToMenu(merchant.id, false, supportDriveThru)
                  }
                />
              )}
              {!isClosing && !!supportDelivery && (
                <LandingPageButton
                  title={'DELIVERY'}
                  src={'/image/delivery.png'}
                  onClick={() => this.goToMenu(merchant.id, true)}
                />
              )}

              {isClosing && !temporaryClose && (
                <div>
                  <div className='m-4'>Shop is closed</div>
                  <Button
                    text='More Details'
                    style={{ minWidth: '100px' }}
                    onClick={this.toggleOperatingHours}
                  />
                </div>
              )}

              {temporaryClose && (
                <div className='m-4'>Shop is temporary closed</div>
              )}
            </div>
            {merchant.supportPreOrder && (
              <Button
                style={{
                  marginTop: '10px',
                  maxWidth: '300px',
                  minWidth: '100px',
                  backgroundColor: 'transparent',
                }}
                onClick={() => {
                  this.togglePreOrderModal();
                }}
              >
                <FontAwesomeIcon icon={'clock'} className={'pr-2'} />
                {isClosing ? 'Pre-Order' : showPreOrderTime(selectedSlot, true)}
                &nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={'chevron-right'} />
              </Button>
            )}
            <OperatingHoursComponent
              openWhen={this.state.showOperatingHour}
              toggle={this.toggleOperatingHours}
              selectedMerchant={merchant}
            />
            <PreOrderModal
              isOpen={openPreOrderModal}
              close={() => {
                this.togglePreOrderModal();
              }}
              selectedSlot={selectedSlot}
              merchant={merchant}
              onSelect={slot => {
                this.setState({
                  selectedSlot: slot,
                });
                this.togglePreOrderModal();
                this.isMerchantClose(merchant, slot);
              }}
            />
          </div>
        </div>
        <div className='bottom'>
          <div className='powered-by'>
            <a href='https://aliments.my'>Powered by HUBBO POS</a>
          </div>
        </div>
        {this.setupPopUpPickUpTypeModal(merchant, openPickUpModal)}
      </div>
    );
  }

  setupPopUpPickUpTypeModal = (merchant, openPickUpModal) => {
    return (
      <ModalComponent
        closeIconColor='black'
        openWhen={openPickUpModal}
        close={() =>
          this.setState({
            openPickUpModal: false,
          })
        }
      >
        <SelectPickUpDialog
          merchantId={merchant.id}
          returnPickUpType={pickUpObj => this.getPickUpType(pickUpObj)}
        />
      </ModalComponent>
    );
  };

  getPickUpType = pickUpObj => {
    this.setState({
      openPickUpModal: false,
    });
    this.pickUpType = pickUpObj.name;
    this.goToMenu(pickUpObj.merchantId, false, true, pickUpObj.carPlateNumber);
  };

  getMenu = merchantId => {
    const mId = path('menu.menuList.merchant.id')(this.props);
    if (!mId || Number(mId) !== Number(merchantId)) {
      this.props.menuAction.getMenu(merchantId);
    }
  };

  goToMenu = (
    merchantId,
    isDelivery = false,
    haveDriveThruPickUp = false,
    carPlateNumber = '',
  ) => {
    // Because server will return 'merchantId=/2'
    // then when use the getQueryFunction will get '/2' instead of '2'
    // so purposely set merchantId directly
    // note: save as String, queryString library convert to string.
    // so need consistence with library
    const query = {
      ...getQueryFromPropsOrCache(this.props, ['merchantId']),
      merchantId: String(merchantId),
    };

    if (isDelivery) {
      query['delivery'] = '1';
    } else {
      const haveDriveThruAndNoPickUpSelected =
        haveDriveThruPickUp && !this.pickUpType;
      if (haveDriveThruAndNoPickUpSelected) {
        this.setState({
          openPickUpModal: true,
        });
        return;
      } else {
        if (this.pickUpType) {
          query['pickUpType'] = this.pickUpType;
        } else {
          query['pickUpType'] = 'self-pick-up';
        }

        query['takeAway'] = '1';
      }
    }

    let queryString = JSON.stringify(query);
    setStorage('query', JSON.stringify(queryString));
    this.props.globalAction.saveParsedQuery(queryString);
    this.props.menuAction.getMenu(
      merchantId,
      isDelivery ? 'DELIVERY' : 'PICKUP',
    );

    this.props.history.push(
      `/menu?merchantId=${merchantId}&${
        isDelivery ? 'delivery' : 'takeAway'
      }=1${isDelivery ? '' : `&pickUpType=${query['pickUpType']}`}`,
    );
  };
}

LandingPage.propTypes = {
  globalAction: PropTypes.object,
  menuAction: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    global: state.Global,
    menu: state.Menu,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    globalAction: bindActionCreators(globalAction, dispatch),
    menuAction: bindActionCreators(menuAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
