import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as checkoutAction from '../../actions/checkoutAction';
import * as menuAction from '../../actions/menuAction';
import * as ticketAction from '../../actions/ticketAction';
import AppBar from '../../component/appBar/appBar';
import PrimaryButton from '../../component/button/primaryButton';
import { Loading } from '../../component/loading/loading';
import ModalComponent from '../../component/modal/modal';
import OrderItem from '../../component/orderItem/orderItem';
import { path } from '../../services/utils';
import CartEmpty from '../checkout/cartEmpty';
import '../checkout/checkout.scss';
import { FBPixel, GA } from '../../reducers/menu';
import MenuDetailModal from '../menu/MenuDetailModal';
import {dynamicLandingRoute} from "../../routes";

class DynamicCheckoutPage extends Component {
  constructor(props) {
    super(props);

    GA.sendPageView('Dynamic Checkout Page');
    FBPixel.sendPageView('Dynamic Checkout Page');
    this.state = {
      showConfirmOrder: false,
      errorMessage: '',
      modal: {
        openModal: false,
        modalMessage: undefined,
        callBackAction: undefined,
      },
      selectedCoffee: null,
      selectedUuid: null,
    };

    this.stateless = {
      checkOutCount: 0,
    };
  }

  componentDidMount() {
    if (!this.props.merchant) {
      this.redirectToMenu();
      return;
    }

    // Handle url param pass takeAway condition
    // Default is DINE_IN
    this.stateless.checkOutCount = this.props.checkout.checkOutCount;
  }

  resetCheckOutCount = () => {
    this.stateless.checkOutCount = 0;
    this.props.checkoutAction.resetCheckOutCount();
  };

  quantityOnClick = item => {
    if (item.quantity < 1) {
      this.setModal(
        true,
        () => {
          this.props.checkoutAction.changeQuantity(
            item,
            this.props.global.query.merchantId,
          );
        },
        <span>
          Are you sure want to remove{' '}
          <span className='item-name'>{item.name}</span>?
        </span>,
      );
    } else {
      this.props.checkoutAction.changeQuantity(
        item,
        this.props.global.query.merchantId,
      );
    }

    this.resetCheckOutCount();
  };

  checkboxOnClick = item => {
    this.props.checkoutAction.checkboxOnClick(
      item,
      this.props.global.query.merchantId,
    );

    this.resetCheckOutCount();
  };

  backOnClick = () => {
    this.props.history.goBack();
  };

  confirmClearOnClick = () => {
    this.state.modal.callBackAction && this.state.modal.callBackAction();
    this.closeModalOnClick();
  };

  closeModalOnClick = () => {
    this.setModal();
  };

  closeMaximumOrder = () => {
    this.setState({
      isExceedMaximumOrder: false,
    });
  };

  getCheckOutItems = bySelected => {
    if (
      !this.props.global.query.merchantId ||
      !this.props.checkout.items[this.props.global.query.merchantId]
    ) {
      return [];
    }

    const items = this.props.checkout.items[this.props.global.query.merchantId];

    return bySelected ? items.filter(i => !i.deselected) : items;
  };

  setModal = (
    openModal = false,
    callBackAction = undefined,
    modalMessage = '',
  ) => {
    this.setState({
      modal: {
        openModal,
        modalMessage,
        callBackAction,
      },
    });
  };

  redirectToMenu = () => {
    this.props.history.push(dynamicLandingRoute);
  };

  showConfirmOrder = show => () => {
    this.setState({
      showConfirmOrder: show,
    });
  };

  onClickConfirm = async () => {
    const merchantId = path('merchant.id', this.props);
    const { auth, ticket } = this.props;
    const {
      customParam1,
      customParam2,
      customParam3,
    } = this.props.location.state;

    const selectedItems = this.getCheckOutItems(true);

    const resp = await this.props.ticketAction.updateTicket({
      data: selectedItems,
      merchantId,
      ticket,
      customParam1,
      customParam2,
      customParam3,
      auth,
    });

    if (resp.code === '200') {
      this.props.history.push('/dynamic/thankyou');
    } else if (resp.message === 'api.invalid.order.ticket') {
      this.setState({
        errorMessage: 'Ticket expired, please request at counter.',
      });
    } else {
      this.setState({
        errorMessage: resp.message,
      });
    }
  };

  closeCoffeeModal = () => {
    this.setState({
      selectedCoffee: null,
      selectedUuid: '',
    });
  };

  onCoffeeUpdated = coffee => {
    this.setState({
      selectedCoffee: { ...this.state.selectedCoffee, ...coffee },
    });
  };

  render() {
    const { errorMessage } = this.state;
    const { allowRemark } = this.props.menu.menuList.merchant;

    if (!this.props.merchant) {
      return false;
    }

    if (!this.getCheckOutItems().length)
      return <CartEmpty onClick={this.redirectToMenu} />;

    const { isLoading } = this.props.ticket;

    return (
      <div className={'checkout-container'} ref={this.checkOutPageRef}>
        {!!this.state.submitted && <Loading />}
        {isLoading && <Loading>Placing Order...</Loading>}

        <AppBar goBack={this.props.history.goBack}>
          <div>Order</div>
        </AppBar>

        <div
          className={`item-list-container ${!this.state.modal.openModal &&
            'smooth-touch'}`}
        >
          {this.getCheckOutItems().map((x, i) => (
            <OrderItem
              key={x.name + x.desc + x.addon + i}
              {...x}
              quantityOnClick={this.quantityOnClick}
              checkboxOnClick={this.checkboxOnClick}
              handleOnClicked={() => {
                this.setState({
                  selectedCoffee: {
                    ...x.selectedCoffee,
                    quantity: x.quantity,
                  },
                  selectedUuid: x.uuid,
                });
              }}
            />
          ))}
        </div>

        <PrimaryButton
          onClick={this.showConfirmOrder(true)}
          primaryColor={true}
          bgBtn={true}
        >
          {isLoading ? <div>Loading</div> : <div>Confirm</div>}
        </PrimaryButton>

        <div className='confirm-pay-modal'>
          <ModalComponent openWhen={errorMessage !== ''}>
            <div className='container'>
              <div className='title'>Error</div>
              <div className='body'>
                <div>{errorMessage}</div>
              </div>
            </div>
            <div className='footer'>
              <div
                className='btn btn-primary'
                onClick={() => {
                  this.setState({
                    errorMessage: '',
                  });
                }}
              >
                OK
              </div>
            </div>
          </ModalComponent>
          {this.renderGeneralMessageModal()}
        </div>
        <div className='confirm-pay-modal'>
          <ModalComponent openWhen={this.state.showConfirmOrder}>
            <div className='container'>
              <div className='title'>Confirm Order</div>
              <div className='body'>
                <div>
                  Confirm order will be sending directly to
                  <br /> kitchen for preparation.
                </div>
              </div>
            </div>
            <div className='footer'>
              <div
                className='btn btn-cancel'
                onClick={this.showConfirmOrder(false)}
              >
                Cancel
              </div>
              <div
                className='btn btn-primary'
                onClick={() => {
                  if (!isLoading) {
                    this.onClickConfirm();
                    this.showConfirmOrder(false)();
                  }
                }}
              >
                OK
              </div>
            </div>
          </ModalComponent>
          {this.renderGeneralMessageModal()}
        </div>

        <MenuDetailModal
          mode={'updateCart'}
          allowRemark={allowRemark}
          menu={this.props.menu}
          selectedCoffee={this.state.selectedCoffee}
          onCoffeeUpdated={this.onCoffeeUpdated}
          onCancel={this.closeCoffeeModal}
          onConfirm={coffee => {
            this.props.menuAction.updateCart(
              this.state.selectedUuid,
              coffee,
              this.props.global.query.merchantId,
            );
            this.props.checkoutAction.resetCheckOutCount();
          }}
        />
      </div>
    );
  }

  renderGeneralMessageModal() {
    return (
      <ModalComponent
        openWhen={this.state.modal.openModal}
        close={this.closeModalOnClick}
      >
        <div className={'message'}> {this.state.modal.modalMessage}</div>
        <div className={'confirmation-button-container'}>
          <div className={'cancel-button'} onClick={this.closeModalOnClick}>
            Cancel
          </div>
          <div className={'confirm-button'} onClick={this.confirmClearOnClick}>
            Confirm
          </div>
        </div>
      </ModalComponent>
    );
  }
}

DynamicCheckoutPage.propTypes = {
  merchant: PropTypes.object,
  checkout: PropTypes.object,
  global: PropTypes.object,
  history: PropTypes.object,
  ticket: PropTypes.object,
  checkoutAction: PropTypes.object,
  ticketAction: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    menu: state.Menu,
    checkout: state.Checkout,
    merchant: state.Menu.menuList && state.Menu.menuList.merchant,
    global: state.Global,
    ticket: state.Ticket,
    auth: state.Auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    checkoutAction: bindActionCreators(checkoutAction, dispatch),
    menuAction: bindActionCreators(menuAction, dispatch),
    ticketAction: bindActionCreators(ticketAction, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DynamicCheckoutPage);
