import TYPES from '../actions/actionTypes';

const initialState = {
  query: undefined,
};

export default function Global(state = initialState, action) {
  switch (action.type) {
    case TYPES.SAVE_PARSED_QUERY:
      return {
        ...state,
        query: action.data,
      };
    case TYPES.SAVE_API_KEY:
      return {
        ...state,
        apiKey: action.data,
      };
    default:
      return state;
  }
}
