import PropTypes, {string} from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as deliveryAction from '../../actions/deliveryAction';
import * as globalAction from '../../actions/globalAction';
import AppBar from '../../component/appBar/appBar';
import { AddAddressReq } from '../../model/request/addAddressReq';
import TextInput from './textInput';
import {isValidPhoneNumber} from "../../services/helper";

class AddressFormPage extends Component {
  state = {
    address: '',
    city: '',
    contact: '',
    country: '',
    created_at: undefined,
    customerId: undefined,
    deliveryNoteToDriver: undefined,
    enabled: undefined,
    id: undefined,
    lat: undefined,
    lng: undefined,
    postcode: '',
    recipientName: undefined,
    state: '',
    unitNumber: '',
    updated_at: undefined,
  };

  componentDidMount() {
    const { location } = this.props;

    const result = (location && location.state && location.state.resp) || {};

    console.log(`props`, this.props);

    result.address = result.address.replace(', ' + result.postcode, '');
    result.address = result.address.replace(result.city + ',', '');
    result.address = result.address.replace(result.state + ',', '');
    result.address = result.address.replace(result.country, '');

    this.setState({
      ...result,
      address: result.address,
    });
  }

  render() {
    const state = this.state;

    return (
      <div>
        <AppBar goBack={this.props.history.goBack}>
          <div>Delivery Detail</div>
        </AppBar>
        <form className={'mb-4'} action={'#'} onSubmit={this.onFormSubmit}>
          <div className='form-field'>
            <div className='font-bold'>Name</div>
            <TextInput
              type='text'
              placeholder={'Eg. David'}
              onChange={this.onNameChange}
              defaultValue={this.state.recipientName}
              required
            />
          </div>
          <div className='form-field'>
            <div className='font-bold'>Contact Number</div>
            <div className='flex' >
              +
              <TextInput
                  type='text'
                  placeholder={'Eg. 01123456789'}
                  onChange={this.onContactNumberChange}
                  value={this.state.contact}
                  required
              />
            </div>
          </div>
          <div className='form-field'>
            <div className='font-bold'>Note to Driver</div>
            <TextInput
              type='text'
              placeholder={'Eg. Meet me at the lobby (Optional)'}
              onChange={this.onNoteToDriverChange}
              defaultValue={this.state.deliveryNoteToDriver}
            />
          </div>
          <div className='form-field'>
            <div className='font-bold'>Address</div>
            <TextInput
              type='text'
              placeholder={'Unit Number (Optional)'}
              onChange={this.onUnitNumberChange}
              defaultValue={this.state.unitNumber}
            />
            <TextInput
              readOnly={true}
              type='text'
              placeholder={
                'Eg. No.123, Jalan Pudu. Address 2, Bukit Bintang, 55100 Kuala Lumpur.'
              }
              defaultValue={state.address}
              onChange={this.onAddressChange}
              required
            />
            <div className={'my-2'}>{state.postcode}</div>
            <div className={'my-2'}>{state.city}</div>
            <div className={'my-2'}>{state.state}</div>
            <div className={'my-2'}>{state.country}</div>
          </div>

          <button className='p-2 m-auto w-40 text-center rounded-lg bg-primary text-base block border-none text-gray-700'>
            Save
          </button>
        </form>
      </div>
    );
  }

  onFormSubmit = event => {
    event.preventDefault();
    let phoneNumber = this.state.contact

    if (isValidPhoneNumber(phoneNumber)) {
      // Issue: Library not compatible for some mobile web browser. eg. vivo browser
      // Issue 2: Some web browser "might" have this issue also.
      // const filter = Object.entries(this.state).filter(v => !!v[1]);
      // const addressRequest = new AddAddressReq(Object.fromEntries(filter));
      this.props.deliveryAction.postAddress(this.state).then(result => {
        this.props.history.push('/addressList');
      })
    } else {
      window.alert("Your contact number is invalid, Please enter a valid contact number.");
    }
  };

  onNameChange = ({ target: { value } }) => {
    this.setState({
      recipientName: value,
    });
  };
  onContactNumberChange = ({ target: { value } }) => {
    if(isNaN(value)){
      return;
    }

    let result = value.trim();
    if(value.startsWith('01')){
      result = '6' + result;
    }

    this.setState({
      contact: result,
    });
  };
  onNoteToDriverChange = ({ target: { value } }) => {
    this.setState({
      deliveryNoteToDriver: value,
    });
  };
  onUnitNumberChange = ({ target: { value } }) => {
    this.setState({
      unitNumber: value,
    });
  };
  onAddressChange = ({ target: { value } }) => {
    this.setState({
      address: value,
    });
  };
}

AddressFormPage.propTypes = {
  location: PropTypes.object,
  deliveryAction: PropTypes.object,
  detail: PropTypes.shape({
    address: PropTypes.string,
    city: PropTypes.string,
    contact: PropTypes.string,
    country: PropTypes.string,
    created_at: PropTypes.string,
    customerId: PropTypes.number,
    deliveryNoteToDriver: PropTypes.string,
    enabled: PropTypes.bool,
    id: PropTypes.number,
    lat: PropTypes.string,
    lng: PropTypes.string,
    postcode: PropTypes.string,
    recipientName: PropTypes.string,
    state: PropTypes.string,
    unitNumber: PropTypes.string,
    updated_at: PropTypes.string,
  }),
};

function mapStateToProps(state) {
  return {
    global: state.Global,
    delivery: state.Delivery,
    auth: state.Auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    globalAction: bindActionCreators(globalAction, dispatch),
    deliveryAction: bindActionCreators(deliveryAction, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddressFormPage);
