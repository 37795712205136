import React, { Component } from 'react';

export default class StartUpPage extends Component {
  render() {
    return (
      <div style={{ padding: '20px' }}>
        <p>starting up page</p>
      </div>
    );
  }
}
