import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import ModalComponent from "../../../component/modal/modal";
import React, {useState} from "react";
import {getDayFromNumber, isClosingNow, showPreOrderTime} from "../../../services/utils";
import {PreOrderSession} from "../../../services/constants";

// Apply for take away and delivery only.
// To use this function required object below for selectedTime,
// selectedSlot = {
//   isAsap: true,
//   start: new Date(),
//   end: new Date().setHours(new Date().getHours() + 1, 0)
//   isToday: true
// }
// selectedTime will be null when merchant has pauseTill
export default function PreOrderModal({isOpen, close, selectedSlot, merchant, onSelect}) {
  const [tabIndex, setTabIndex] = useState(selectedSlot ? selectedSlot.tabIndex : 0);

  let _pauseTill:Date;
  const currentTime = new Date();
  if(merchant && merchant.pauseTill){
    _pauseTill = new Date(merchant.pauseTill);
  }

  function getTabDate() {
    let hourAdd = 0;
    let tabList = [1, 2, 3];

    return tabList.map((e, index) => {
      const date = new Date();
      date.setHours(date.getHours() + hourAdd);
      hourAdd = hourAdd + 24;
      const dayOfTheWeek = date.getDay();
      const currentDate = date.getDate();

      return <Tab
          key={index}>{e === 1 ? 'Today' : `${currentDate} ${getDayFromNumber(dayOfTheWeek)}`}</Tab>
    });
  }

  function getTabPanelTime() {
    let hourAdd = 0;
    let tabList = [1, 2, 3];

    return tabList.map((e, index) => {
      const date = new Date();
      date.setHours(date.getHours() + hourAdd);
      hourAdd = hourAdd + 24;
      const dayOfTheWeek = date.getDay();
      const isToday = currentTime.getDay() === dayOfTheWeek;

      let todayOperationList;
      if(merchant){
        todayOperationList = merchant.operatingHours.filter((e) => e.dayOfWeek === dayOfTheWeek) || [];
      } else if (isToday) {
        todayOperationList = [
          {
            timeStart: `${new Date().getHours().toString().padStart(2, '0')}00`,
            timeEnd: '2359'
          }
        ]
      } else {
        todayOperationList = [
          {
            timeStart: '0000',
            timeEnd: '2359'
          }
        ]
      }

      todayOperationList.sort((prev: Date, next: Date) => {
        return parseInt(prev.timeStart) - parseInt(next.timeStart);
      });

      const slotList = [];
      for (const section in todayOperationList) {
        let start = todayOperationList[section].timeStart;
        const end = todayOperationList[section].timeEnd;
        start = currentTime.getHours() > start.slice(0, 2) && isToday ? `${currentTime.getHours()}`.padStart(2, '0') + `${currentTime.getMinutes()}`.padStart(2, '0') : start;
        const each = Math.ceil((end - start) / 100);

        for (let j = 0; j < each; j++) {
          let isAsap = false;
          const twoDigit = start.slice(0, 2);
          let startDate = new Date();
          startDate.setHours(parseInt(twoDigit) + j, 0,0,0)
          startDate.setDate(date.getDate())
          startDate.setMonth(date.getMonth())
          startDate.setFullYear(date.getFullYear())

          let endDate = new Date();
          const endAdd = j + 1;
          endDate.setHours(parseInt(twoDigit) + endAdd, 0,0,0)
          endDate.setDate(date.getDate())
          endDate.setMonth(date.getMonth())
          endDate.setFullYear(date.getFullYear())

          //have pause till
          if (_pauseTill && _pauseTill >= startDate) {
            continue;
          } else if (isToday) {
            //Handle Today
            if (j === 0) {
              //check first slot, is currentDate is greater than startDate then is isAsap
              if(startDate.getTime() <= date.getTime()){
                isAsap = true;
              }else{
                //remove all first time slot as merchant maybe need to preparation for food 1 hr
                continue;
              }
            } else if (Math.sign(endDate.getTime() - date.getTime()) === 1 && ((endDate.getTime() - date.getTime()) / 60000) < 120 && (slotList.find((e) => e.isAsap) || slotList.length === 0)) {
              // Handle diff should be always positive and diff within 2 Hours and either Asap or opening haven't near yet
              continue;
            }
          } else if (j === 0 && !!merchant) {
            //apply on merchant only
            //remove all first time slot as merchant maybe need to preparation for food 1 hr
            continue;
          }

          const _date = {
            start: startDate,
            end: endDate,
            isAsap: isAsap,
            isToday: isToday,
            tabIndex: index
          };

          slotList.push(_date)
        }

      }

      return <TabPanel key={index}>
        <div
            className={`p-4 overflow-scroll ${slotList.length === 0 ? 'flex justify-center items-center' : ''}`}
            style={{height: '350px'}}>
          {slotList.length === 0 && <div>
            {`Sorry, There is no slot for ${isToday ? 'today' : 'this date'}`}
          </div>}
          {slotList.map((slot, index) => <div key={index}
                                              className={`${selectedSlot && slot.start.getDate() === selectedSlot.start.getDate() && slot.start.getHours() === selectedSlot.start.getHours() && 'selected-time'} link p-2 text-left`}
                                              onClick={() => {
                                                sessionStorage.setItem(PreOrderSession.preOrderSlot, JSON.stringify(slot));
                                                onSelect(slot);
                                              }}>
            {showPreOrderTime(slot)}
          </div>)}
        </div>
      </TabPanel>
    });
  }

  return (
      <ModalComponent openWhen={isOpen} close={close} backgroundColor='white'
                      closeIconColor='black' styleWidth={'500px'} styleHeight={'500px'}>
        <div className='text-2xl font-bold font-black p-4' style={{color: 'black'}}>SCHEDULE ORDER</div>
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList>
            {getTabDate()}
          </TabList>
          {getTabPanelTime()}
        </Tabs>
      </ModalComponent>
  );
}