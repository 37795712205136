import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../component/button/button';

const CartEmpty = props => {
  return (
    <div className='flex flex-col items-center justify-center text-center'>
      <FontAwesomeIcon icon={'cart-plus'} className='text-3xl' />
      <div className='my-4'>
        <div> Opss.... Your cart seems like empty.</div>
        <div> Go to menu list to checkout some delicious food.</div>
      </div>
      <Button text={'Go to menu'} onClick={props.onClick} />
    </div>
  );
};

CartEmpty.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CartEmpty;
