import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';
import './index.css';
import './tailwind.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import TYPES from './actions/actionTypes';

import 'react-app-polyfill/ie11';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';
import 'core-js/features/array/flat-map';
import { setStorage } from './services/utils';

const { dispatch } = store;

const config = {
  apiKey: 'AIzaSyBgyM8cJypkSAWbXVbQhZM5JElIud3hUWg',
  authDomain: 'aliments-8508e.firebaseapp.com',
  databaseURL: 'https://aliments-8508e.firebaseio.com',
  projectId: 'aliments-8508e',
  storageBucket: 'aliments-8508e.appspot.com',
  messagingSenderId: '770494357726',
};
window.firebase.initializeApp(config);

axios.interceptors.response.use(
  resp => {
    // dispatch(userSignOut());
    const { request, data, config } = resp;
    const { Auth } = store.getState();
    const isSentApiKey = config.headers.apiKey;

    if (
      data.code &&
      data.message &&
      data.code === '500' &&
      data.message === 'api.key.invalid'
    ) {
      if (request.responseURL.indexOf('api/customer/account') === -1) {
        dispatch({ type: TYPES.TOGGLE_LOGIN_DIALOG, data: true });
      }

      setStorage('apiKey', '', 0);
    } else if (isSentApiKey && Auth.showLoginDialog) {
      dispatch({ type: TYPES.TOGGLE_LOGIN_DIALOG, data: false });
    }

    return resp;
  },
  error => {
    return Promise.reject(error);
  },
);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
