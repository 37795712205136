import PropTypes from 'prop-types';
import React, {Component, useEffect, useState} from 'react';
import {Loading} from '../../component/loading/loading';
import ApiService from '../../services/api';
import {parse, stringify} from 'query-string';
import './outletPage.scss';
import {isClosingNow} from "../../services/utils";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as outletAction from '../../actions/outletAction'
import {OutletSession, PreOrderSession} from "../../services/constants";
import ShowPreOrderTime from "../checkout/preOrderTime/preOrderTime";
import OutletMerchantCard from "./outletMerchantCard";
import {SearchBar} from "../../component/searchBar/searchBar";

class OutletPage extends Component {


    constructor(props) {
        super(props);


        const _orderSlot = JSON.parse(sessionStorage.getItem(PreOrderSession.preOrderSlot));

        if(_orderSlot){
            _orderSlot.start = new Date(_orderSlot.start);
            _orderSlot.end = new Date(_orderSlot.end);
        }

        this.state = {
            merchantList: undefined,
            filterMerchantList: undefined,
            headerImg: undefined,
            searchText: '',
            tableNo: undefined,
            chain: undefined,
            orderSlot: _orderSlot ? _orderSlot : {
                isAsap: true,
                start: new Date(),
                end: new Date().setHours(new Date().getHours() + 1, 0),
                isToday: true,
                tabIndex: 0
            }
        }
    }

    componentDidMount() {
        this.getMerchantList()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let element = document.getElementById("scroll-to-here")
        if (element) {
            element.scrollIntoView({
                behavior: "auto",
                block: "center",
                inline: "center",
            })
        }
    }

    getMerchantList = () => {
        let object = this.props.outlet.object;

        const query = parse(this.props.location.search);
        this.setState({
            tableNo: query.tableNo,
            chain: query.chain,
        })
        const stringParam = stringify({
            chain: query.chain,
            wlCode: query.wlCode,
            partner: query.partner,
        });
        if (!object) {
            ApiService.get(`api/merchant?${stringParam}`).then(resp => {
                let {merchants, headerImg} = resp;
                this.setState({
                    headerImg: headerImg
                })

                if (merchants) {
                    merchants = merchants.filter(merchant =>
                        (process.env.PUBLIC_URL.includes('dev') ? true : !merchant.devOnly) && (merchant.operatingHours.length !== 0) &&
                        ((merchant.supportDelivery || merchant.supportTakeAway || merchant.supportDriveThru))
                        || (this.state.tableNo && this.state.chain))

                    if (merchants.length === 1) {
                        if (query.tableNo) {
                            sessionStorage.setItem(OutletSession.tableNo, query.tableNo);
                            this.props.history.push(`/menu?merchantId=${merchants[0].id}&tableNo=${query.tableNo}&noPopup=1`)
                        } else {
                            const tableNo = sessionStorage.getItem(OutletSession.tableNo);
                            if(tableNo){
                                sessionStorage.clear();
                            }

                            this.props.history.push(`/landing?merchantId=${merchants[0].id}`)
                        }
                        return;
                    }

                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition(
                            position => {
                                this.successGeoCallBack(position, merchants)
                            },
                            positionError => {
                                // alert(positionError.message);
                                this.errorGeoCallBack(merchants);
                            },
                            {
                                enableHighAccuracy: true,
                                timeout: 5000,
                                maximumAge: 0,
                            },
                        );
                    } else {
                        this.updateMerchantState(...merchants)
                    }
                }
            })
        } else {
            // use when navigate back
            this.setState({
                headerImg: object.headerImg,
            })
            this.updateMerchantState(...object.merchantList)

            let searchText = this.props.outlet.searchText
            if (searchText) this.filterMerchantList(searchText, object.merchantList)
        }
    }

    successGeoCallBack = (position, merchants) => {
        const {longitude, latitude} = position.coords;

        merchants.sort((a, b) => {
            const isClosingA = isClosingNow(a.operatingHours);
            const isClosingB = isClosingNow(b.operatingHours);

            const [lat1, lng1] = a.coordinate.split(',');
            const [lat2, lng2] = b.coordinate.split(',');

            const d1 = distance(lat1, lng1, latitude, longitude);
            const d2 = distance(lat2, lng2, latitude, longitude);

            return (isClosingA === isClosingB) ? d1 - d2 : isClosingA ? 1 : -1;
        });

        merchants = merchants.filter(merchant => {
            const [lat, lng] = merchant.coordinate.split(',');
            const distanceDiff = distance(lat, lng, latitude, longitude);
            return distanceDiff <= 50;
        })
        this.updateMerchantState(...merchants)
    }

    errorGeoCallBack = (merchants) => {
        merchants.sort((a, b) => {
            const isClosingA = isClosingNow(a.operatingHours);
            const isClosingB = isClosingNow(b.operatingHours);

            return (isClosingA === isClosingB) ? 0 : isClosingA ? 1 : -1;
        });
        this.updateMerchantState(...merchants)
    }

    updateMerchantState = (...merchants) => {
        if (!this.props.outlet.object) {
            this.props.outletAction.saveMerchantList(merchants, this.state.headerImg)
        }

        this.setState({
            merchantList: merchants,
            filterMerchantList: merchants
        })
    }

    onSearchHandler = (e) => {
        let value = e.target.value
        this.props.outletAction.saveOutletScrollPosition('');
        this.props.outletAction.saveLastSearchText('');

        this.filterMerchantList(value)
    }

    filterMerchantList(value, obj) {
        this.setState({
            searchText: value
        })
        if (!!value) {
            let result = (obj ? obj : this.state.merchantList).filter(merchant => {
                let merchantName = '';
                if (merchant.name) {
                    merchantName = merchant.name.toLowerCase();
                }

                let merchantDesc = '';
                if (merchant.desc) {
                    merchantDesc = merchant.desc.toLowerCase();
                }

                let merchantAddress = '';
                if (merchant.addr) {
                    merchantAddress = merchant.addr.toLowerCase();
                }

                let lowerCaseValue = value.toLowerCase()
                return merchantName.includes(lowerCaseValue) ||
                    merchantDesc.includes(lowerCaseValue) ||
                    merchantAddress.includes(lowerCaseValue)
            })
            this.setState({filterMerchantList: result})
        } else {
            this.setState({filterMerchantList: this.state.merchantList})
        }
    }

    render() {
        const {filterMerchantList, searchText, tableNo, chain, orderSlot} = this.state;
        return (
            <div style={{backgroundColor: "#ffffff"}}>
                <GenerateHeader headerImg={this.state.headerImg}/>
                <SearchBar style={{margin: "0 2.0rem"}} value={searchText} disabled={!this.state.merchantList}
                                   onChange={this.onSearchHandler} hint={`Search for ${chain ? 'outlets' : 'restaurants'}`}/>
                {!tableNo && <ShowPreOrderTime padding={'1.25rem 2.0rem'} selectedSlot={orderSlot} onSelect={
                    (slot) => {
                        this.setState({
                            orderSlot: slot
                        })
                    }
                }/>}
                {!filterMerchantList ? (
                    <Loading>Getting outlet...</Loading>
                ) : (
                    filterMerchantList.map(merchant => <OutletMerchantCard key={merchant.id} props={this.props} merchant={merchant}
                                                                           selectedSlot={orderSlot} tableNo={tableNo}
                                                                           searchText={searchText} chain={chain}
                                                                           onSelect={(slot) => {
                                                                               this.setState({
                                                                                   orderSlot: slot
                                                                               });
                                                                           }
                                                                           }/>)
                )}
            </div>
        )
    }
}

function GenerateHeader({headerImg}) {
    return (
        <React.Fragment>
            {
                headerImg ? (
                    <div className='w-full'>
                        <LazyLoadImage style={{width: "inherit"}} wrapperClassName="outerHeader" effect="blur"
                                       src={headerImg}/>
                    </div>
                ) : (
                    <div className='w-full text-center shadow-sm py-5'>Outlet</div>
                )
            }
        </React.Fragment>
    )
}

// https://www.geodatasource.com/developers/javascript
function distance(lat1, lon1, lat2, lon2, unit = 'K') {
    if (lat1 === lat2 && lon1 === lon2) {
        return 0;
    } else {
        const radlat1 = (Math.PI * lat1) / 180;
        const radlat2 = (Math.PI * lat2) / 180;
        const theta = lon1 - lon2;
        const radtheta = (Math.PI * theta) / 180;
        let dist =
            Math.sin(radlat1) * Math.sin(radlat2) +
            Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = (dist * 180) / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit === 'K') {
            dist = dist * 1.609344;
        }
        if (unit === 'N') {
            dist = dist * 0.8684;
        }
        return dist;
    }
}

OutletPage.propTypes = {
    history: PropTypes.object,
};

// ChainListTile.propTypes = {
//   title: PropTypes.string,
//   subtitle: PropTypes.string,
//   className: PropTypes.string,
//   onClick: PropTypes.func,
// };

function mapStateToProps(state) {
    return {
        outlet: state.Outlet,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        outletAction: bindActionCreators(outletAction, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OutletPage);