import React from 'react';
import './style.css';

export function SuccessSign(props) {
  return (
    <div
      className='o-circle c-container__circle o-circle__sign--success'
      {...props}
    >
      <div className='o-circle__sign'></div>
    </div>
  );
}

export function ErrorSign(props) {
  return (
    <div
      className='o-circle c-container__circle o-circle__sign--failure'
      {...props}
    >
      <div className='o-circle__sign' {...props}></div>
    </div>
  );
}
