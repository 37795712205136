import React from 'react';
import PropTypes from 'prop-types';

export function SearchBar({ value, disabled, onChange, hint, style }) {
  return (
    <div className='flex pt-5' style={style}>
      <div
        className='flex flex-1'
        style={{
          border: '1.2px solid #CFD8DC',
          borderRadius: '14px',
          padding: '12px',
          alignItems: 'center',
        }}
      >
        <input
          disabled={disabled}
          value={value}
          onChange={onChange}
          className='flex-1 outline-none'
          style={{ border: 'unset', fontSize: '0.9rem' }}
          placeholder={hint}
          type='text'
        />
        <img
          src={`${process.env.PUBLIC_URL}/image/Search.png`}
          width='16px'
          height='16px'
        />
      </div>
    </div>
  );
}

SearchBar.prototype = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  hint: PropTypes.string.isRequired,
};

export default SearchBar;
