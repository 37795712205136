import React from 'react';
import PropTypes from 'prop-types';

export default function TextInput(props) {
  const { name, type = 'text', label, id, required } = props;

  return (
    <div className='mb-5'>
      {label && (
        <label
          htmlFor='email'
          className={`block mb-2 text-sm font-medium ${
            required ? 'required' : ''
          }`}
        >
          {label}
        </label>
      )}
      <input
        name={name}
        type={type}
        id={id}
        className='w-full bg-transparent border-0 border-b-2 border-gray-300 rounded-none focus:outline-none focus:border-yellow-500 m-0 pl-0 pb-1'
        required={required}
      />
    </div>
  );
}

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  id: PropTypes.string,
};
