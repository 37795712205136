import * as queryString from 'query-string';
import uuid from 'uuid';
import ApiService from './api';
import {getStorage, setStorage} from './utils';

export const WECHAT_PROVIDER = 'wechatLogin';
export const FIREBASE_REDIRECT_RESULT = 'getFirebaseRedirectResult';

export default async function login(provider, wechatCode = undefined) {
  // Get login wechatCode
  if (provider === WECHAT_PROVIDER && !wechatCode) {
    const origin = window.location.origin;
    const path = '/menu';
    const query = decodeURIComponent(
      queryString.stringify(JSON.parse(getStorage('query'))),
    );
    const url = encodeURIComponent(`${origin}${path}?${query}`);
    const loginUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa9d48c542aa1e260&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`;

    window.location.replace(loginUrl);
    return Promise.resolve();
  }

  const param = {};

  if (provider === WECHAT_PROVIDER) {
    param.wechatCode = wechatCode;
  }
  // Firebase Auth
  else if (provider.providerId) {
    await firebaseLogin(provider);
  }
  // Firebase Get Result
  else if (provider === FIREBASE_REDIRECT_RESULT) {
    const firebaseResult = await firebaseGetRedirectResult();
    param.email = firebaseResult.user.email;

    if (firebaseResult.credential) {
      const { credential } = firebaseResult;
      switch (credential.providerId) {
        case 'google.com':
          param.googleToken = credential.idToken;
          break;
        case 'facebook.com':
          param.fbToken = credential.accessToken;
          break;
      }
    }
  }

  return alimentLogin(param);
}

export async function firebasePhoneLogin(phoneNumber, token) {
  return alimentLogin({ phoneNo: String(phoneNumber), phoneNoToken: token });
}

export async function firebaseLogin(provider) {
  return window.firebase.auth().signInWithRedirect(provider);
}

export async function firebaseGetRedirectResult() {
  const redirectResult = await window.firebase.auth().getRedirectResult();
  const { user, credential } = redirectResult;

  if (credential && credential.accessToken) {
    console.log(user.email);
    return redirectResult;
  }
  return null;
}

async function alimentLogin(params, isGuest = false) {
  let deviceId = getStorage('uuid');

  if (!deviceId) {
    deviceId = uuid.v4();
    setStorage('uuid', deviceId);
  }

  let anonymousId;
  if(isGuest){
    anonymousId = deviceId;
  }

  const resp = await ApiService.post('/api/customer/login', {
    ...params,
    deviceId,
    anonymousId
  },null, true);

  const { apiKey } = resp;

  if (apiKey) {
    setStorage('apiKey', apiKey);
  }
  return Promise.resolve(resp);
}

export async function loginWithGuest(){
  return await alimentLogin(null, true);
}

export async function loginWithSmsOTP(phoneNumber, otp) {
  const params = {
    phoneNo: phoneNumber,
    phoneNoOTP: otp,
  }

  return await alimentLogin(params);
}

export async function getOTPBySMS(phoneNumber){
  return await ApiService.get(`/api/customer/otp/${phoneNumber}`,);
}
