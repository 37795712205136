export default class DeliveryQuotation {
  id;
  merchantId;
  addressId;
  customerId;
  deliveryPartnerId;
  partner;
  currency;
  orderRef;
  status;
  deliveryStatus;
  driverId;
  driverName;
  driverContact;
  driverPlateNumber;
  driverLastLat;
  driverLastLng;
  driverPhoto;
  errorMsg;
  amount;
  created_at;
  updated_at;
  orderReceivedDate;
  driverFoundDate;
  preparingDate;
  driverPickedUpDate;
  deliveredDate;
  cancelledDate;
  customerDeliveryAddress;
  deliveryPartner;
  retry;
  ownDelivery;
  originalAmount;

  constructor({
    id,
    merchantId,
    addressId,
    customerId,
    deliveryPartnerId,
    partner,
    currency,
    orderRef,
    status,
    deliveryStatus,
    driverId,
    driverName,
    driverContact,
    driverPlateNumber,
    driverLastLat,
    driverLastLng,
    driverPhoto,
    errorMsg,
    amount,
    created_at,
    updated_at,
    orderReceivedDate,
    driverFoundDate,
    preparingDate,
    driverPickedUpDate,
    deliveredDate,
    cancelledDate,
    customerDeliveryAddress,
    deliveryPartner,
    retry,
    ownDelivery, originalAmount,
  }) {
    this.id = id;
    this.merchantId = merchantId;
    this.addressId = addressId;
    this.customerId = customerId;
    this.deliveryPartnerId = deliveryPartnerId;
    this.partner = partner;
    this.currency = currency;
    this.orderRef = orderRef;
    this.status = status;
    this.deliveryStatus = deliveryStatus;
    this.driverId = driverId;
    this.driverName = driverName;
    this.driverContact = driverContact;
    this.driverPlateNumber = driverPlateNumber;
    this.driverLastLat = driverLastLat;
    this.driverLastLng = driverLastLng;
    this.driverPhoto = driverPhoto;
    this.errorMsg = errorMsg;
    this.amount = amount;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.orderReceivedDate = orderReceivedDate;
    this.driverFoundDate = driverFoundDate;
    this.preparingDate = preparingDate;
    this.driverPickedUpDate = driverPickedUpDate;
    this.deliveredDate = deliveredDate;
    this.cancelledDate = cancelledDate;
    this.customerDeliveryAddress = customerDeliveryAddress;
    this.deliveryPartner = deliveryPartner;
    this.retry = retry;
    this.ownDelivery = ownDelivery;
    this.originalAmount = originalAmount;
  }
}
