import moment from 'moment';
import * as queryString from 'query-string';
import { curry, path as ramdaPath } from 'ramda';
import api from './api';
import { store } from '../store/store';

const decimalFormatter = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const noDecimalFormatter = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

function getCurrentMerchantCurrency() {
  return store.getState().Menu.menuList.merchant.currency;
}

export function parsePrice(value) {
  const currency = getCurrentMerchantCurrency();
  switch (currency) {
    case 'Rp':
    case '₫':
      return noDecimalFormatter.format(value);
    default:
      return decimalFormatter.format(value);
  }
}

export function toTwoDecimal(value, allowZero = true) {
  const newValue = value || 0;
  const floatValue = parseFloat(newValue).toFixed(3);

  if (Number(newValue) === 0 && !allowZero) {
    return '';
  }

  return floatValue % 1 !== 0
    ? floatValue.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
    : parseFloat(newValue).toFixed(2);
}

export function roundparsePrice(value) {
  return Math.round((value + 0.00001) * 100) / 100;
}

export function getDateFromNow(
  value,
  showDaysBefore,
  dateFormat = 'DD MMMM YYYY h:mm a',
) {
  if (!value) {
    return null;
  }

  const diff = moment().diff(value, 'days');
  const fromNow = moment(value).fromNow();
  const date = moment(value).format(dateFormat);

  return showDaysBefore > 0 && diff < showDaysBefore ? fromNow : date;
}

export function isWechat() {
  return false;
  // return !!window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i);
}

export function isDevice() {
  //判断是android还是ios还是web
  const ua = navigator.userAgent.toLowerCase();
  return !!ua.match(/iPhone\sOS/i) || !!ua.match(/iPad/i)
    ? 'iOS'
    : ua.match(/Android/i)
    ? 'Android'
    : 'Web';
}

export function openUrl(url) {
  try {
    window.location.replace(url);
  } catch (e) {
    window.location.href = url;
  }
}

//Set expireDays = 0, to delete cookies
function setCookie(c_name, value, expireDays) {
  let expireDate = new Date();
  expireDate.setDate(expireDate.getDate() + expireDays);

  const cookieName = c_name + '=';
  const cookieValue = escape(value);
  const cookieExpireDate =
    expireDays == null ? '' : ';expires=' + expireDate.toGMTString();
  document.cookie = cookieName + cookieValue + cookieExpireDate;
}

function getCookie(c_name) {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(c_name + '=');
    if (c_start !== -1) {
      c_start = c_start + c_name.length + 1;
      let c_end = document.cookie.indexOf(';', c_start);
      if (c_end === -1) c_end = document.cookie.length;
      return unescape(document.cookie.substring(c_start, c_end));
    }
  }
  return '';
}

export function setStorage(name, value, expireDays) {
  setCookie(name, value, expireDays);

  if (expireDays === 0) {
    localStorage.removeItem(name);
  } else {
    localStorage.setItem(name, value);
  }
}

export function getStorage(name) {
  const fromCookie = getCookie(name);
  const fromLocal = localStorage.getItem(name) || '';

  return fromCookie || fromLocal;
}

export function setSessionStorage(name, value) {
  sessionStorage.setItem(name, value);
}

export function getSessionStorage(name) {
  return sessionStorage.getItem(name) || '';
}

export function getDayFromNumber(day = 0) {
  const dateDay = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  return dateDay[day];
}

export function isClosingNow(timeAllow = [], isMenu = false, preOrderDate) {
  const todayDay = preOrderDate ? preOrderDate.getDay() : new Date().getDay();
  //todo 10 minutes delay for current
  const todayTime = preOrderDate
    ? preOrderDate.getHours() * 100 + preOrderDate.getMinutes()
    : new Date().getHours() * 100 + new Date().getMinutes();

  const formatToTimeString = time => {
    return moment(time, 'HH:mm a').format('h:mm A');
  };

  // open today but not within operating hours
  const willOpenToday = () => {
    const opening = timeAllow.filter(
      time =>
        time.dayOfWeek === todayDay &&
        todayTime >= Number(time.timeStart) &&
        todayTime <= Number(time.timeEnd),
    );
    return !!opening.length;
  };

  if (isMenu && !timeAllow.length) {
    return false;
  }

  const timeAllowInDay = timeAllow.map(time => time.dayOfWeek).sort();

  let isNotAvailableNow = false;
  let nextAvailableTime = null;

  // not open today
  isNotAvailableNow = !timeAllowInDay.includes(todayDay) || !willOpenToday();

  // no operating hours
  if (!timeAllowInDay.length) {
    // coming soon
    nextAvailableTime = true;
  } else if (isNotAvailableNow) {
    // check from today
    nextAvailableTime = timeAllow.find(
      time => time.dayOfWeek === todayDay && Number(time.timeStart) > todayTime,
    );

    // if from today then set isFromToday
    // else find next available day
    nextAvailableTime = nextAvailableTime
      ? {
          ...nextAvailableTime,
          isFromToday: true,
        }
      : timeAllow.find(time => time.dayOfWeek > todayDay) || timeAllow[0];

    nextAvailableTime.desc =
      (nextAvailableTime.isFromToday
        ? ''
        : `${getDayFromNumber(nextAvailableTime.dayOfWeek)}, `) +
      `${formatToTimeString(
        nextAvailableTime.timeStart,
      )} - ${formatToTimeString(nextAvailableTime.timeEnd)}`;

    nextAvailableTime.shortDesc =
      (nextAvailableTime.isFromToday
        ? ''
        : `${getDayFromNumber(nextAvailableTime.dayOfWeek)}, `) +
      `${formatToTimeString(nextAvailableTime.timeStart)}`;
  }

  return nextAvailableTime;
}

const _path = (path, object) => ramdaPath(path.split('.'), object);
/**
 * A wrapper for Ramda Path, to make path seperate by '.'
 * Retrieve the value at a given path.
 *
 * @func
 * @category Object
 * @typedefn Idx = String | Int
 * @sig [Idx] -> {a} -> a | Undefined
 * @param {String} path The path to use.
 * @param {Object} obj The object to retrieve the nested property from.
 * @return {*} The data at `path`.
 * @see R.prop
 * @example
 *
 *      R.path('a.b', {a: {b: 2}}); //=> 2
 *      R.path('a.b', {c: {b: 2}}); //=> undefined
 */
export const path = curry(_path);

// https://gist.github.com/gordonbrander/2230317
export const randomId = () => {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return (
    '_' +
    Math.random()
      .toString(36)
      .substr(2, 9)
  );
};

export const getQueryFromPropsOrCache = (props, requiredParamList = []) => {
  const checkAllIn = obj => requiredParamList.every(key => obj && !!obj[key]);
  const {
    location: { search, pathname },
  } = props;

  const query = queryString.parse(search);
  if (checkAllIn(query) || (query.merchantId && query.ticketId)) {
    return query;
  }

  const cached = path('global.query', props);
  if (checkAllIn(cached)) {
    const url = [pathname, queryString.stringify(cached)].join('?');
    props.history.replace(url);
    return cached;
  }

  return {};
};

// Credit David Walsh (https://davidwalsh.name/javascript-debounce-function)

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(func, wait, immediate) {
  let timeout;

  return function executedFunction() {
    const context = this;
    const args = arguments;

    const later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

export function getValueFromKeyAtSetting(key: '', settingObj: {}) {
  let link = '';
  if (settingObj.object) {
    for (let index in settingObj.object.settings) {
      const setting = settingObj.object.settings[index];
      if (setting['key'].replace(`${api.getWLCode()}.`, '') === key) {
        link = setting['value'];
        break;
      }
    }
  }
  return link;
}

// To use this function required object below,
// preOrderDate = {
//   isAsap: true,
//   start: new Date(),
//   end: new Date().setHours(new Date().getHours() + 1, 0)
//   isToday: true
// }
export function showPreOrderTime(slot, showDate: false) {
  if (!slot) {
    return 'Please select a pre-order time slot';
  } else if (slot.isAsap) {
    return 'ASAP (next 60 mins)';
  }

  let startHourString = '';
  let startMinuteString = '';
  let startTimeZone = '';
  if (slot.start.constructor === Date) {
    const start: Date = slot.start;
    startHourString = start.getHours().toString();
    startMinuteString = start
      .getMinutes()
      .toString()
      .padStart(2, '0');

    if (start.getHours() < 12) {
      if (start.getHours() === 0) {
        startHourString = '12';
      }
      startTimeZone = 'AM';
    } else {
      if (start.getHours() !== 12) {
        startHourString = `${start.getHours() - 12}`;
      }
      startTimeZone = 'PM';
    }
    startHourString = startHourString.padStart(2, '0');
  }

  let endHourString = '';
  let endMinuteString = '';
  let endTimeZone = '';
  if (slot.end.constructor === Date) {
    const end: Date = slot.end;
    endHourString = end.getHours().toString();
    endMinuteString = end
      .getMinutes()
      .toString()
      .padStart(2, '0');

    if (end.getHours() < 12) {
      if (end.getHours() === 0) {
        endHourString = '12';
      }
      endTimeZone = 'AM';
    } else {
      if (end.getHours() !== 12) {
        endHourString = `${end.getHours() - 12}`;
      }
      endTimeZone = 'PM';
    }
    endHourString = endHourString.padStart(2, '0');
  }

  const output = `${startHourString}:${startMinuteString} ${startTimeZone} - ${endHourString}:${endMinuteString} ${endTimeZone}`;

  if (showDate) {
    return `${
      showDate
        ? slot.isToday
          ? 'Today '
          : `${slot.start.getDate()} ${getDayFromNumber(slot.start.getDay())} `
        : ''
    }(${output})`;
  }

  return output;
}

export function setImageUrlDimension(originalUrl, dimension, mode = 'min') {
  if (!originalUrl) return '';
  try {
    let url = new URL(originalUrl);
    let params = new URLSearchParams(url.search);
    params.set('dimension', dimension);
    params.set('mode', mode);
    return `${url.origin}${url.pathname}?${params.toString()}`;
  } catch (e) {
    return originalUrl;
  }
}

export function shouldRequestServerOTP(finalNumber) {
  const esmsAllowedCountryCode = ['+60', '+65'];
  return esmsAllowedCountryCode.some(number => finalNumber.startsWith(number));
}

