import React, { PureComponent } from 'react';
import './wechatBlocker.scss';
import { isDevice } from '../../services/utils';

export default class WechatBlocker extends PureComponent {
  render() {
    return (
      <div className={'wechat-blocker'}>
        <div className='img-container'>
          <img
            src={`${process.env.PUBLIC_URL}/image/open_in.png`}
            alt='open_in_browser'
          />
        </div>

        <div className={'message'}>
          <div>
            <span>1) Click </span>
            <img
              src={`${process.env.PUBLIC_URL}/image/more_button.png`}
              alt='more button'
            />
          </div>
          <div>
            2) Click "Open {isDevice() === 'iOS' ? 'in Safari' : 'with Browser'}
            "
          </div>
        </div>
      </div>
    );
  }
}
