import { now } from 'moment';

export function isSnoozed(snoozeTill) {
  if (snoozeTill === null || snoozeTill === undefined) return false;
  return snoozeTill >= now();
}

export function isNotSnoozed(snoozeTill) {
  return !isSnoozed(snoozeTill);
}
