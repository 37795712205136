import PropTypes from 'prop-types';
import React, { Component } from 'react';

class DeliveryMap extends Component {
  componentDidMount() {
    const { google } = window;
    const { customerLatLng, driverLatLng } = this.props;

    // Init
    const mapElement = document.getElementById('delivery-map');

    this.map = new google.maps.Map(mapElement, {
      center: driverLatLng,
      zoom: 16,
      disableDefaultUI: true,
      gestureHandling: 'cooperative',
    });

    this.marker = new google.maps.Marker({
      position: driverLatLng,
      map: this.map,
      title: 'Driver',
      icon: `${process.env.PUBLIC_URL}/image/delivery_person.png`,
    });

    this.customerMarker = new google.maps.Marker({
      position: customerLatLng,
      map: this.map,
      title: 'Customer',
    });
  }

  componentDidUpdate(props) {
    this.map.setCenter(props);
    this.marker.setPosition(props);
  }

  render() {
    return (
      <div
        id={'delivery-map'}
        className={'w-full'}
        style={{ height: '150px' }}
      ></div>
    );
  }
}

DeliveryMap.propTypes = {
  customerLatLng: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,
  driverLatLng: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,
};

export default DeliveryMap;
