import axios, { AxiosRequestConfig } from 'axios';
import { getStorage } from './utils';

export default class ApiService {
  static async get(url, sentApiKey, ignoreError = false, extraHeader) {
    let headers = this.getApiKey(sentApiKey);
    headers['wlCode'] = this.getWLCode();

    if (extraHeader) {
      headers = {
        ...headers,
        ...extraHeader,
      };
    }

    try {
      const resp = await axios.get(`${process.env.REACT_APP_ENV}${url}`, {
        headers: headers,
      });
      return resp.data;
    } catch (err) {
      console.group(`API ERROR: GET`);
      if (!ignoreError) alert('err', 'Api Error'); //todo: temp
      console.log(err);
      console.groupEnd();
    }
  }

  static async post(url, request = {}, apiKey = null, extraHeader) {
    let headers = {
      apiKey: apiKey ? apiKey : getStorage('apiKey'),
      wlCode: this.getWLCode(),
    };

    if (extraHeader) {
      headers = {
        ...headers,
        ...extraHeader,
      };
    }

    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_ENV}${url}`,
        request,
        {
          headers: headers,
        },
      );
      return resp.data;
    } catch (err) {
      // FIXME: NOT PROPER HANDLING ERROR
      console.group(`API ERROR: POST`);
      console.log(err);
      console.groupEnd();
    }
  }

  static async put(url, request = {}, apiKey = null) {
    const headers = {
      apiKey: apiKey ? apiKey : getStorage('apiKey'),
      wlCode: this.getWLCode(),
    };

    try {
      const resp = await axios.put(
        `${process.env.REACT_APP_ENV}${url}`,
        request,
        {
          headers: headers,
        },
      );
      return resp.data;
    } catch (err) {
      // FIXME: NOT PROPER HANDLING ERROR
      console.group(`API ERROR: POST`);
      console.log(err);
      console.groupEnd();
    }
  }

  static async delete(url) {
    const config = {
      headers: {
        apiKey: getStorage('apiKey'),
        wlCode: this.getWLCode(),
      },
    };
    try {
      const resp = await axios.delete(
        `${process.env.REACT_APP_ENV}${url}`,
        config,
      );

      return resp.data;
    } catch (e) {
      console.group(`API ERROR: DELETE`);
      console.log(e);
      console.groupEnd();
    }
  }

  static getWLCode() {
    return process.env.REACT_APP_WLCODE;
  }

  static getApiKey(sentApiKey = false) {
    return sentApiKey ? { apiKey: getStorage('apiKey') } : {};
  }

  static async getExternalURL(url) {
    const resp = await axios.get(url);
    return resp.data;
  }

  static async getV2(url, sentApiKey, ignoreError = false, extraHeader) {
    let baseUrl = `${process.env.REACT_APP_ENV}${url}`;
    let headers = this.getApiKey(sentApiKey);
    headers['wlCode'] = this.getWLCode();

    if (extraHeader) {
      headers = {
        ...headers,
        ...extraHeader,
      };
    }

    if (url.startsWith('http')) {
      baseUrl = url;
    }

    return axios.get(baseUrl, {
      headers: headers,
    });
  }

  static async postV2(url, request = {}, apiKey = null, extraHeader) {
    let baseUrl = `${process.env.REACT_APP_ENV}${url}`;
    let headers = {
      apiKey: apiKey ? apiKey : getStorage('apiKey'),
      wlCode: this.getWLCode(),
    };

    if (extraHeader) {
      headers = {
        ...headers,
        ...extraHeader,
      };
    }

    if (url.startsWith('http')) {
      baseUrl = url;
    }

    return axios.post(baseUrl, request, {
      headers: headers,
    });
  }
}
