import React from 'react';
import ImageLoader from '../imageLoader/imageLoader';
import './loading.scss';

export const Loading = props => (
  <div className={'loading'} onTouchMove={e => e.preventDefault()}>
    <ImageLoader />
    <div>{props.children}</div>
  </div>
);
