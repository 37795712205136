export const INVOICE_STATUS = {
  NEW: 'NEW',
  PENDING: 'PENDING',
  SUBMITTED: 'SUBMITTED',
};

export const ERROR_REASON = {
  invalid_uuid: 'invalid_uuid',
  known_exception: 'known_exception',
  pending_invoice: 'pending_invoice',
};
