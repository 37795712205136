import PropTypes from 'prop-types';
import React from 'react';

const TextInput = ({ className, ...props }) => {
  const lgClass = 'lg:my-1 lg:text-lg';
  return (
    <input
      className={`w-full bg-transparent border-0 border-b-2 border-gray-300 rounded-none focus:outline-none focus:border-yellow-500 m-0 p-0 ${lgClass} ${className}`}
      type='text'
      {...props}
    />
  );
};

TextInput.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default TextInput;
