import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Base64 } from 'js-base64';
import * as PropTypes from 'prop-types';
import * as queryString from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as checkoutAction from '../../actions/checkoutAction';
import * as globalAction from '../../actions/globalAction';
import * as menuAction from '../../actions/menuAction';
import AppBar from '../../component/appBar/appBar';
import { Loading } from '../../component/loading/loading';
import {
  getStorage,
  isClosingNow,
  parsePrice,
  path,
  setImageUrlDimension,
} from '../../services/utils';
import '../coffeePopup/selectCoffeePopup.scss';
import '../menu/menu.scss';
import { FBPixel, GA } from '../../reducers/menu';
import MenuPromoList, {
  MenuPromo,
} from '../../component/menuPromoList/menuPromoList';
import SearchBar from '../../component/searchBar/searchBar';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ImagePlaceholder } from '../menu/menu';
import MenuDetailModal from '../menu/MenuDetailModal';
import { isNotSnoozed, isSnoozed } from '../../utils/orderUtils';
import {dynamicLandingRoute} from "../../routes";

class DynamicMenuPage extends Component {
  constructor(props) {
    super(props);

    const query = queryString.parse(props.location.search);
    const cached = path('global.query', props);
    GA.sendPageView('Dynamic Menu Page');
    FBPixel.sendPageView('Dynamic Menu Page');

    const haveQuery = query.merchantId && query.ticketId;
    const haveCache = !!cached;

    if (haveQuery && !haveCache) {
      props.history.push(`${dynamicLandingRoute}?${queryString.stringify(query)}`);
    }

    this.state = {
      selectedCategory: 0,
      isRemarkFocusing: false,
      loading: false,
      shouldExpandCategory: '',
      customParam1: query.customParam1 || undefined,
      customParam2: query.customParam2 || undefined,
      customParam3: query.customParam3 || undefined,
      searchMenu: '',
    };

    this.stateless = {
      clickFromSideBar: false,
      isScrollPositionChecked: false,
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    // restore scroll position
    if (
      nextProps.menu.scrollPosition &&
      this.coffeeListContainer &&
      nextProps.global.query &&
      nextProps.menu.scrollPosition.id ===
        Number(nextProps.global.query.merchantId) &&
      !this.stateless.isScrollPositionChecked
    ) {
      this.stateless.isScrollPositionChecked = true;
      this.coffeeListContainer.scrollTop = nextProps.menu.scrollPosition.value;
    }
  }

  getMenu = merchantId => {
    if (
      !this.props.menu.menuList ||
      (merchantId &&
        this.props.menu.menuList.merchant.id !== Number(merchantId))
    ) {
      this.props.menuAction.getMenu(merchantId, 'DINE_IN');
    }
  };

  getCheckOutItems = () => {
    if (
      !this.props.global.query.merchantId ||
      !this.props.checkout.items[this.props.global.query.merchantId]
    ) {
      return [];
    }

    return this.props.checkout.items[this.props.global.query.merchantId];
  };

  getBrainTreeToken = merchantId => {
    // no token, has merchant id, has apiKey
    !this.props.menu.brainTreeToken &&
      merchantId &&
      getStorage('apiKey') &&
      this.props.menuAction.getBrainTreeToken(merchantId);
  };

  openCoffeeModal = coffee => {
    let addOnByCategory = {};
    let totalAddOnPrice = 0;
    const { addOnCategories } = this.props.menu.menuList;

    coffee.addOns.forEach(addOn => {
      const haveAddOnCategory =
        addOn.addOnCategoryId && addOnCategories[addOn.addOnCategoryId];
      const addOnId = haveAddOnCategory
        ? addOnCategories[addOn.addOnCategoryId].name
        : 'General';
      if (!addOnByCategory[addOnId]) {
        addOnByCategory[addOnId] = { list: [], minOrder: 0 };
      }

      addOnByCategory[addOnId].list.push(addOn);
      addOnByCategory[addOnId].minOrder = haveAddOnCategory
        ? addOnCategories[addOn.addOnCategoryId].minOrder
        : 0;
    });

    // handle addOn that min selected is 1, and only 1 option available.
    Object.entries(addOnByCategory).forEach(([addOnName, value]) => {
      if (addOnName === 'General' || value.minOrder !== 1) {
        return;
      }

      const notSnoozedAddOns = value.list.filter(o => isNotSnoozed(o.snoozeTill));

      if (notSnoozedAddOns.length === 1) {
        const first = notSnoozedAddOns[0];
        first.checked = true;
        totalAddOnPrice += first.price;
      }
    });

    this.setState({
      selectedCoffee: {
        ...coffee,
        quantity: 1,
        otherRequest: '',
        addOns: addOnByCategory,
        totalAddOnPrice,
      },
    });
  };

  closeCoffeeModal = () => {
    this.setState({
      selectedCoffee: null,
      shouldExpandCategory: '',
    });
  };

  onCoffeeUpdated = coffee => {
    this.setState({
      selectedCoffee: { ...this.state.selectedCoffee, ...coffee },
    });
  };

  onRemarkFocus = () => {
    // setTimeout to prevent container height change before keyboard showup
    setTimeout(() => {
      this.setState({
        isRemarkFocusing: true,
      });
    });
  };

  onRemarkBlur = () => {
    setTimeout(() => {
      this.setState({
        isRemarkFocusing: false,
      });
    }, 10);
  };

  getAddOnCategoryByName = name => {
    const { addOnCategories } = this.props.menu.menuList;
    let category;
    Object.keys(addOnCategories).forEach(c => {
      if (addOnCategories[c].name === name) {
        category = addOnCategories[c];
      }
    });

    return category;
  };

  renderAddOnText = (category, isSingleSelect) => {
    const singleSelectText = 'Choose 1 option';
    const multiSelectText = 'Choose your add-on among options below';

    if (!category) {
      return <div>{isSingleSelect ? singleSelectText : multiSelectText}</div>;
    } else {
      const minOrderText = `At least ${category.minOrder} option(s)`;
      const maxOrderText = `No more than ${category.maxOrder} option(s)`;

      if (!!category.maxOrder && !!category.minOrder) {
        return (
          <React.Fragment>
            <div>{minOrderText}</div>
            <div>{maxOrderText}</div>
          </React.Fragment>
        );
      } else if (category.maxOrder) {
        return (
          <div>{category.maxOrder === 1 ? singleSelectText : maxOrderText}</div>
        );
      } else {
        if (!category.minOrder || category.minOrder === 0) {
          return (
            <div>{isSingleSelect ? singleSelectText : multiSelectText}</div>
          );
        }

        return <div>{minOrderText}</div>;
      }
    }
  };

  renderImageLoader = () => (
    <div className='lds-facebook'>
      <div />
      <div />
      <div />
    </div>
  );

  renderCoffeeDetails = (coffee, list) => {
    const merchant = this.props.menu.menuList.merchant;
    const { isClosing } = coffee;
    const isSnoozing = isSnoozed(list.snoozeTill);
    const menuImage = list.image
      ? list.image
      : this.props.menu.menuList.merchant.logo;

    return (
      <div
        key={list.id}
        className={
          'detail-container ' + ((isClosing || isSnoozing) && 'detail-overlay')
        }
        onClick={() =>
          isClosing || isSnoozing ? {} : this.openCoffeeModal(list)
        }
      >
        <div className='image-container'>
          <LazyLoadImage
            src={setImageUrlDimension(menuImage, 100)}
            placeholder={<ImagePlaceholder />}
          />
        </div>
        <div className='detail'>
          <div className='title-en'>{list.name}</div>
          <div className='title-cn'>{list.shortDesc}</div>
          <div className='price'>
            {merchant.currency} {parsePrice(list.price)}{' '}
            <span className={`${list.normalPrice ? 'normal-price' : 'hidden'}`}>
              {merchant.currency} {parsePrice(list.normalPrice)}
            </span>
          </div>
          {this.renderEachItemAmountOrder(list)}
        </div>
        {coffee.isClosing && (
          <div className='text'>
            <div>Available at</div>
            <div>{coffee.isClosing.desc}</div>
          </div>
        )}
      </div>
    );
  };

  renderEachItemAmountOrder(item) {
    let list = this.getCheckOutItems();
    list = list.filter(e => {
      return e.id === item.id;
    });

    let total = 0;
    for (const index in list) {
      total += list[index].quantity;
    }

    if (total < 1) {
      return <FontAwesomeIcon icon='plus' />;
    }

    return (
      <div className={'order-amount'}>
        <div className={'amount'}>{total}</div>
      </div>
    );
  }

  scrollTo = (index, coffee) => {
    this.stateless.clickFromSideBar = true;

    this.setState({
      selectedCategory: index,
    });
    this.coffeeListContainer.scrollTop = this[coffee.name].offsetTop - 70 - 54; //to remove extra height from search bar and app bar;
    setTimeout(() => {
      this.stateless.clickFromSideBar = false;
    });
  };

  listOnScroll = menuList => {
    if (this.stateless.clickFromSideBar) {
      return;
    }

    const listScrollTop = this.coffeeListContainer.scrollTop;
    const { categories } = menuList;

    for (let i = 0; i < categories.length; i++) {
      const current = categories[i];
      const currentRefTop = this[current.name].offsetTop;
      let next, nextRefTop, selected;

      // not last index
      if (i < categories.length - 1) {
        next = categories[i + 1];
        nextRefTop = this[next.name].offsetTop;
      }

      // last index
      if (!next && listScrollTop >= currentRefTop) {
        selected = i;
      } else if (
        next &&
        listScrollTop >= currentRefTop &&
        listScrollTop < nextRefTop
      ) {
        selected = i;
      }

      if (selected >= 0) {
        this.setState({
          selectedCategory: selected,
        });
      }
    }
  };

  renderSideBar = menuList => {
    const { selectedCategory } = this.state;

    return (
      <React.Fragment>
        {menuList.categories.map((coffee, index) => (
          <div
            key={`${coffee.name}-${index}`}
            className={' ' + (selectedCategory === index && 'selected')}
            onClick={() => this.scrollTo(index, coffee)}
          >
            {coffee.name}
          </div>
        ))}
      </React.Fragment>
    );
  };

  renderCoffeeList = menuList => {
    return (
      <React.Fragment>
        {menuList.categories.map(coffee => (
          <div
            key={coffee.id}
            className='list-container'
            ref={ref => (this[coffee.name] = ref)}
          >
            <div className='header'>{coffee.name}</div>
            {coffee.list.map(list => this.renderCoffeeDetails(coffee, list))}
          </div>
        ))}
      </React.Fragment>
    );
  };

  goToCheckoutPage = () => {
    const checkOutItem = this.props.checkout.items[
      this.props.global.query.merchantId
    ];
    const params = Base64.encode(JSON.stringify(checkOutItem));
    const { customParam1, customParam2, customParam3 } = this.state;
    // save scroll position when go to checkout page only
    this.props.menuAction.saveScrollPosition(
      this.props.menu.menuList.merchant.id,
      this.coffeeListContainer.scrollTop,
    );

    // added orders params for mobile side
    this.props.history.push({
      pathname: `/dynamic/checkout`,
      search: '?orders=' + params,
      state: {
        customParam1: customParam1,
        customParam2: customParam2,
        customParam3: customParam3,
      },
    });
  };

  renderCheckout = () => {
    const length = this.getCheckOutItems()
      .map(item => item.quantity)
      .reduce((item, total) => {
        return item + total;
      }, 0);

    return (
      <div
        className={'checkout-footer ' + (!!length && 'expand')}
        onClick={this.goToCheckoutPage}
      >
        <span>
          {length} Order{length > 1 && 's'}
        </span>
        <FontAwesomeIcon icon='chevron-right' />
      </div>
    );
  };

  render() {
    const { location, menu } = this.props;
    const { searchMenu } = this.state;

    const parsedQuery = queryString.parse(location.search);

    if (
      !menu.menuList ||
      (parsedQuery &&
        parsedQuery.merchantId &&
        menu.menuList &&
        Number(parsedQuery.merchantId) !== menu.menuList.merchant.id)
    ) {
      return <Loading>Loading...</Loading>;
    }

    const menuList = {
      ...this.props.menu.menuList,
      categories: this.props.menu.menuList.categories
        .map(menu => {
          return { ...menu, isClosing: isClosingNow(menu.timeAllows, true) };
        })
        .sort((next, current) => {
          if (current.isClosing) {
            return -1;
          }
          return 0;
        }),
    };

    menuList.categories.map(e => {
      e.list = e.list.filter(
        item =>
          !searchMenu ||
          item.name.toLowerCase().includes(searchMenu.toLowerCase()),
      );

      return e;
    });
    menuList.categories = menuList.categories.filter(e => e.list.length !== 0);

    const promos: [MenuPromo] = this.props.menu.menuList.promos;
    let { allowRemark } = this.props.menu.menuList.merchant;

    if (allowRemark === null || allowRemark === undefined) {
      allowRemark = true;
    }

    return (
      <div className='coffee-page'>
        <AppBar goBack={this.props.history.goBack}>
          <div>Menu</div>
        </AppBar>
        <div
          className={
            'coffee-container ' +
            (this.getCheckOutItems().length && 'coffee-container-expand')
          }
        >
          <div
            className={`side-bar ${!this.state.selectedCoffee &&
              'smooth-touch'}`}
          >
            {this.renderSideBar(menuList)}
          </div>
          <div className={'coffee-section'}>
            <SearchBar
              style={{ margin: '0px 15px' }}
              value={searchMenu}
              hint={'Search Menu'}
              onChange={e => {
                this.setState({
                  searchMenu: e.target.value,
                });
              }}
            />
            <div
              className={`coffee-list ${!this.state.selectedCoffee &&
                'smooth-touch'}`}
              onScroll={() => this.listOnScroll(menuList)}
              ref={ref => (this.coffeeListContainer = ref)}
            >
              <MenuPromoList list={promos} />
              {this.renderCoffeeList(menuList)}
            </div>
          </div>
        </div>
        {this.renderCheckout()}

        <MenuDetailModal
          allowRemark={allowRemark}
          menu={menu}
          selectedCoffee={this.state.selectedCoffee}
          onCoffeeUpdated={this.onCoffeeUpdated}
          onCancel={this.closeCoffeeModal}
          onConfirm={coffee => {
            this.props.menuAction.addToCart(
              coffee,
              this.props.global.query.merchantId,
            );
            this.props.checkoutAction.resetCheckOutCount();
          }}
        />
      </div>
    );
  }
}

DynamicMenuPage.propTypes = {
  location: PropTypes.object,
  menu: PropTypes.object,
  history: PropTypes.object,
  menuAction: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    menu: state.Menu,
    checkout: state.Checkout,
    global: state.Global,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    menuAction: bindActionCreators(menuAction, dispatch),
    globalAction: bindActionCreators(globalAction, dispatch),
    checkoutAction: bindActionCreators(checkoutAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DynamicMenuPage);
