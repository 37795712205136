import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import TextInput from './components/TextInput';
import OrderDetails from './components/OrderDetails';
import SelectOptions from './components/SelectOptions';
import identificationTypes from '../../assets/einvoice/identification_types.json';
import states from '../../assets/einvoice/states.json';
import countries from '../../assets/einvoice/countries.json';
import { bindActionCreators } from 'redux';
import * as eInvoiceAction from '../../actions/eInvoiceAction';
import { connect } from 'react-redux';
import { ErrorSign, SuccessSign } from '../../component/sign';
import clsx from 'clsx';
import ModalComponent from '../../component/modal/modal';
import ReCAPTCHA from 'react-google-recaptcha';
import { ERROR_REASON, INVOICE_STATUS } from './constants';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import './style.css';
import useUrlQuery from '../../hooks/useUrlQuery';

export const FNB = 'fnb';
export const FUEL = 'fuel';

function EInvoiceSubmissionPage(props) {
  const { einvoice: state, eInvoiceAction } = props;
  const {
    eInvoice,
    merchant,
    errorFetching,
    isFetching,
    error,
    isSubmitting,
    errorSubmitting,
    isSubmitted,
  } = state;

  const { uuid } = useParams();
  const urlQuery = useUrlQuery();
  const target = urlQuery.get('target') === FUEL ? FUEL : FNB;
  const recaptchaRef = React.createRef();

  const [recaptchaValue, setRecaptchaValue] = useState();
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [isForeigner, setIsForeigner] = useState(false);
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
  const [countdown, setCountdown] = useState(10);
  const [contactNumber, setContactNumber] = useState('');

  // Redirect to the invoice page after 5 seconds
  useEffect(() => {
    if (eInvoice.status !== INVOICE_STATUS.SUBMITTED) {
      return;
    }

    if (countdown === 0) {
      setCountdown(null);
      window.location.href = eInvoice.invoiceUrl;
    }

    if (!countdown) return;

    const intervalId = setInterval(() => {
      setCountdown(countdown - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [countdown, eInvoice]);

  // Get invoice and order data
  useEffect(() => {
    eInvoiceAction.getEInvoice(uuid, false, target);
  }, []);

  // Handle submit error
  useEffect(() => {
    setShowErrorDialog(!!errorSubmitting);

    if (errorSubmitting) {
      recaptchaRef.current.reset();
      setRecaptchaValue(undefined);
    }
  }, [errorSubmitting]);

  // Reload page on submission success
  useEffect(() => {
    if (!isSubmitted) {
      return;
    }

    eInvoiceAction.getEInvoice(uuid, false, target);
  }, [isSubmitted]);

  // Background fetch e-invoice data every 10 seconds
  useEffect(() => {
    if (eInvoice.status !== INVOICE_STATUS.PENDING) {
      return;
    }

    const intervalId = setInterval(() => {
      eInvoiceAction.getEInvoice(uuid, true, target);
    }, 10000);

    return () => clearInterval(intervalId);
  }, [eInvoice.status]);

  const submit = async event => {
    event.preventDefault();

    if (!recaptchaValue || !privacyPolicyAccepted) {
      return;
    }

    const formData = new FormData(event.target);

    const formValues = {
      uuid,
      buyer: {
        name: formData.get('name'),
        contactNumber,
        email: formData.get('email'),
        idType: formData.get('idType'),
        idNumber: formData.get('idNumber'),
        tin: formData.get('tin'),
        isForeigner,
        sstRegNo: formData.get('sstRegNo'),
        address: {
          addressLine0: formData.get('addressLine0'),
          addressLine1: formData.get('addressLine1'),
          addressLine2: formData.get('addressLine2'),
          cityName: formData.get('city'),
          stateCode: formData.get('state'),
          countryCode: formData.get('country'),
          postalCode: formData.get('postcode'),
        },
      },
      recaptchaResponse: recaptchaValue,
    };

    eInvoiceAction.submitEInvoice(formValues, target);
  };

  const handleErrorFetching = () => {
    if (
      [ERROR_REASON.invalid_uuid, ERROR_REASON.pending_invoice].includes(
        errorFetching.reason,
      )
    ) {
      return (
        <div className='flex flex-1 flex-col items-center justify-center'>
          <span className='cloud-uploading'></span>
          <p className='text-center pt-12 mb-2 font-bold'>
            Your Invoice is still processing
          </p>
          <p className='text-center mt-0'>
            Please refresh this page in 10 minutes.
          </p>
        </div>
      );
    }

    return (
      <div className='flex flex-1 flex-col items-center justify-center'>
        <ErrorSign />
        <h1 className='title px-8 mt-8 text-center'>{errorFetching.message}</h1>
      </div>
    );
  };

  const handleProcessedInvoice = () => {
    return (
      <div className='flex flex-1 flex-col items-center justify-center'>
        {eInvoice.status === INVOICE_STATUS.SUBMITTED ? (
          <React.Fragment>
            <SuccessSign />
            <h1 className='title mt-8'>
              Your e-Invoice has been successfully submitted
            </h1>
            <p className='text-center px-8'>
              You can{' '}
              <a href={eInvoice.invoiceUrl} target='_blank'>
                click here
              </a>{' '}
              to access the QR code and view your e-Invoice on the MyInvois
              Portal.
            </p>
            <p className='text-center'>
              Redirecting you in {countdown} seconds
            </p>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <span className='app-loader'></span>
            <h1 className='title mt-8 text-center px-8'>
              Your e-Invoice request has been submitted and is pending
              verification. This may take up to 2 minutes.
            </h1>
          </React.Fragment>
        )}
      </div>
    );
  };

  if (isFetching) {
    return (
      <div className='flex flex-1 flex-col items-center justify-center'>
        <span className='app-loader'></span>
        <p>Getting your Invoice data</p>
      </div>
    );
  }

  if (errorFetching) {
    return handleErrorFetching();
  }

  if (
    eInvoice.status === INVOICE_STATUS.SUBMITTED ||
    eInvoice.status === INVOICE_STATUS.PENDING
  ) {
    return handleProcessedInvoice();
  }

  return (
    <div className='p-3'>
      <div className='receipt mt-2'>
        <div className='bg-white px-8 pb-4'>
          <div className='flex flex-col items-center justify-center'>
            <h1>e-Invoice Submission</h1>
            <img
              className='w-32 h-32'
              src={merchant.logo}
              alt={`${merchant.name} logo`}
            />
            <p className={'title'}>{merchant.name}</p>
          </div>

          <OrderDetails />
        </div>
      </div>

      {error && error.message && (
        <div className='bg-red-500 px-6 py-1 mt-4'>
          <p className='text-white'>{error.message}</p>
        </div>
      )}

      <div className='px-8 bg-white'>
        <h2 className='text-md pt-8'>User Information</h2>
        <h2 className='title text-md mb-6'>Personal Details</h2>

        <form action='' method='POST' onSubmit={submit}>
          <div className='flex flex-col'>
            <div className='flex-1'>
              <TextInput name='name' label='Name' required />
            </div>

            <div className='flex flex-row'>
              <div className='flex-1 mr-3'>
                <label
                  htmlFor='email'
                  className={`block mb-2 text-sm font-medium required`}
                >
                  Mobile No
                </label>
                <PhoneInput
                  country={'my'}
                  preferredCountries={['my']}
                  enableSearch={true}
                  countryCodeEditable={false}
                  defaultMask={'............'}
                  alwaysDefaultMask={true}
                  inputClass={
                    'border-0 border-b-2 border-gray-300 rounded-none focus:outline-none focus:border-yellow-500'
                  }
                  onChange={phone => setContactNumber(`+${phone}`)}
                />
              </div>
              <div className='flex-1 ml-3'>
                <TextInput name='email' label='Email' />
              </div>
            </div>

            <div className='flex-1'>
              <SelectOptions
                required
                label='Identification Type'
                name='idType'
                options={identificationTypes.map(type => ({
                  label: type.name,
                  value: type.code,
                }))}
              />
            </div>

            <div className='flex-1'>
              <TextInput
                name='idNumber'
                label='Identification Number'
                required
              />
            </div>
          </div>

          <h2 className='title text-md mb-6'>Address</h2>
          <div className='flex flex-col'>
            <div className='flex-1'>
              <TextInput name='addressLine0' label='Address Line 0' required />
            </div>
            <div className='flex-1'>
              <TextInput name='addressLine1' label='Address Line 1' />
            </div>
            <div className='flex-1'>
              <TextInput name='addressLine2' label='Address Line 2' />
            </div>
            <div className='flex flex-row'>
              <div className='flex-1 mr-3'>
                <TextInput name='postcode' label='Postal Code' />
              </div>
              <div className='flex-1 ml-3'>
                <TextInput name='city' label='City' required />
              </div>
            </div>
            <div className='flex flex-row'>
              <div className='flex-1 mr-3'>
                <SelectOptions
                  required
                  label='State'
                  name='state'
                  options={states.map(item => ({
                    label: item.State,
                    value: item.Code,
                  }))}
                />
              </div>
              <div className='flex-1 ml-3'>
                <SelectOptions
                  required
                  label='Country'
                  name='country'
                  defaultValue='MYS'
                  options={countries.map(country => ({
                    label: country.Country,
                    value: country.Code,
                  }))}
                />
              </div>
            </div>
          </div>

          <h2 className='title text-md mb-6'>Tax Details</h2>
          <div className='flex flex-col'>
            <div className='flex-1'>
              <TextInput
                name='tin'
                label='Tax Identification Number (TIN)'
                required={!isForeigner}
              />
            </div>
            <div className='flex flex-1 align-middle'>
              <input
                type='checkbox'
                id='is_foreigner'
                name='is_foreigner'
                checked={isForeigner}
                onChange={e => {
                  setIsForeigner(e.target.checked);
                }}
              />
              <label htmlFor='is_foreigner'>I am a foreigner</label>
            </div>
          </div>

          <h2 className='title text-md mb-6'>SST Details</h2>
          <div className='flex flex-col'>
            <div className='flex-1'>
              <TextInput name='sstRegNo' label='SST Registration Number' />
            </div>
          </div>

          <div className='flex flex-1 pb-2 items-start'>
            <input
              type='checkbox'
              id='privacy_policy'
              name='privacy_policy'
              checked={privacyPolicyAccepted}
              onChange={e => {
                setPrivacyPolicyAccepted(e.target.checked);
              }}
            />
            <label htmlFor='privacy_policy' className='pb-4 text-gray-600'>
              By completing and submitting this form, you consent to the
              collection, use, processing, and disclosure of your personal data
              by Grab for the purpose of complying with LHDN e-invoice
              submission. You acknowledge that the processing of your personal
              data will be in governed in accordance{' '}
              <a href='https://www.grab.com/my/terms-policies/privacy-notice'>
                Grab Privacy Notice
              </a>{' '}
              and LHDN data processing requirements.
            </label>
          </div>

          <div className='flex flex-col items-center justify-center'>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={() => {
                setRecaptchaValue(recaptchaRef.current.getValue());
              }}
            />
          </div>

          <button
            type='submit'
            className={clsx(
              'bg-gray-400 text-white p-3 mt-8 w-full rounded-lg border-0 font-bold mb-8',
              { 'opacity-50 cursor-not-allowed': isSubmitting },
              {
                'opacity-50 cursor-not-allowed':
                  !recaptchaValue || !privacyPolicyAccepted,
              },
              { 'bg-primary': recaptchaValue && privacyPolicyAccepted },
            )}
          >
            Submit
          </button>
        </form>
      </div>

      <ModalComponent openWhen={showErrorDialog} className={'h-20'}>
        {errorSubmitting && (
          <React.Fragment>
            <p className='title text-center'>
              {errorSubmitting ? errorSubmitting.message : ''}
            </p>
            {errorSubmitting.errorFields && errorSubmitting.errorFields.length && (
              <ul>
                {errorSubmitting.errorFields.map(item => (
                  <li>{item.message}</li>
                ))}
              </ul>
            )}
          </React.Fragment>
        )}
        <button
          type='button'
          className={
            'bg-primary text-white p-3 mt-3 w-full rounded-lg border-0 font-bold'
          }
          onClick={() => setShowErrorDialog(false)}
        >
          Okay
        </button>
      </ModalComponent>
    </div>
  );
}

const mapStateToProps = state => ({
  einvoice: state.EInvoice,
});

const mapDispatchToProps = dispatch => {
  return {
    eInvoiceAction: bindActionCreators(eInvoiceAction, dispatch),
  };
};

const withWrapper = Component => props => (
  <div id='einvoice-wrapper' className={'flex flex-1'}>
    <Component {...props} />
  </div>
);

export default withWrapper(
  connect(mapStateToProps, mapDispatchToProps)(EInvoiceSubmissionPage),
);
