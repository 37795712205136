import TYPES from '../actions/actionTypes';

const initialState = {
    object: undefined
};

export default function Settings(state = initialState, action) {
    switch (action.type) {
        case TYPES.GET_SETTINGS:
            return { ...state, object: action.data}
        case TYPES.GET_SETTINGS_FAIL:
            return { ...state}
        default:
            return state;
    }
}
