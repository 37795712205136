import * as UUID from 'uuid';
import TYPES from '../actions/actionTypes';

import { setStorage } from '../services/utils';

const initialState = {
  items: {},
  checkOutCount: 0,
};

function isSameItem(firstItem, secondItem) {
  return (
    firstItem.id === secondItem.id &&
    firstItem.addon === secondItem.addon &&
    firstItem.remark === secondItem.remark &&
    firstItem.price === secondItem.price
  );
}

function buildItem(state, action) {
  const {
    id,
    name,
    price,
    quantity,
    otherRequest,
    addOns,
    remarks,
    printerId,
    printerId2,
    combo,
    subItems,
    allowDiscount,
    docketName,
    sstOverride,
    serviceChargeOverride,
    selectedCoffee,
    createdDate,
    ...restData
  } = action.data;
  let _price = price;
  const addon = [];
  const remark = [];
  const menuAddOnId = [];

  const addOnForTicket = [];

  addOns.forEach(addOn => {
    if (addOn.checked) {
      addon.push(addOn.name);
      menuAddOnId.push(addOn.id);
      addOnForTicket.push(addOn);
      if (addOn.price) _price += addOn.price;
    }
  });
  remarks.forEach(addOn => {
    if (addOn.checked) {
      remark.push(addOn.name);
    }
  });

  otherRequest.trim() && remark.push(otherRequest.trim().toUpperCase());

  // TO handle dynamic QR will erase previous record when sent concurrent,
  // Backend will use merge, instead of pulling old data, then append new
  // record then replace the record.
  // TO use merge, need to have unique ID for each item.
  const uuid = UUID.v4();

  const _item = {
    uuid,
    id,
    name,
    price: _price,
    addon: addon.join(', '),
    remark: remark.join(', '),
    addonPrice: _price - price,
    quantity,
    menuAddOnId,
    addOnForTicket,
    printerId,
    printerId2,
    combo,
    subItems,
    allowDiscount,
    sstOverride,
    docketName,
    serviceChargeOverride,
    selectedCoffee,
    createdDate,
  };

  return _item;
}

function buildCheckoutItems(state, action) {
  const { merchantId } = action;
  const _item = buildItem(state, action);

  const _items = (() => {
    // Special case: when no record, just add
    const currentMerchantItems = state.items[merchantId];

    if (!currentMerchantItems) {
      return [_item];
    } else {
      const _haveValueBefore = currentMerchantItems.find(item =>
        isSameItem(item, _item),
      );

      // Add quantity if have value before
      if (_haveValueBefore) {
        return currentMerchantItems.map(item => {
          return isSameItem(item, _item)
            ? { ...item, quantity: item.quantity + _item.quantity }
            : item;
        });
      } else {
        return [...currentMerchantItems, _item];
      }
    }
  })();

  return _items;
}

function updateCheckoutItems(state, action) {
  const { merchantId, uuid } = action;
  const item = buildItem(state, action);
  item.uuid = uuid; // get existing uuid

  const currentMerchantItems = [...state.items[merchantId]];
  const itemIndex = currentMerchantItems.findIndex(item => item.uuid === uuid);
  currentMerchantItems[itemIndex] = item;
  console.log({ currentMerchantItems, item });
  return currentMerchantItems;
}

export default function Checkout(state = initialState, action) {
  switch (action.type) {
    case TYPES.ADD_TO_CART:
      return {
        ...state,
        items: {
          ...state.items,
          [action.merchantId]: buildCheckoutItems(state, action),
        },
      };
    case TYPES.UPDATE_CART:
      return {
        ...state,
        items: {
          ...state.items,
          [action.merchantId]: updateCheckoutItems(state, action),
        },
      };

    case TYPES.CHANGE_QUANTITY:
      const _result =
        (item => {
          return state.items[action.merchantId]
            .map(i =>
              isSameItem(item, i)
                ? item.quantity < 1
                  ? undefined
                  : { ...i, ...item }
                : i,
            )
            .filter(x => !!x);
        })(action.data) || [];

      return {
        ...state,
        items: { ...state.items, [action.merchantId]: _result },
      };
    case TYPES.CHECK_BOX_CLICK:
      const checkBoxItems =
        (item => {
          return state.items[action.merchantId].map(i =>
            isSameItem(i, item)
              ? { ...i, deselected: item.deselected ? undefined : true }
              : i,
          );
        })(action.data) || [];
      return {
        ...state,
        items: { ...state.items, [action.merchantId]: checkBoxItems },
      };
    case TYPES.CLEAR_ALL:
      const items_2 = { ...state.items };
      delete items_2[action.merchantId];

      return {
        ...state,
        items: items_2,
      };
    case TYPES.UPDATE_SESSION_STORAGE:
      setStorage('items', JSON.stringify(state.items));
      return {
        ...state,
      };
    case TYPES.SAVE_CHECKOUT_COUNT:
      return {
        ...state,
        checkOutCount: action.data,
      };
    case TYPES.RESET_CHECKOUT_COUNT:
      return {
        ...state,
        checkOutCount: 0,
      };
    default:
      return state;
  }
}
