import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dropin from 'braintree-web-drop-in';
import * as R from 'ramda';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authAction from '../../actions/authAction';
import * as checkoutAction from '../../actions/checkoutAction';
import * as deliveryAction from '../../actions/deliveryAction';
import * as menuAction from '../../actions/menuAction';
import AppBar from '../../component/appBar/appBar';
import { Loading } from '../../component/loading/loading';
import LoginByDialog from '../../component/loginByDialog/loginByDialog';
import ModalComponent from '../../component/modal/modal';
import OperatingHoursComponent from '../../component/operatingHours/operatingHours';
import OrderItem from '../../component/orderItem/orderItem';
import PriceBox from '../../component/priceBox/priceBox';
import DeliveryAddress from '../../model/deliveryAddress';
import DeliveryQuotation from '../../model/deliveryQuotation';
import ApiService from '../../services/api';
import {
  getApiErrorMessage,
  getPaymentMethodSortingArray,
  MenuSession,
  paymentMethodList,
  PreOrderSession,
} from '../../services/constants';
import {
  isEqualBoost,
  isEqualFinexus,
  isEqualIpay88GrabPay,
  isEqualIpay88ShopeePay,
  isEqualIpay88Tng,
  isEqualLinks,
  isEqualTwoc2p_Alipay,
  isEqualTwoc2p_CC,
  isEqualTwoC2p_FPX,
  isEqualTwoc2p_GrabPay,
  isEqualTwoc2p_ShopeePay,
  isEqualTwoc2p_Tng,
  isEqualTwoc2p_Wechat,
  isEqualWeChatPay,
  isValidPhoneNumber,
} from '../../services/helper';
import {
  getStorage,
  getValueFromKeyAtSetting,
  isClosingNow,
  isWechat,
  openUrl,
  parsePrice,
  path,
  roundparsePrice,
  setStorage,
} from '../../services/utils';
import CartEmpty from './cartEmpty';
import './checkout.scss';
import LoginTextInput from '../../component/loginByDialog/loginTextInput';
import Button from '../../component/button/button';
import { FBPixel, GA } from '../../reducers/menu';
import ShowPreOrderTime from './preOrderTime/preOrderTime';
import MenuDetailModal from '../menu/MenuDetailModal';
import './../menu/menu.scss';
import 'react-tabs/style/react-tabs.css';

let env = process.env.REACT_APP_DEBUG
  ? ''
  : process.env.REACT_APP_ENV.indexOf('dev.api.aliments') >= 0
  ? 'SANDBOX'
  : 'PRODUCTION';

class Checkout extends Component {
  DINE_IN = 'DINE_IN';
  TAKE_AWAY = 'TAKE_AWAY';
  DELIVERY = 'DELIVERY';

  constructor() {
    super();
    const _orderSlot = JSON.parse(
      sessionStorage.getItem(PreOrderSession.preOrderSlot),
    );
    if (_orderSlot) {
      _orderSlot.start = new Date(_orderSlot.start);
      _orderSlot.end = new Date(_orderSlot.end);
    }

    this.state = {
      method: this.DINE_IN,
      isShowDetail: true,
      coupon: '',
      couponStatus: null,
      afterDiscountPrice: 0,
      modal: {
        openModal: false,
        modalMessage: undefined,
        callBackAction: undefined,
      },
      showLoginDialog: false,
      isExceedMaximumOrder: false,
      openBrainTreeModal: false,
      openPaymentMethodModal: false,
      brainTree: {},
      isGettingCreditCards: false,
      isLogin: false,
      paymentMethod: null,
      lastUsedPayment: null,
      showOperatingHours: false,
      openQRCodeModal: false,
      isBrainTreeAvailable: true,
      isConfirmPayModal: false,
      isLockCouponEntry: false,
      openPhoneNumberModal: false,
      getValidPhoneNumber: '',
      openAlertMessageModal: false,
      alertMsg: '',
      deliveryAlertNote: '',
      selectedCoffee: null,
      selectedUuid: null,
      //use for preOrder and now order as well
      orderSlot: _orderSlot
        ? _orderSlot
        : {
            isAsap: true,
            start: new Date(),
            end: new Date().setHours(new Date().getHours() + 1, 0),
            isToday: true,
            tabIndex: 0,
          },
    };

    this.stateless = {
      isTimeoutSet: false,
      shouldProceedOrder: false,
      isHidden: false,
      checkOutCount: 0,
      paymentMethodList: paymentMethodList(),
      isBrainTreeInit: false,
      couponApiReturn: {},
    };

    document.addEventListener('visibilitychange', this.visibilityChange);
  }

  componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    snapshot: SS,
  ) {
    GA.sendPageView('Checkout Page');
    FBPixel.sendPageView('Checkout Page');
  }

  componentDidMount() {
    if (!this.props.merchant) {
      this.redirectToMenu();
      return;
    }

    //if pauseTill is greated than current date || current operation hours is close or pre-order time slot is close
    const _pauseTill = this.props.menu.menuList.merchant.pauseTill;
    if (
      (_pauseTill && _pauseTill >= this.state.orderSlot.start) ||
      isClosingNow(
        this.props.menu.menuList.merchant.operatingHours,
        false,
        !this.props.merchant.supportPreOrder || this.state.orderSlot.isAsap
          ? null
          : this.state.orderSlot.start,
      )
    ) {
      this.setState({
        orderSlot: null,
      });
    }

    // enter debug mode
    if (
      this.props.global.query &&
      this.props.global.query.merchantId &&
      this.props.global.query.merchantId === '2'
    ) {
      env = '';
    }

    // Handle url param pass takeAway condition
    // Default is DINE_IN
    let method = this.DINE_IN;
    if (this.isDelivery()) method = this.DELIVERY;
    else if (this.isTakeAway()) method = this.TAKE_AWAY;
    this.setState({ method: method });

    this.stateless.checkOutCount = this.props.checkout.checkOutCount;

    if (getStorage('apiKey')) {
      this.setState({
        isLogin: true,
      });
      this.setAutoApplyCouponCodeAndCallApi();
    }
    // Direct fire login when user in wechat
    else if (!getStorage('apiKey') && isWechat()) {
      this.stateless.shouldProceedOrder = true;
      this.setState({
        showLoginDialog: true,
      });
      // }else if (!getStorage('apiKey') && this.isDelivery()){
    } else {
      this.setState({
        showLoginDialog: true,
      });
    }

    this.getBrainTreeToken();

    this.initBrainTreePayment();
    this.getLastUsedPayment();

    let value = getValueFromKeyAtSetting(
      'deliveryAlertNote',
      this.props.settingsApi,
    );
    if (value && this.isDelivery() && getStorage('apiKey')) {
      this.setState({
        deliveryAlertNote: value,
      });
    }
  }

  componentWillUnmount() {
    this.props.checkoutAction.saveCheckOutCount(this.stateless.checkOutCount);
    document.removeEventListener('visibilitychange', this.visibilityChange);
  }

  componentWillReceiveProps(props) {
    const { menu } = props;
    if (menu && menu.brainTreeToken && !this.stateless.isBrainTreeInit) {
      this.initBrainTreePayment(menu.brainTreeToken);
    }

    if (this.itemIsFree(props.checkout.items)) {
      this.setState({ paymentMethod: 'Redemption' });
    } else {
      this.getLastUsedPayment(props.checkout.items);
    }
  }

  setAutoApplyCouponCodeAndCallApi() {
    let code;
    if (getStorage('apiKey')) {
      if (this.props.merchant.autoApplyCouponCode) {
        this.setState({
          coupon: this.props.merchant.autoApplyCouponCode,
        });
        code = this.props.merchant.autoApplyCouponCode;
      }
    }
    this.callApplyCouponOnClickMethod(code);
  }

  async callApplyCouponOnClickMethod(code) {
    this.applyCouponOnClick(this.getSubTotal(), code)().then(() => {
      if (this.isDeliveryAndIsLogin()) {
        this.props.deliveryAction.getAddress().then(resp => {
          //Check if user is first logic in before push to addressSelection, no address will cause it to pops box
          if (!resp.addresses.length) {
            this.props.history.push({
              pathname: '/addressSelection',
              state: { noAddress: true },
            });
            return;
          }
          this.props.deliveryAction.selectFirstAddress();
          this.setLoading(true);
          this.props.deliveryAction
            .getQuotation(
              this.props.global.query.merchantId,
              this.props.delivery.selectedAddress.id,
              Number(this.state.afterDiscountPrice),
              this.getSubTotal(),
            )
            .then(resp => {
              console.log(`quoatation resp`, resp);
              this.setLoading(false);
              if (!resp.isSuccess()) {
                this.setModal(
                  true,
                  () => {
                    this.setModal();
                    this.props.deliveryAction.removeSelectedAddress();
                  },
                  resp.message,
                  null,
                );
              }
            });
        });
      }
    });
  }

  getPaymentMethod() {
    const partner = R.pathOr('', ['global', 'query', 'partner'])(this.props);

    // pay with method
    const paymentMethodType =
      this.state.paymentMethod && this.state.paymentMethod.type;

    // when paymentMethodType is redemption.
    if (this.state.paymentMethod === 'Redemption') {
      return 'REDEMPTION';
    }

    if (
      (!!partner && isEqualBoost(partner)) ||
      (!partner && !!paymentMethodType && isEqualBoost(paymentMethodType))
    ) {
      return 'BOOST';
    } else if (
      (!!partner && isEqualWeChatPay(partner)) ||
      (!partner && !!paymentMethodType && isEqualWeChatPay(paymentMethodType))
    ) {
      return;
    } else if (
      (!!partner && isEqualIpay88GrabPay(partner)) ||
      (!partner &&
        !!paymentMethodType &&
        isEqualIpay88GrabPay(paymentMethodType))
    ) {
      return 'IPAY88_GRABPAY';
    } else if (
      (!!partner && isEqualIpay88Tng(partner)) ||
      (!partner && !!paymentMethodType && isEqualIpay88Tng(paymentMethodType))
    ) {
      return 'IPAY88_TNG';
    } else if (paymentMethodType === 'ALIMENTS_POINT') {
      return 'ALIMENTS_POINT';
    } else if (paymentMethodType === 'PAY_AT_COUNTER') {
      return 'PAY_AT_COUNTER';
    } else if (
      (!!partner && isEqualFinexus(partner)) ||
      (!partner && !!paymentMethodType && isEqualFinexus(paymentMethodType))
    ) {
      return 'FINEXUS';
    } else if (
      (!!partner && isEqualTwoc2p_GrabPay(partner)) ||
      (!partner &&
        !!paymentMethodType &&
        isEqualTwoc2p_GrabPay(paymentMethodType))
    ) {
      return 'TWOC2P_GRABPAY';
    } else if (
      (!!partner && isEqualTwoc2p_ShopeePay(partner)) ||
      (!partner &&
        !!paymentMethodType &&
        isEqualTwoc2p_ShopeePay(paymentMethodType))
    ) {
      return 'TWOC2P_SHOPEEPAY';
    } else if (
      (!!partner && isEqualTwoc2p_Wechat(partner)) ||
      (!partner &&
        !!paymentMethodType &&
        isEqualTwoc2p_Wechat(paymentMethodType))
    ) {
      return 'TWOC2P_WECHAT';
    } else if (
      (!!partner && isEqualTwoc2p_Alipay(partner)) ||
      (!partner &&
        !!paymentMethodType &&
        isEqualTwoc2p_Alipay(paymentMethodType))
    ) {
      return 'TWOC2P_ALIPAY';
    } else if (
      (!!partner && isEqualTwoc2p_CC(partner)) ||
      (!partner && !!paymentMethodType && isEqualTwoc2p_CC(paymentMethodType))
    ) {
      return 'TWOC2P_CC';
    } else if (
      (!!partner && isEqualTwoc2p_Tng(partner)) ||
      (!partner && !!paymentMethodType && isEqualTwoc2p_Tng(paymentMethodType))
    ) {
      return 'TWOC2P_TNG';
    } else if (
      (!!partner && isEqualIpay88ShopeePay(partner)) ||
      (!partner &&
        !!paymentMethodType &&
        isEqualIpay88ShopeePay(paymentMethodType))
    ) {
      return 'IPAY88_SHOPEEPAY';
    } else if (
      (!!partner && isEqualTwoc2p_ShopeePay(partner)) ||
      (!partner &&
        !!paymentMethodType &&
        isEqualTwoc2p_ShopeePay(paymentMethodType))
    ) {
      return 'TWOC2P_SHOPEEPAY';
    } else if (
      (!!partner && isEqualTwoC2p_FPX(partner)) ||
      (!partner && !!paymentMethodType && isEqualTwoC2p_FPX(paymentMethodType))
    ) {
      return 'TWOC2P_FPX';
    } else if (
      (!!partner && isEqualLinks(partner)) ||
      (!partner && !!paymentMethodType && isEqualLinks(paymentMethodType))
    ) {
      return 'LINKS';
    }

    if (
      !partner &&
      this.state.paymentMethod &&
      this.state.paymentMethod.nonce
    ) {
      return 'BRAINTREE';
    }
  }

  getBrainTreeToken() {
    // no token, has merchant id, has apiKey
    if (
      !this.props.menu.brainTreeToken &&
      this.props.global.query.merchantId &&
      getStorage('apiKey') &&
      this.props.menu.menuList &&
      this.props.menu.menuList.merchant.paymentMethods.find(
        payment => payment.type === 'BRAINTREE',
      )
    ) {
      console.log('GETTING BRAIN TREE TOKEN');
      this.props.menuAction.getBrainTreeToken(
        this.props.global.query.merchantId,
      );
    }
  }

  getLastUsedPayment = async (items = this.props.checkout.items) => {
    if (!getStorage('apiKey') || this.props.global.query.partner) {
      return;
    }

    if (this.itemIsFree(items)) {
      this.setState({
        paymentMethod: 'Redemption',
      });
      return;
    }

    const resp = await ApiService.get(`/api/customer/ipay88/token`, true);
    let token =
      resp.tokens && resp.tokens.length
        ? resp.tokens.find(token => token.lastUsed === true)
        : null;
    if (token && this.props.menu) {
      const paymentMethod = this.props.menu.menuList.merchant.paymentMethods.find(
        payment => payment.type === token.cardScheme,
      );
      token = {
        ...token,
        ...this.stateless.paymentMethodList[paymentMethod.type],
        ...paymentMethod,
      };
    }

    // When only one payment method other than BRAINTREE available, select it
    const availablePaymentMethod =
      this.props.menu.menuList &&
      this.props.menu.menuList.merchant.paymentMethods.filter(payment =>
        this.isPaymentMethodAvailable(payment),
      );
    if (
      !token &&
      availablePaymentMethod.length === 1 &&
      availablePaymentMethod[0].type !== 'BRAINTREE'
    ) {
      this.setState({
        paymentMethod: {
          ...availablePaymentMethod[0],
          ...this.stateless.paymentMethodList[availablePaymentMethod[0].type],
        },
      });
    } else {
      this.setState({
        lastUsedPayment: token || null,
        paymentMethod: !this.state.paymentMethod
          ? token
          : this.state.paymentMethod, //check paymentMethod if have no need set to token or else might be null
      });
    }
  };

  itemIsFree = (items = this.props.checkout.items) => {
    if (this.isDelivery()) {
      return false;
    } else {
      return (
        items &&
        items[this.props.global.query.merchantId] &&
        items[this.props.global.query.merchantId].length &&
        !items[this.props.global.query.merchantId].filter(
          i => i.price > 0 && !i.deselected,
        ).length
      );
    }
  };

  initBrainTreePayment = async (
    brainTreeToken = this.props.menu.brainTreeToken,
  ) => {
    if (this.props.global.query.partner || this.stateless.isBrainTreeInit) {
      return;
    }

    const { paymentMethods } = this.props.menu.menuList.merchant;
    const brainTree = paymentMethods.find(
      method => method.type === 'BRAINTREE',
    );

    // no brain tree available
    // or has brain tree but env is different
    // or item is free
    if (
      !brainTree ||
      this.itemIsFree() ||
      (brainTree && env && env !== brainTree.env)
    ) {
      this.setState({
        isBrainTreeAvailable: false,
      });
      return;
    }

    // This is last step to check
    // FIX SelectPayment Method infinite loading when merchant do not support braintree payment
    if (!brainTreeToken) {
      return;
    }

    this.stateless.isBrainTreeInit = true;

    const [dropinInstance, clientInstance] = await Promise.all([
      dropin.create({
        authorization: brainTreeToken,
        container: '#dropin-container',
      }),
      window.braintree.client.create({
        authorization: brainTreeToken,
      }),
    ]);

    const threeDSecure = await window.braintree.threeDSecure.create({
      client: clientInstance,
    });

    this.setState(
      {
        dropinInstance,
        threeDSecure,
      },
      () => {
        const creditCardAvailable = dropinInstance.isPaymentMethodRequestable();
        if (creditCardAvailable) {
          this.setState({
            isGettingCreditCards: true,
          });

          setTimeout(async () => {
            const card = await dropinInstance.requestPaymentMethod();

            this.setState({
              brainTree: { ...this.state.brainTree, card },
              paymentMethod: this.itemIsFree()
                ? 'Redemption'
                : this.state.lastUsedPayment || {
                    ...card,
                    img: `./image/icon_${card.details.cardType.toLowerCase()}.png`,
                  },
              isGettingCreditCards: false,
            });
          }, 500);
        }
      },
    );
  };

  verifyCard = () => {
    const my3DSContainer = document.querySelector('.verify-card-modal');
    const my3DSContainerBody = document.createElement('div');
    my3DSContainerBody.classList.add('body');

    this.state.threeDSecure.verifyCard(
      {
        amount: this.getAllRelatedPrice().finalTotal,
        nonce: this.state.brainTree.card.nonce,
        addFrame: (err, iframe) => {
          my3DSContainerBody.appendChild(iframe);
          my3DSContainer.appendChild(my3DSContainerBody);
        },
        removeFrame: () => {
          document.body.removeChild(my3DSContainer);
        },
      },
      (err, payload) => {
        if (payload) {
          this.setState(
            {
              brainTree: { ...this.state.brainTree, verifiedCard: payload },
            },
            () => this.orderOnClick(),
          );
        } else {
          setTimeout(() => {
            this.setModal(true, this.setModal, `Opss...${err.message}`);
          }, 500);
        }
      },
    );
  };

  visibilityChange = async () => {
    // console.log(document.visibilityState);

    if (
      this.state.transactionId &&
      this.stateless.checkOutCount > 0 &&
      this.stateless.checkOutCount < 4 &&
      (this.props.global.query.partner ||
        (this.state.paymentMethod &&
          this.state.paymentMethod.name === 'Boost')) &&
      document.visibilityState === 'visible'
    ) {
      this.setLoading(true);

      //CALL CHECK STATUS API
      const res = await ApiService.get(
        `api/customer/boost/status/${this.state.transactionId}`,
      );

      // console.log(`res`, res);
      //SUCCESS - redirect to thankyou page -> click button to homepage
      if (res.code === '200') {
        this.props.history.push('/thankyou');
      }
      // FAIL - takeout loading, showpop up payment fail,
      else if (res.message === 'api.payment.failed') {
        this.setState({
          modal: {
            openModal: true,
            modalMessage: 'Payment fail, please try again',
            callBackAction: this.closeModalOnClick,
          },
          submitted: false,
        });
      }

      this.setLoading(false);
    } else {
      // this.props.history.push('/thankyou')
    }
  };

  setLoading = (showLoading, callBackFn) => {
    this.setState({ submitted: showLoading }, callBackFn);
  };

  toggleQRCode = toggle => {
    this.setState({
      openQRCodeModal: toggle || !this.state.openQRCodeModal,
    });
  };

  closeQrCode = () => {
    this.setState({
      isConfirmPayModal: true,
    });
  };

  toggleConfirmPayModal = confirm => {
    this.setState({
      isConfirmPayModal: !this.state.isConfirmPayModal,
    });

    if (confirm) {
      this.props.history.push('/menu');

      this.props.global.query.merchantId &&
        this.props.checkoutAction.clearAll(this.props.global.query.merchantId);
    }
  };

  toggleBrainTreeModal = () => {
    this.setState({
      openBrainTreeModal: !this.state.openBrainTreeModal,
    });
  };

  togglePaymentMethodModal = () => {
    // Check is user logged in
    if (!getStorage('apiKey')) {
      this.stateless.shouldProceedOrder = true;
      this.setState({
        showLoginDialog: true,
      });
      return;
    }

    this.setState({
      openPaymentMethodModal: !this.state.openPaymentMethodModal,
    });
  };

  onPaymentMethodClicked = payment => async () => {
    const doAction = () => {
      return new Promise(resolve => {
        switch (payment.type) {
          case 'BRAINTREE':
            this.toggleBrainTreeModal();
            resolve();
            break;
          default:
            resolve(payment);
            break;
        }
      });
    };

    const action = await doAction();

    if (action) {
      this.setState(
        {
          paymentMethod: {
            ...payment,
            ...this.stateless.paymentMethodList[payment.type],
          },
        },
        // Recalculate when select payment method.
        this.applyCouponOnClick(this.getSubTotal()),
      );
    }

    this.togglePaymentMethodModal();
  };

  getNonce = async () => {
    try {
      const card = await this.state.dropinInstance.requestPaymentMethod();
      this.setState(
        {
          brainTree: { ...this.state.brainTree, card },
          paymentMethod: {
            ...card,
            img: `./image/icon_${card.details.cardType.toLowerCase()}.png`,
          },
        },
        () => {
          this.toggleBrainTreeModal();
          // Reapply coupon when change payment method.
          this.applyCouponOnClick(this.getSubTotal())();
        },
      );
    } catch (e) {}
  };

  methodOnClick = method => () => {
    this.setState(
      {
        method,
      },
      this.applyCouponOnClick(this.getSubTotal()),
    );
  };

  loginOnClick = async res => {
    // Show loading when user click on login
    this.setLoading(true);
    // get response from account
    await this.props.authAction.getUserAccount();
    const clientToken = await (res.code === '500'
      ? Promise.resolve()
      : this.getBrainTreeToken());

    if (clientToken) {
      this.initBrainTreePayment(clientToken);
    }

    // Check login to aliments result
    if (res.code === '500') {
      this.setModal(true, this.setModal, `Login Error: ${res.message}`);
    } else {
      this.setState(
        {
          isLogin: true,
        },
        () => {
          this.getLastUsedPayment();
        },
      );
      this.setAutoApplyCouponCodeAndCallApi();
    }

    // Dismiss loading
    this.setLoading(false);
  };

  loginOnClose = () => {
    // When use close login dialog
    // dismiss loading, and login dialog
    if (!getStorage('apiKey') && this.isDelivery()) {
      this.backOnClick();
    } else {
      this.setState({
        showLoginDialog: false,
        submitted: false,
      });
    }
  };

  openDeepLink = url => {
    openUrl(url);

    this.setLoading(false);
  };

  toggleOperatingHours = () => {
    this.setState({
      showOperatingHours: !this.state.showOperatingHours,
    });
  };

  allowToProceed = () => {
    if (!this.isOrderSlotSelected()) {
      if (this.isPreOrderSupported()) {
        alert(
          'The restaurant is closed right now. \n\nPlease select a time slot',
        );
      } else {
        const _pauseTill = this.props.menu.menuList.merchant.pauseTill;
        if (_pauseTill && _pauseTill >= new Date()) {
          alert('The restaurant is temporary close.');
        } else {
          this.toggleOperatingHours();
        }
      }
      return false;
    }

    return true;
  };

  isPreOrderSupported = () => {
    const allowPreOrder = getValueFromKeyAtSetting(
      'allow.preorder.new',
      this.props.settingsApi,
    );
    const supportPreOrder = this.props.menu.menuList.merchant.supportPreOrder;
    return !!(allowPreOrder === '1' && supportPreOrder);
  };

  isOrderSlotSelected = () => !!this.state.orderSlot;

  orderOnClick = async () => {
    // Block order when on loading
    if (this.state.submitted) return;

    //check for dine in
    if (this.isDining()) {
      if (isClosingNow(this.props.menu.menuList.merchant.operatingHours)) {
        // is merchant still under operating hour
        this.toggleOperatingHours();
        return;
      }
    } else if (!this.allowToProceed()) {
      //check for takeAway and delivery
      return;
    }

    // Check is user logged in
    if (!getStorage('apiKey')) {
      this.stateless.shouldProceedOrder = true;
      this.setState({
        showLoginDialog: true,
      });
      return;
    }

    // login, no parner, no paymentMethod
    if (!this.props.global.query.partner && !this.state.paymentMethod) {
      this.togglePaymentMethodModal();
      return;
    }

    this.stateless.checkOutCount += 1;
    if (this.props.global.query.partner && this.stateless.checkOutCount > 3) {
      this.setState({
        isExceedMaximumOrder: true,
      });
      return;
    }

    //Prompt Phone Number if is take away and user profile dont have phone number
    if (
      this.state.method === this.TAKE_AWAY &&
      !(
        getStorage('phoneNumber') ||
        this.props.auth.user.me.contactNo ||
        this.props.auth.user.me.phoneNo
      )
    ) {
      this.setState({
        openPhoneNumberModal: true,
      });
      return;
    }

    // Trigger loading
    this.setLoading(true);

    const url = '/api/customer/v2/order';

    const items = [];
    this.getCheckOutItems(true).forEach(item => {
      for (let i = 0; i < item.quantity; i++) {
        items.push({
          menuId: item.id,
          remark: item.remark,
          menuAddOnId: item.menuAddOnId,
        });
      }
    });

    const partner = R.pathOr('', ['global', 'query', 'partner'])(this.props);
    const request = {
      merchantId: this.props.global.query.merchantId,
      tableNo: this.getTableNo(),
      // byPoint: false,
      //"vcashId": "60179002237",
      // "ipay88TokenId": 1,
      deviceExtra: 'WEB ' + navigator.userAgent,
      items,
      takeAway: this.isTakeAway(),
      delivery: this.isDelivery(),
      remark: this.getRemark(),
    };

    if (!this.isDining() && !this.state.orderSlot.isAsap) {
      request['preOrderDatetime'] = this.state.orderSlot.start.getTime();
    }

    if (this.isDelivery()) {
      request['deliveryQuotationId'] = this.props.delivery.deliveryQuotation.id;
    }

    /** Additional param **/
    // coupon
    // sent only when coupon is valid
    if (this.state.couponStatus && this.state.couponStatus.isValid) {
      request.coupon = this.state.couponStatus.couponCode;
    }

    // pay with method
    const paymentMethodType =
      this.state.paymentMethod && this.state.paymentMethod.type;

    if (
      (!!partner && isEqualBoost(partner)) ||
      (!partner && !!paymentMethodType && isEqualBoost(paymentMethodType))
    ) {
      request['redirectUrl'] = window.location.origin + '/boostVerify';
    } else if (
      (!!partner && isEqualWeChatPay(partner)) ||
      (!partner && !!paymentMethodType && isEqualWeChatPay(paymentMethodType))
    ) {
      request['redirectUrl'] = window.location.origin + '/wechatVerify';
    }

    /** END Additional param **/

    // when no partner and payment method is brainthree
    if (
      !partner &&
      this.state.paymentMethod &&
      this.state.paymentMethod.nonce
    ) {
      // const { verifiedCard, card } = this.state.brainTree;
      // request['braintreeNonce'] = verifiedCard
      //   ? verifiedCard.nonce
      //   : card.nonce;
    }

    request['paymentMethod'] = this.getPaymentMethod();

    const resp = await ApiService.post(url, request);

    if (resp.code === '200') {
      if (resp.message !== 'payment.pending') {
        this.props.history.push('/thankyou');
      } else if (
        partner ||
        (this.state.paymentMethod && this.state.paymentMethod.id)
      ) {
        if (
          this.state.paymentMethod &&
          this.state.paymentMethod.type === 'PAY_AT_COUNTER'
        ) {
          this.setLoading(false);
          this.toggleQRCode();
        } else if (resp.boostDeepLink) {
          this.openDeepLink(resp.boostDeepLink);
        } else if (resp.wechatPayDeepLink) {
          openUrl(resp.paymentUrl);
        } else if (
          isEqualIpay88GrabPay(partner) ||
          (!partner && isEqualIpay88GrabPay(paymentMethodType)) ||
          isEqualIpay88Tng(partner) ||
          (!partner && isEqualIpay88Tng(paymentMethodType)) ||
          isEqualIpay88ShopeePay(partner) ||
          (!partner && isEqualIpay88ShopeePay(paymentMethodType)) ||
          isEqualFinexus(partner) ||
          (!partner && isEqualFinexus(paymentMethodType)) ||
          isEqualTwoc2p_GrabPay(partner) ||
          (!partner && isEqualTwoc2p_GrabPay(paymentMethodType)) ||
          isEqualTwoc2p_GrabPay(partner) ||
          (!partner && isEqualTwoc2p_GrabPay(paymentMethodType)) ||
          isEqualTwoc2p_CC(partner) ||
          (!partner && isEqualTwoc2p_CC(paymentMethodType)) ||
          isEqualTwoc2p_Wechat(partner) ||
          (!partner && isEqualTwoc2p_Wechat(paymentMethodType)) ||
          isEqualTwoc2p_ShopeePay(partner) ||
          (!partner && isEqualTwoc2p_ShopeePay(paymentMethodType)) ||
          isEqualTwoc2p_Alipay(partner) ||
          (!partner && isEqualTwoc2p_Alipay(paymentMethodType)) ||
          isEqualTwoC2p_FPX(partner) ||
          (!partner && isEqualTwoC2p_FPX(paymentMethodType)) ||
          isEqualTwoc2p_Tng(partner) ||
          (!partner && isEqualTwoc2p_Tng(paymentMethodType)) ||
          isEqualLinks(partner) ||
          (!partner && !!paymentMethodType && isEqualLinks(paymentMethodType))
        ) {
          openUrl(resp.paymentUrl);
        }
      }

      this.setState({
        transactionId: resp.refNo,
      });
    } else {
      this.setLoading(false);

      // verify card
      if (resp.message === 'api.3ds.required') {
        const my3DSContainer = document.createElement('div');
        my3DSContainer.classList.add('verify-card-modal');
        document.body.appendChild(my3DSContainer);
        this.verifyCard();
        return;
      }

      this.setModal(true, this.setModal, `Opss...${resp.message}`);
    }
  };

  getSubTotal = () => {
    return this.getCheckOutItems().reduce((previous, current) => {
      if (current.deselected) return previous;
      return previous + current.price * current.quantity;
    }, 0);
  };

  resetCoupon = () => {
    this.setState({
      isLockCouponEntry: false,
      coupon: '',
      couponStatus: null,
    });
  };

  recalculateTotal = () => {
    this.setState(
      {
        afterDiscountPrice: 0,
      },
      () => {
        this.applyCouponOnClick(this.getSubTotal())();
      },
    );
  };

  resetCheckOutCount = () => {
    this.stateless.checkOutCount = 0;
    this.props.checkoutAction.resetCheckOutCount();
  };

  quantityOnClick = item => {
    if (item.quantity < 1) {
      this.setModal(
        true,
        () => {
          this.recalculateTotal();
          this.props.checkoutAction.changeQuantity(
            item,
            this.props.global.query.merchantId,
          );
        },
        <span>
          Are you sure want to remove{' '}
          <span className='item-name'>{item.name}</span>?
        </span>,
      );
    } else {
      this.recalculateTotal();
      this.props.checkoutAction.changeQuantity(
        item,
        this.props.global.query.merchantId,
      );
    }

    this.resetCheckOutCount();
  };

  checkboxOnClick = item => {
    this.props.checkoutAction.checkboxOnClick(
      item,
      this.props.global.query.merchantId,
    );

    this.resetCheckOutCount();
  };

  hideOnClick = () => {
    this.setState({ isShowDetail: !this.state.isShowDetail });
  };

  applyCouponOnClick = (total, code) => async () => {
    this.setLoading(true);
    let couponCode = code ? code : this.state.coupon;

    const merchantId = this.props.merchant.id;
    const checkoutItem = this.props.checkout.items;

    let itemList = [];
    checkoutItem[merchantId].forEach(e => {
      for (let i = 0; i < e.quantity; i++) {
        itemList.push({
          addOn: e.addon,
          combo: e.combo,
          dateCreated: Date.now(),
          discount: 0,
          menuAddOnId: e.menuAddOnId,
          menuId: e.id,
          menuName: e.name,
          printerId: e.printerId,
          printerId2: e.printerId2,
          remark: e.remark,
          serviceCharge: null,
          serviceTax: null,
          scale: null,
          subItems: e.subItems,
          totalAmount: null,
        });
      }
    });
    let req = {
      delivery: this.isDelivery(),
      code: couponCode,
      items: itemList,
      merchantId: merchantId,
      paymentMethod: this.getPaymentMethod(),
      takeAway: this.isTakeAway(),
      useCashback: false,
      primeId: null,
    };

    const resp = await ApiService.post('api/customer/v2/orderSummary', req);
    this.setLoading(false);

    if (resp.code === '200') {
      if (!this.state.coupon.trim()) {
        this.stateless.oldCouponApiReturn = resp;
      }
      this.stateless.couponApiReturn = resp;
      const couponError = resp.couponError;
      this.setState({
        afterDiscountPrice: roundparsePrice(resp.afterDiscountPrice),
        couponStatus: !this.state.coupon
          ? null
          : {
              isValid: !couponError,
              message: couponError
                ? getApiErrorMessage(couponError)
                : 'Success',
              couponCode: couponError ? null : this.state.coupon,
            },
        isLockCouponEntry: !!this.state.coupon,
      });
      if (this.isDelivery()) {
        this.getDeliveryAmount();
      }
    } else {
      if (resp.message === 'api.key.invalid') {
        return;
      }

      this.setState({
        openAlertMessageModal: true,
        alertMsg: getApiErrorMessage(resp.message),
      });

      // this.stateless.couponApiReturn = this.stateless.oldCouponApiReturn || this.stateless.couponApiReturn;
      // this.setState({
      //   afterDiscountPrice: 0,
      //   couponStatus: {
      //     isValid: false,
      //     message: getApiErrorMessage(resp.message),
      //   },
      // });
    }
  };

  getDeliveryAmount = () => {
    //timer create to prevent when the user spamm gao gao causes delivery quotation didnt call to server;
    if (Object.keys(this.props.delivery.deliveryQuotation).length) {
      let quotationId = this.props.delivery.deliveryQuotation.id;
      this.props.deliveryAction
        .getQuotation(
          this.props.global.query.merchantId,
          this.props.delivery.selectedAddress.id,
          this.state.afterDiscountPrice,
          this.getSubTotal(),
          quotationId,
        )
        .then(resp => {
          if (resp.isSuccess() && resp.alertMsg) {
            this.setState({
              openAlertMessageModal: true,
              alertMsg: resp.alertMsg,
            });
          }
          this.setLoading(false);
        });
    } else {
      this.setLoading(false);
    }
  };

  couponOnChange = event => {
    this.setState({ coupon: event.target.value.toUpperCase() });
  };

  backOnClick = () => {
    this.props.history.push('/menu');
  };

  clearAllOnClick = () => {
    this.setModal(
      true,
      this.props.checkoutAction.clearAll,
      'Are you sure wan to remove all?',
    );
  };

  getAllRelatedPrice = () => {
    const {
      afterDiscountPrice = 0,
      totalDiscount = 0,
      totalSST = 0,
      totalServiceCharge = 0,
    } = this.stateless.couponApiReturn;

    const serviceCharge =
      (diningMethod => {
        switch (diningMethod) {
          case this.DINE_IN:
            return this.props.merchant.serviceChargeDineIn;
          case this.TAKE_AWAY:
          case this.DELIVERY:
            return this.props.merchant.serviceChargeTakeAway;
          default:
            return 0;
        }
      })(this.state.method) * 100;

    const serviceTax = this.props.merchant.sstCharge * 100;
    const SUB_TOTAL = this.getSubTotal();
    const subTotal = Number(this.state.afterDiscountPrice) || SUB_TOTAL;
    const discountedPrice = totalDiscount;

    const serviceChargerTotal = totalServiceCharge;
    const serviceTaxTotal = totalSST;

    let finalTotal = afterDiscountPrice;

    if (this.isDelivery()) {
      const deliveryQuotation = new DeliveryQuotation(
        this.props.delivery.deliveryQuotation,
      );
      const deliveryQuotationAmount = deliveryQuotation.amount || 0.0;
      finalTotal += deliveryQuotationAmount;
    }

    return {
      serviceCharge,
      serviceTax,
      SUB_TOTAL,
      subTotal,
      discountedPrice,
      serviceChargerTotal,
      serviceTaxTotal,
      finalTotal: finalTotal,
    };
  };

  confirmClearOnClick = () => {
    this.state.modal.callBackAction && this.state.modal.callBackAction();
    this.closeModalOnClick();
  };

  closeModalOnClick = () => {
    this.setModal();
  };

  closeMaximumOrder = () => {
    this.setState({
      isExceedMaximumOrder: false,
    });
  };

  getCheckOutItems = bySelected => {
    if (
      !this.props.global.query.merchantId ||
      !this.props.checkout.items[this.props.global.query.merchantId]
    ) {
      return [];
    }

    const items = this.props.checkout.items[this.props.global.query.merchantId];

    return bySelected ? items.filter(i => !i.deselected) : items;
  };

  setModal = (
    openModal = false,
    callBackAction = undefined,
    modalMessage = '',
    cancelOnClick,
  ) => {
    this.setState({
      modal: {
        openModal,
        modalMessage,
        callBackAction,
        cancelOnClick,
      },
    });
  };

  redirectToMenu = () => {
    let session = sessionStorage.getItem(MenuSession.savedParsedQuery);
    this.props.history.push(`/menu${session ? `?${session}` : ''}`);
  };

  isPaymentMethodAvailable = payment => {
    const find = ['REDEMPTION', 'IPAY88', 'WECHATPAY', 'VCASH'].find(
      value => value === payment.type,
    );

    const envSameWithPaymentOrEmpty = env === '' || payment.env === env;
    const finalResult = !find && envSameWithPaymentOrEmpty;

    return (
      !(
        payment.type === 'REDEMPTION' ||
        payment.type === 'IPAY88' ||
        payment.type === 'WECHATPAY' ||
        payment.type === 'VCASH'
      ) && envSameWithPaymentOrEmpty
    );
  };

  getAlimentsPoint = () => {
    return this.props.auth.user && this.props.auth.user.me
      ? this.props.auth.user.me.point || '0'
      : false;
  };

  ipay88Form = inputObject => {
    const IPAY88_GRAB_URL = 'https://payment.ipay88.com.my/ePayment/entry.asp';
    const MerchantCode = inputObject.ipay88MerchantCode;
    const ProdDesc = this.props.menu.menuList.merchant.name + ' (web)';
    const PaymentId = inputObject.ipay88PaymentId.toString(); //TNG: 538, GRABPAY: 523
    const UserName = inputObject.viewAObject.customer.name;
    const UserEmail = inputObject.viewAObject.customerEmail;
    const Signature = inputObject.ipaySha1;
    const RefNo = inputObject.refNo;
    const Amount = parsePrice(inputObject.amount);

    const params = {
      MerchantCode,
      PaymentId,
      RefNo,
      Amount,
      Currency: 'MYR',
      ProdDesc,
      UserName,
      UserEmail,
      UserContact: '1',
      Remark: '',
      Lang: 'UTF-8',
      Signature,
      ResponseURL: `${process.env.REACT_APP_ENV}web/fb/ipay88/result`,
      // "ResponseURL": window.location.origin + '/wechatVerify',
      BackendURL: `${process.env.REACT_APP_ENV}api/backend/ipay88/callback`,
    };

    function post(path, params, method = 'post') {
      const form = document.createElement('form');
      form.method = method;
      form.action = path;

      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const hiddenField = document.createElement('input');
          hiddenField.type = 'hidden';
          hiddenField.name = key;
          hiddenField.value = params[key];

          form.appendChild(hiddenField);
        }
      }

      document.body.appendChild(form);
      form.submit();
    }

    post(IPAY88_GRAB_URL, params);
  };

  renderAlertMsgModal = () => {
    let alertMsg = this.state.alertMsg;
    return (
      <ModalComponent openWhen={true}>
        <div id={'dropin-container'} className={'braintree-component'} />
        <div className='text-lg p-5'>{alertMsg}</div>
        <div className={'confirmation-button-container'}>
          <div className={'confirm-button'} onClick={this.toggleAlertMsgModal}>
            Okay, got it!
          </div>
        </div>
      </ModalComponent>
    );
  };

  renderDeliveryAlertNote = () => {
    let deliveryAlertNote = this.state.deliveryAlertNote;
    return (
      <ModalComponent openWhen={true}>
        <div id={'dropin-container'} className={'braintree-component'} />
        <div className='text-lg p-5'>{deliveryAlertNote}</div>
        <div className={'confirmation-button-container'}>
          <div
            className={'confirm-button'}
            onClick={this.hideDeliveryAlertNote}
          >
            Okay, got it!
          </div>
        </div>
      </ModalComponent>
    );
  };

  toggleAlertMsgModal = () => {
    this.setState({
      openAlertMessageModal: false,
    });
  };

  hideDeliveryAlertNote = () => {
    this.setState({
      deliveryAlertNote: '',
    });
  };

  closePhoneNumberModal = () => {
    this.setState({
      openPhoneNumberModal: false,
    });
  };

  checkPhoneNumber = async () => {
    let phoneNumber = this.state.getValidPhoneNumber;

    if (isValidPhoneNumber(phoneNumber)) {
      setStorage('phoneNumber', phoneNumber);
      await this.setState({
        openPhoneNumberModal: false,
      });
      await this.orderOnClick();
    } else {
      window.alert(
        'Your contact number is invalid, Please enter a valid contact number',
      );
    }
  };

  phoneNumberOnChange = e => {
    if (!isNaN(e.target.value.trim())) {
      this.setState({
        getValidPhoneNumber: e.target.value.trim(),
      });
    }
  };

  isDining() {
    return this.state.method === this.DINE_IN;
  }

  isDeliveryAndIsLogin() {
    return this.isDelivery() && getStorage('apiKey');
  }

  isTakeAway() {
    return (
      !!Number(this.props.global.query.takeAway) ||
      this.state.method === this.TAKE_AWAY
    );
  }

  isDelivery() {
    return !!Number(this.props.global.query.delivery);
  }

  getRemark() {
    if (this.state.method === this.TAKE_AWAY) {
      let loginContactNumber = this.props.auth.user.me.contactNo; // for user who edit their account to have phone number (FB, Google)
      let loginPhoneNumber = this.props.auth.user.me.phoneNo; // for user who sign in with phne number
      let localPhoneNumber = getStorage('phoneNumber');
      let remarkPhoneNumber = '';

      if (!!localPhoneNumber) {
        remarkPhoneNumber = localPhoneNumber;
      } else if (!!loginPhoneNumber) {
        remarkPhoneNumber = loginPhoneNumber;
      } else if (!!loginContactNumber) {
        remarkPhoneNumber = loginContactNumber;
      }

      switch (this.props.global.query.pickUpType) {
        case 'drive-thru':
          return (
            remarkPhoneNumber +
            ` ${getStorage('carPlateNumber')} ${getStorage('carDescription')}`
          );
      }

      return remarkPhoneNumber;
    } else {
      return '';
    }
  }

  getTableNo() {
    if (
      this.props.global.query.pickUpType &&
      this.props.global.query.pickUpType === 'drive-thru'
    ) {
      return 'Drive Thru';
    } else {
      return R.path(['query', 'tableNo'], this.props.global)
        ? this.props.global.query.tableNo
        : 'Pickup'; // from url tableNo
    }
  }

  onAddressClick = () => {
    // Check is user logged in
    if (!getStorage('apiKey')) {
      this.stateless.shouldProceedOrder = true;
      this.setState({
        showLoginDialog: true,
      });
      return;
    } else this.props.history.push('/addressList');
  };

  renderDiningMethodTab(isShowDetail) {
    const isNotTakeAway = R.compose(
      R.not,
      R.pathEq(['global', 'query', 'takeAway'], '1'),
    )(this.props);

    return (
      <div className={'method-container ' + (isShowDetail && 'expand')}>
        {isNotTakeAway && (
          <div
            className={`dine-in ${this.state.method === this.DINE_IN &&
              'selected'}`}
            onClick={this.methodOnClick(this.DINE_IN)}
          >
            <div className='message'>Dine In</div>
            <img
              src={`${process.env.PUBLIC_URL}/image/ic_dine_in_disable.png`}
              alt={'dine-in-logo'}
            />
          </div>
        )}

        <div
          className={`takeaway ${this.state.method === this.TAKE_AWAY &&
            'selected'}`}
          onClick={this.methodOnClick(this.TAKE_AWAY)}
        >
          <div className='message'>Take Away</div>
          <img
            src={`${process.env.PUBLIC_URL}/image/ic_takeaway_disable.png`}
            alt={'take-away-logo'}
          />
        </div>
      </div>
    );
  }

  renderConfirmPaymentModal() {
    return (
      <ModalComponent openWhen={this.state.isConfirmPayModal}>
        <div className='container'>
          <div className='title'>Pay at Counter</div>
          <div className='body'>
            <div>Tap Okay if you have completed this order.</div>
            <div>You are unable to return to this page after confirmation.</div>
          </div>
        </div>
        <div className='footer'>
          <div
            className='btn btn-cancel'
            onClick={() => this.toggleConfirmPayModal()}
          >
            Cancel
          </div>
          <div
            className='btn btn-primary'
            onClick={() => this.toggleConfirmPayModal(true)}
          >
            OK
          </div>
        </div>
      </ModalComponent>
    );
  }

  renderPayAtCounterModal() {
    return (
      <ModalComponent openWhen={this.state.openQRCodeModal}>
        <div className='title'>One More Step...</div>
        <img
          className='qr-code'
          src={`http://chart.apis.google.com/chart?cht=qr&choe=ISO-8859-1&chs=500x500&chl=${this.state.transactionId}`}
          alt='qrcode'
        />
        <div>Order ID: {this.state.transactionId}</div>
        <div>
          Please proceed for payment by flashing his QR Code at the counter
        </div>
        <div className='btn-primary' onClick={this.closeQrCode}>
          Done
        </div>
      </ModalComponent>
    );
  }

  renderSelectCheckoutMethodModal(finalTotal) {
    let currency = R.path(['merchant', 'currency'], this.props);

    return (
      <ModalComponent
        openWhen={this.state.openPaymentMethodModal}
        close={this.togglePaymentMethodModal}
      >
        <div className='container'>
          <div className='title'>Select Payment Method</div>
          <div className='body'>
            <div>
              {this.props.menu.menuList &&
                this.props.menu.menuList.merchant.paymentMethods
                  .filter(payment => this.isPaymentMethodAvailable(payment))
                  .sort((a, b) => {
                    const referArray = getPaymentMethodSortingArray();
                    return (
                      referArray.indexOf(a.type) - referArray.indexOf(b.type)
                    );
                  })
                  .map(payment => {
                    const currentPayment =
                      this.stateless.paymentMethodList[payment.type] || {};
                    const isAliment = payment.type === 'ALIMENTS_POINT';
                    const isTWOC2P_FPX = payment.type === 'TWOC2P_FPX';

                    const points = this.getAlimentsPoint();
                    const twoC2PMinAmount = getValueFromKeyAtSetting(
                      'fpx.min.amount',
                      this.props.settingsApi,
                    );

                    let disabled =
                      (isAliment && points && points < finalTotal * 100) ||
                      (isTWOC2P_FPX &&
                        twoC2PMinAmount &&
                        twoC2PMinAmount > finalTotal);
                    if (
                      payment.type === 'PAY_AT_COUNTER' &&
                      (this.isDelivery() || this.isTakeAway())
                    ) {
                      disabled = true;
                    }
                    return (
                      <div
                        key={payment.id}
                        className={
                          'payment-row ' + (disabled && 'payment-disabled')
                        }
                        onClick={this.onPaymentMethodClicked(payment)}
                      >
                        <img
                          className={
                            ' ' + (payment.type === 'WECHATPAY' && 'img-wechat')
                          }
                          src={currentPayment.img}
                          alt={currentPayment.name}
                        />
                        <span>
                          {currentPayment.name}
                          {isTWOC2P_FPX && disabled
                            ? ` ${currency} (min ${twoC2PMinAmount})`
                            : ''}
                        </span>
                        {isAliment && points && (
                          <span className='pts'>({points} pts)</span>
                        )}
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
      </ModalComponent>
    );
  }

  renderMissingPartnerModal() {
    return (
      <ModalComponent
        openWhen={this.state.isExceedMaximumOrder}
        close={this.closeMaximumOrder}
      >
        <div className='modal-content'>
          Please install
          <span className='font-weight-bold'>
            {' '}
            {this.props.global.query.partner}{' '}
          </span>
          to continue.
        </div>
        <div className={'confirmation-button-container'}>
          <div className={'confirm-button'} onClick={this.closeMaximumOrder}>
            Confirm
          </div>
        </div>
      </ModalComponent>
    );
  }

  renderGeneralMessageModal() {
    const closeFn =
      this.state.modal.cancelOnClick === null ? null : this.closeModalOnClick;
    return (
      <ModalComponent openWhen={this.state.modal.openModal} close={closeFn}>
        <div className={'message'}> {this.state.modal.modalMessage}</div>
        <div className={'confirmation-button-container'}>
          {!!closeFn && (
            <div className={'cancel-button'} onClick={closeFn}>
              Cancel
            </div>
          )}
          <div className={'confirm-button'} onClick={this.confirmClearOnClick}>
            Confirm
          </div>
        </div>
      </ModalComponent>
    );
  }

  makePayWithButton = isGettingPayment => {
    const getPartnerOrDefaulFrom = R.pathOr('default', ['query', 'partner']);

    const havePartner = !!R.path(['query', 'partner'])(this.props.global);
    const partner = getPartnerOrDefaulFrom(this.props.global);
    const partnerClassName = R.compose(
      R.toLower,
      R.join('-'),
      R.split(' '),
      getPartnerOrDefaulFrom,
    )(this.props.global);

    const isIpay88 = R.anyPass([isEqualIpay88Tng, isEqualIpay88GrabPay])(
      partner,
    );

    const isDeliverButDoNotHaveQuotationId =
      this.isDelivery() && !this.props.delivery.deliveryQuotation.id;

    const orderButtonBlurClass =
      ((isGettingPayment && this.state.isLogin) ||
        isDeliverButDoNotHaveQuotationId) &&
      'order-button-blur';

    const iPay88Logo =
      isIpay88 && isEqualIpay88GrabPay(partner)
        ? 'grab pay_logo.png'
        : isEqualIpay88Tng(partner)
        ? 'touchngo_logo.png'
        : '';

    return (
      <div
        className={`order-button large-text pay-with-partner ${partnerClassName} ${orderButtonBlurClass}`}
        onClick={this.orderOnClick}
      >
        {isIpay88 ? (
          <>
            <div className={`message padding-right`}>Pay with</div>
            <img
              src={`${process.env.PUBLIC_URL}/image/${iPay88Logo}`}
              alt={'partner-logo'}
            />
          </>
        ) : (
          <>
            {havePartner && (
              <img
                src={`${
                  process.env.PUBLIC_URL
                }/image/${partner.toLowerCase()}_logo.png`}
                alt={'partner-logo'}
              />
            )}
            <div className={`message padding-left`}>
              {havePartner ? `Pay with ${partner}` : 'Pay Now'}
            </div>
          </>
        )}
      </div>
    );
  };

  closeCoffeeModal = () => {
    this.setState({
      selectedCoffee: null,
      selectedUuid: '',
    });
  };

  onCoffeeUpdated = coffee => {
    this.setState({
      selectedCoffee: { ...this.state.selectedCoffee, ...coffee },
    });
  };

  render() {
    window.b = this;
    const { couponStatus } = this.state;
    const { global } = this.props;
    if (!this.props.merchant) {
      return false;
    }
    const currency = this.props.merchant.currency;
    const { allowRemark } = this.props.menu.menuList.merchant;

    if (!this.getCheckOutItems().length)
      return <CartEmpty onClick={this.redirectToMenu} />;

    const isShowDetail = !!this.state.isShowDetail;
    const {
      serviceCharge,
      serviceTax,
      SUB_TOTAL,
      discountedPrice,
      serviceChargerTotal,
      serviceTaxTotal,
      finalTotal,
    } = this.getAllRelatedPrice();

    console.log(`this.getAllRelatedPrice()`, this.getAllRelatedPrice());
    const isGettingPayment = !!(
      getStorage('apiKey') &&
      !this.props.global.query.partner &&
      this.state.isBrainTreeAvailable &&
      (!this.state.dropinInstance || this.state.isGettingCreditCards)
    );

    const merchant = path('menu.menuList.merchant', this.props);

    const deliveryAddress = new DeliveryAddress(
      this.props.delivery.selectedAddress,
    );
    const deliveryQuotation = new DeliveryQuotation(
      this.props.delivery.deliveryQuotation,
    );
    const deliveryQuotationAmount = deliveryQuotation.amount || 0.0;
    const deliveryOriginalAmount = deliveryQuotation.originalAmount
      ? deliveryQuotationAmount !== deliveryQuotation.originalAmount
        ? deliveryQuotation.originalAmount
        : ''
      : '';

    return (
      <div className={'checkout-container'} ref={this.checkOutPageRef}>
        <AppBar goBack={this.backOnClick}>
          <div>Order</div>
        </AppBar>
        {this.isDelivery() && (
          <div className='bg-white'>
            <div className='p-3 pb-0 flex flex-row justify-center items-center'>
              <FontAwesomeIcon
                icon={'map-marker-alt'}
                className='text-aliments mr-2 h-6'
              />
              <div className='flex-auto'>{this.props.merchant.name}</div>
              <div className='flex flex-row justify-center items-center flex-grow-0'>
                <img
                  src='/image/delivery.png'
                  alt='takeAway'
                  className='h-6 mr-2'
                />
                Delivery
              </div>
            </div>
            <div className='text-aliments pl-3 w-3 text-center'>|</div>
            <div
              className='p-3 pt-0 flex flex-row justify-center items-center'
              onClick={this.onAddressClick}
            >
              <FontAwesomeIcon
                icon={'map-marker-alt'}
                className='text-aliments mr-2 h-6 flex-grow-0'
              />
              <div className={'flex-1'}>
                {deliveryAddress.address || 'Select a delivery destination'}
              </div>
            </div>
          </div>
        )}
        <div
          className={`item-list-container ${!this.state.modal.openModal &&
            'smooth-touch'}`}
        >
          {this.getCheckOutItems().map((x, i) => (
            <div key={x.uuid}>
              <OrderItem
                handleOnClicked={() => {
                  this.setState({
                    selectedCoffee: {
                      ...x.selectedCoffee,
                      quantity: x.quantity,
                    },
                    selectedUuid: x.uuid,
                  });
                }}
                key={x.name + x.desc + x.addon + i}
                {...x}
                quantityOnClick={this.quantityOnClick}
                checkboxOnClick={this.checkboxOnClick}
              />
            </div>
          ))}
        </div>
        <div className={'action-container'}>
          <div className={'hide-button'}>
            <FontAwesomeIcon
              icon={'chevron-up'}
              className={`${this.state.isShowDetail && 'fa-rotate-up'}`}
              onClick={this.hideOnClick}
            />
          </div>
          <div className={'price-detail-container'}>
            {this.isDelivery() && (
              <div className={'space-between-row'}>
                <div>DELIVERY</div>
                <PriceBox
                  units={currency}
                  originalPrice={deliveryOriginalAmount}
                  price={deliveryQuotationAmount}
                />
              </div>
            )}
            <div className={'space-between-row'}>
              <div>SUBTOTAL</div>
              <PriceBox units={currency} price={SUB_TOTAL} />
            </div>
            {!!discountedPrice &&
              this.stateless.couponApiReturn &&
              this.stateless.couponApiReturn.coupon &&
              this.stateless.couponApiReturn.coupon.merchantPromo && (
                <div className={'space-between-row'}>
                  <div>DISCOUNTED PRICE</div>
                  <PriceBox
                    units={currency}
                    price={discountedPrice}
                    showNegative={true}
                  />
                </div>
              )}
            {!!serviceCharge && (
              <div className={'space-between-row'}>
                <div>SERVICE CHARGE @ {serviceCharge}%</div>
                <PriceBox units={currency} price={serviceChargerTotal} />
              </div>
            )}
            {!!serviceTax && (
              <div className={'space-between-row'}>
                <div>
                  {currency === 'RM' ? 'SERVICE TAX' : 'GST'} @{' '}
                  {serviceTax.toFixed(2)}%
                </div>
                <PriceBox units={currency} price={serviceTaxTotal} />
              </div>
            )}
            {!!discountedPrice &&
              this.stateless.couponApiReturn &&
              this.stateless.couponApiReturn.coupon &&
              !this.stateless.couponApiReturn.coupon.merchantPromo && (
                <div className={'space-between-row'}>
                  <div>DISCOUNTED PRICE</div>
                  <PriceBox
                    units={currency}
                    price={discountedPrice}
                    showNegative={true}
                  />
                </div>
              )}
          </div>
          <div className={`coupon-container ${isShowDetail && 'expand'}`}>
            <div style={{ position: 'relative' }} className={`coupon-code`}>
              <div
                className={
                  couponStatus && couponStatus.isValid
                    ? 'color-green'
                    : 'color-red'
                }
              >
                {(couponStatus && couponStatus.message) || 'COUPON CODE'}
              </div>
              <input
                type='text'
                value={this.state.coupon}
                placeholder={'eg.DISCOUNT'}
                maxLength='10'
                disabled={this.state.isLockCouponEntry}
                onChange={this.couponOnChange}
                onKeyPress={event => {
                  if (
                    event.code === 'Enter' ||
                    event.key === 'Enter' ||
                    event.keyCode === 13 ||
                    event.which === 13
                  ) {
                    event.preventDefault();
                    this.applyCouponOnClick(SUB_TOTAL)();
                  }
                }}
              />
              {!this.isDining() &&
                !global.query.tableNo &&
                this.state.isLockCouponEntry &&
                this.isPreOrderSupported() && (
                  <div
                    onClick={() => {
                      this.resetCoupon();
                      this.recalculateTotal();
                    }}
                    style={{ position: 'absolute', right: '8px' }}
                    className={'p-3'}
                  >
                    <FontAwesomeIcon icon='trash' color='red' />
                  </div>
                )}
            </div>
            {/*is dinning or not dinning but not support preOrder or have table No*/}
            {(this.isDining() ||
              global.query.tableNo ||
              (!this.isDining() && !this.isPreOrderSupported())) &&
              (this.state.isLockCouponEntry ? (
                <div
                  className={
                    'apply-button large-text ' +
                    (!!this.state.coupon.length && 'valid')
                  }
                  onClick={() => {
                    this.resetCoupon();
                    this.recalculateTotal();
                  }}
                >
                  REMOVE
                </div>
              ) : (
                <div
                  className={
                    'apply-button large-text ' +
                    (!!this.state.coupon.length && 'valid')
                  }
                  onClick={this.applyCouponOnClick(SUB_TOTAL)}
                >
                  APPLY
                </div>
              ))}
            {!this.isDining() &&
              !global.query.tableNo &&
              this.isPreOrderSupported() && (
                <ShowPreOrderTime
                  method={this.state.method}
                  merchant={this.props.menu.menuList.merchant}
                  selectedSlot={this.state.orderSlot}
                  onSelect={slot => {
                    this.setState({
                      orderSlot: slot,
                    });
                  }}
                />
              )}
          </div>
          {!this.isDelivery() && this.renderDiningMethodTab(isShowDetail)}
          {/*{this.renderToggleButtonForCashback()}*/}
          {!this.props.global.query.partner && (
            <div
              onClick={
                isGettingPayment || finalTotal === 0
                  ? () => {}
                  : this.togglePaymentMethodModal
              }
              className={
                'payment-method ' +
                (isShowDetail && ' expand ') +
                (isGettingPayment && ' payment-method-blur')
              }
            >
              {this.state.paymentMethod ? (
                <React.Fragment>
                  {this.state.paymentMethod.type && (
                    <img
                      className={
                        ' ' +
                        (this.state.paymentMethod.type === 'WECHATPAY' &&
                          'img-wechat')
                      }
                      src={this.state.paymentMethod.img}
                      alt='credit-card'
                    />
                  )}
                  {this.state.paymentMethod.type ? (
                    <span>
                      {this.state.paymentMethod.description ||
                        `${this.state.paymentMethod.name}`}
                    </span>
                  ) : (
                    <span>{this.state.paymentMethod}</span>
                  )}
                </React.Fragment>
              ) : (
                'Select payment method'
              )}
              {isGettingPayment && (
                <div className='loading-overlay'>
                  <FontAwesomeIcon icon={'circle-notch'} spin />
                </div>
              )}
            </div>
          )}
          <div className={'total-container'}>
            <div className={'total'}>
              TOTAL{' '}
              <span className={'large-text'}>
                {currency} {parsePrice(finalTotal)}
              </span>
            </div>

            {this.makePayWithButton(isGettingPayment)}
          </div>
        </div>

        {/* Popup Modal */}
        {this.state.submitted && <Loading />}
        {this.renderGeneralMessageModal()}
        <div className='maximum-order-modal'>
          {this.renderMissingPartnerModal()}
        </div>
        <LoginByDialog
          openWhen={
            this.state.showLoginDialog || this.props.auth.showLoginDialog
          }
          close={() => {
            this.props.authAction.closeLoginDialog();
            this.loginOnClose();
          }}
          isDining={this.state.method === this.DINE_IN}
          qrCompulsoryLogin={merchant.qrCompulsoryLogin}
          loginOnClick={this.loginOnClick}
        />

        {/*Prompt phone number modal (When pick up)*/}
        <div
          className={
            this.state.openPhoneNumberModal
              ? 'braintree-container-expand'
              : 'braintree-container-hide'
          }
        >
          <ModalComponent openWhen={this.state.openPhoneNumberModal}>
            <div className='login-dialog pt-20 pb-8 m-auto'>
              <div className='text-aliments text-4xl font-bold pb-10'>
                Please enter your contact number
              </div>
              <div className='flex flex-col my-4 items-center'>
                <LoginTextInput
                  placeholder={'Eg. 01123456789'}
                  onChange={this.phoneNumberOnChange}
                  type={'text'}
                  value={this.state.getValidPhoneNumber}
                />
                <Button
                  text={'Continue'}
                  className='rounded-full uppercase'
                  style={{
                    minWidth: '160px',
                    marginTop: '30px',
                  }}
                  onClick={this.checkPhoneNumber}
                />
                <Button
                  text={'Cancel'}
                  className='rounded-full uppercase'
                  style={{
                    minWidth: '160px',
                    marginTop: '30px',
                  }}
                  isOutline={true}
                  onClick={this.closePhoneNumberModal}
                />
              </div>
            </div>
          </ModalComponent>
        </div>

        <div
          className={
            'braintree-container ' +
            (this.state.openBrainTreeModal
              ? 'braintree-container-expand'
              : 'braintree-container-hide')
          }
        >
          <ModalComponent openWhen={true} close={this.toggleBrainTreeModal}>
            <div id={'dropin-container'} className={'braintree-component'} />
            <div className={'confirmation-button-container'}>
              <div className={'confirm-button'} onClick={() => this.getNonce()}>
                Confirm
              </div>
            </div>
          </ModalComponent>
        </div>
        <div
          className={
            'braintree-container ' +
            (this.state.openAlertMessageModal
              ? 'braintree-container-expand'
              : 'braintree-container-hide')
          }
        >
          {this.renderAlertMsgModal()}
        </div>
        <div
          className={
            'braintree-container ' +
            (this.state.deliveryAlertNote
              ? 'braintree-container-expand'
              : 'braintree-container-hide')
          }
        >
          {this.renderDeliveryAlertNote()}
        </div>
        <div className='payment-method-container'>
          {this.renderSelectCheckoutMethodModal(finalTotal)}
        </div>
        <OperatingHoursComponent
          openWhen={this.state.showOperatingHours}
          toggle={this.toggleOperatingHours}
          selectedMerchant={this.props.menu.menuList.merchant}
        />
        <div className='qrcode-modal'>{this.renderPayAtCounterModal()}</div>
        <div className='confirm-pay-modal'>
          {this.renderConfirmPaymentModal()}
        </div>

        <MenuDetailModal
          mode={'updateCart'}
          allowRemark={allowRemark}
          menu={this.props.menu}
          selectedCoffee={this.state.selectedCoffee}
          onCoffeeUpdated={this.onCoffeeUpdated}
          onCancel={this.closeCoffeeModal}
          onConfirm={coffee => {
            this.props.menuAction.updateCart(
              this.state.selectedUuid,
              coffee,
              this.props.global.query.merchantId,
            );
            this.props.checkoutAction.resetCheckOutCount();
            this.recalculateTotal();
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    menu: state.Menu,
    checkout: state.Checkout,
    merchant: state.Menu.menuList && state.Menu.menuList.merchant,
    global: state.Global,
    auth: state.Auth,
    delivery: state.Delivery,
    settingsApi: state.Settings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    checkoutAction: bindActionCreators(checkoutAction, dispatch),
    authAction: bindActionCreators(authAction, dispatch),
    menuAction: bindActionCreators(menuAction, dispatch),
    deliveryAction: bindActionCreators(deliveryAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
