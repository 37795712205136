import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import * as queryString from 'query-string';
import React, { Component } from 'react';
import './App.scss';
import WechatBlocker from './component/wechatBlocker/wechatBlocker';
import Routes from './routes';
import { isWechat } from './services/utils';
import { bindActionCreators } from 'redux';
import * as settingAction from './actions/settingAction';
import { connect } from 'react-redux';

library.add(fas, fab, far);

class App extends Component {
  componentDidMount() {
    this.props.settingsAction.getSettingsApi();
  }

  render() {
    if (
      isWechat() && // If current browser agent is wechat.
      window.location.search &&
      queryString.parse(window.location.search) &&
      queryString.parse(window.location.search).partner &&
      queryString
        .parse(window.location.search) // And user is using others than wechat pay to make payment.
        .partner.toLowerCase() !== 'wechat pay'
    ) {
      // Block user from continue to browse app in wechat browser.
      // Instead, ask them to open in phone's browser.
      return <WechatBlocker />;
    } else
      return (
        <div className='App'>
          <main>
            <Routes />
          </main>
        </div>
      );
  }
}

function mapStateToProps(state) {
  return {
    settings: state.Settings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingsAction: bindActionCreators(settingAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
