import PropTypes from 'prop-types';
import React from 'react';
import login, { WECHAT_PROVIDER } from '../../services/auth';
import { isWechat } from '../../services/utils';
import ModalComponent from '../modal/modal';
import './loginByDialog.scss';
import LoginDialog from './loginDialog';

const LoginByDialog = props => {
  // Direct process to login, since the option is only wechat
  if (isWechat() && props.openWhen) {
    login(WECHAT_PROVIDER).then(resp => {
      props.close();
      props.loginOnClick(resp);
    });
    return false;
  }

  return (
    <ModalComponent openWhen={props.openWhen} close={props.close}>
      {props.openWhen && (
        <LoginDialog close={props.close} qrCompulsoryLogin={props.qrCompulsoryLogin} isQRLoginOrGuest={props.isQRLoginOrGuest} loginOnClick={props.loginOnClick} isDining={props.isDining} selectGuest={props.selectGuest} />
      )}
    </ModalComponent>
  );
};

LoginByDialog.propTypes = {
  openWhen: PropTypes.bool,
  close: PropTypes.func,
  loginOnClick: PropTypes.func,
  isDining: PropTypes.bool,
  qrCompulsoryLogin: PropTypes.bool,
  isQRLoginOrGuest: PropTypes.bool,
  selectGuest: PropTypes.func
};

export default LoginByDialog;
