import React from 'react';
import PropTypes from 'prop-types';

export default function SelectOptions(props) {
  const { name, options, label, id, required, defaultValue } = props;

  return (
    <div className='mb-5'>
      {label && (
        <label
          htmlFor={name}
          className={`block mb-2 text-sm font-medium ${
            required ? 'required' : ''
          }`}
        >
          {label}
        </label>
      )}
      <select
        id={id}
        name={name}
        className='w-full bg-transparent border-0 border-b-2 border-gray-300 rounded-none focus:outline-none focus:border-yellow-500 pb-1 pl-0'
        style={{ textIndent: 0 }}
        required={required}
      >
        <option disabled selected value=''>
          Select {label}
        </option>
        {options.map(option => (
          <option
            key={option.value}
            value={option.value}
            selected={defaultValue ? defaultValue === option.value : false}
          >
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

SelectOptions.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  id: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
