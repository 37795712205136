import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authAction from '../../actions/authAction';
import { getDateFromNow } from '../../services/utils';
import ModalComponent from '../../component/modal/modal';

export class CashbackHistoryPage extends Component {
  state = {
    showHowToCashbackDialog: false,
  };

  constructor(props) {
    super(props);

    this.props.authAction.getUserAccount();
  }

  renderHowItWorks() {
    return (
      <div className='p-8'>
        <div className='font-bold text-2xl'>How it works</div>
        <ul>
          <li className='pb-8'>
            Earn cashback when you order with selected participating merchants.
          </li>
          <li className='pb-8'>
            Cashback will be credited to your HUBBO POS cashback wallet.
          </li>
          <li className='pb-8'>
            Cashback is valid to use in HUBBO POS app on next purchase with the
            same merchant or chain.
          </li>
          <li className='pb-8'>
            Cashback valid for 30 days from the order date.
          </li>
          <li className='pb-8'>
            Cashback is not exchange-able to cash or any other form of physical
            or digital payment.
          </li>
        </ul>
      </div>
    );
  }

  setShowHowToCashbackDialog(isOpen) {
    this.setState({
      showHowToCashbackDialog: isOpen,
    });
  }

  renderCashback(list: []) {
    return (
      <div className='order-history'>
        {list.map(obj => (
          <div
            key={'1'}
            onClick={() => this.setShowHowToCashbackDialog(true)}
            style={{ borderBottom: '1px solid lightgrey' }}
            className='order-history-list flex'
          >
            <img
              style={{ padding: '10px 30px 10px 10px' }}
              src={obj.image}
              alt='logo'
              height='70px'
              width='70px'
            />
            <div className='flex flex-col justify-center'>
              <div className='font-bold'>{obj.chain}</div>
              <div className='text-sm' style={{ color: 'gray' }}>
                {obj.currency} {obj.balance.toFixed(2)}, valid till:{' '}
                {getDateFromNow(obj.validTill, 0, 'YYYY-MM-DD')}
              </div>
            </div>
          </div>
        ))}
        <ModalComponent
          openWhen={this.state.showHowToCashbackDialog}
          close={() => this.setShowHowToCashbackDialog()}
          closeIconColor={'black'}
          stylePadding='30px'
          styleHeight='auto'
        >
          <div className='font-bold text-2xl'>How to use Cashback</div>
          <div className='font-bold pt-3'>[Dine-in]</div>
          <ul>
            <li className='pb-8'>Valid at participating outlets only.</li>
            <li className='pb-8'>
              When paying your bill, tell the cashier your phone number.
            </li>
            <li className='pb-8'>
              Your bill will be discounted based on your remaining cashback.
            </li>
          </ul>
          <div className='font-bold'>[HUBBO POS Delivery]</div>
          <ul>
            <li className='pb-8'>Valid for HUBBO POS delivery only.</li>
            <li className='pb-8'>
              Your bill will be discounted automatically based on your remaining
              cashback.
            </li>
          </ul>
        </ModalComponent>
      </div>
    );
  }

  render() {
    const user = this.props.auth.user;

    if (user && user.cashbacks) {
      if (user.cashbacks.length >= 1) {
        return this.renderCashback(user.cashbacks);
      } else {
        return this.renderHowItWorks();
      }
    } else {
      return this.renderHowItWorks();
    }
  }
}

function mapStateToProps(state) {
  return {
    auth: state.Auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    authAction: bindActionCreators(authAction, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CashbackHistoryPage);
