import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './rightArrowButton.scss';

class RightArrowButton extends Component {
  render() {
    return (
      <div className={`right-arrow-button ${this.props.className || ''}`}>
        <FontAwesomeIcon
          icon={'chevron-right'}
          className={this.props.open ? 'rotate-90-clockwise' : ''}
        />
      </div>
    );
  }
}

export default RightArrowButton;
