import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as deliveryAction from '../../actions/deliveryAction';
import * as globalAction from '../../actions/globalAction';
import AppBar from '../../component/appBar/appBar';
import DeliveryAddress from '../../model/deliveryAddress';
import './addressListPage.scss';
import AddressListTile from './addressListTile';

class AddressListPage extends Component {
  componentDidMount() {
    this.props.deliveryAction.getAddress();
  }

  render() {
    const { addresses } = this.props.delivery;
    const { search } = this.props.history.location;

    return (
      <div className='address-list-page'>
        <AppBar goBack={this.goBack}>Choose a place</AppBar>
        {addresses.map(address => (
          <AddressListTile
            key={address.id}
            address={new DeliveryAddress(address)}
            onDeleteClick={this.onAddressDeleteClick(address.id)}
            onEditClick={this.onAddressEditClick(address)}
            onClick={() => {
              // console.log(`address`, address);

              this.props.deliveryAction.selectAddress(address);
              if(search){
                this.props.history.push('/checkout' + search);
              }else{
                this.props.history.push('/checkout');
              }

            }}
          />
        ))}
        <this.addNewButton />
      </div>
    );
  }

  goBack = this.props.history.goBack;
  addNewButton = () => (
    <div
      className='add-new-btn rounded-full bg-red-500 p-4 m-4 font-bold text-center'
      onClick={this.onAddNewAddressClick}
    >
      <div>Add New Address</div>
    </div>
  );
  onAddressDeleteClick = id => () => {
    if (this.props.delivery.addresses.length > 1) {
      this.props.deliveryAction.deleteAddress(id);
    } else {
      alert('Must have at least 1 address.');
    }
  };

  onAddressEditClick = address => () => {
    this.props.history.push('/addressForm', { resp: { ...address } });
  };

  onAddNewAddressClick = () => {
    this.props.history.push('/addressSelection');
  };
}

AddressListPage.propTypes = {
  history: PropTypes.object,
  deliveryAction: PropTypes.shape({
    getAddress: PropTypes.func,
    deleteAddress: PropTypes.func,
  }),
  delivery: PropTypes.shape({
    addresses: PropTypes.array,
  }),
};

function mapStateToProps(state) {
  return {
    global: state.Global,
    delivery: state.Delivery,
    auth: state.Auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    globalAction: bindActionCreators(globalAction, dispatch),
    deliveryAction: bindActionCreators(deliveryAction, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddressListPage);
