import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loading } from '../../component/loading/loading';
import {
  getDateFromNow,
  getQueryFromPropsOrCache,
  getStorage,
  getValueFromKeyAtSetting,
  path,
  setStorage, shouldRequestServerOTP,
} from '../../services/utils';
import './cashbackLandingPage.scss';
import LoginTextInput from '../../component/loginByDialog/loginTextInput';
import Button from '../../component/button/button';
import ModalComponent from '../../component/modal/modal';
import OtpDialog from '../../component/loginByDialog/otpDialog';
import {
  firebasePhoneLogin,
  getOTPBySMS,
  loginWithSmsOTP,
} from '../../services/auth';
import * as authAction from '../../actions/authAction';
import ApiService from '../../services/api';
import PrimaryButton from '../../component/button/primaryButton';
import queryString from 'query-string';
import { isValidPhoneNumber } from '../../services/helper';

class CashbackLandingPage extends PureComponent {
  state = {
    cashbackErrorMsg: '',
    phoneNumber: '',
    requestOtp: false,
    isLoading: false,
    cashback: undefined,
    merchant: undefined,
    cashbackSuccessDialog: undefined,
    apiKey: getStorage('apiKey'),
  };

  constructor(props) {
    super(props);

    const parsedQuery = getQueryFromPropsOrCache(props, ['uuid']);

    if (parsedQuery.uuid) {
      const uuid = parsedQuery.uuid;
      this.getCashbackStatus(uuid);
    }
  }

  getCashbackStatus(uuid) {
    ApiService.get(`/api/customer/v2/cashback/check/${uuid}`).then(resp => {
      if (resp.code === '200') {
        this.setState({
          cashback: resp.cashback,
          merchant: resp.merchant,
        });
      } else if (resp.message === 'invalid.qr') {
        this.setCashbackErrorMsg(
          'Oops, someone else already claimed the cashback for this receipt.',
        );
      } else {
        this.setCashbackErrorMsg(
          `Something went wrong, Please try again later. \nReason: ${resp.message}`,
        );
      }
    });
  }

  postClaimCashback(uuid) {
    if (!this.state.apiKey) {
      return;
    }

    ApiService.put(`/api/customer/v2/cashback/claim/${uuid}`).then(resp => {
      if (resp.code === '200') {
        this.setState({
          cashbackSuccessDialog: resp.customerCashback,
        });
      } else {
        if (resp.message === 'reached.maximum.claim.daily') {
          this.setCashbackErrorMsg(
            "Oops, You've reached your cashback limit for today.",
          );
        } else if (resp.message === 'invalid.qr') {
          this.setCashbackErrorMsg(
            'Oops, someone else or you already earn cashback for this receipt.',
          );
        } else if (resp.message === 'api.key.invalid') {
          alert('Session expired. Please login again.');
          this.setState({
            apiKey: null,
          });
          setStorage('apiKey', null);
        } else {
          this.setCashbackErrorMsg(
            `Something went wrong. Please try again later. \nReason: ${resp.message}`,
          );
        }
      }
    });
  }

  onPhoneNumberChange = event => {
    let value = event.target.value.trim();
    value = value.replace('+', '');

    if (isNaN(value)) {
      return;
    }

    this.setState({
      phoneNumber: value.startsWith('01') ? `+6${value}` : `+${value}`,
    });
  };

  async onFirebaseOtpResult(result, uuid) {
    const { phoneNumber } = result.user;
    const token = await result.user.getIdToken();
    firebasePhoneLogin(phoneNumber, token).then(async resp => {
      this.setState({
        apiKey: getStorage('apiKey'),
      });
      await this.props.authAction.getUserAccount();
      await this.postClaimCashback(uuid);
      this.setOtpDialog(false);
    });
  }

  loginUsingFirebaseToken(otpCode, uuid) {
    window.confirmationResult
      .confirm(otpCode)
      .then(result => this.onFirebaseOtpResult(result, uuid))
      .catch(e => {
        if (e.code) {
          switch (e.code) {
            case 'auth/invalid-verification-code':
              alert('Invalid OTP Code');
              break;

            default:
              alert('Sign-In Error \n' + e.code);
          }
        } else {
          alert('Sign-In Error, Please try again');
        }
      });
  }

  async loginUsingServerOTP(phoneNumber, otpCode, uuid) {
    const resp = await loginWithSmsOTP(phoneNumber, otpCode);
    if (resp['code'] === '200') {
      this.setState({
        apiKey: getStorage('apiKey'),
      });
      await this.props.authAction.getUserAccount();
      await this.postClaimCashback(uuid);
      this.setOtpDialog(false);
    } else {
      alert(resp['message'] || 'Something went wrong. Please try again later');
    }
    this.setLoading(false);
  }

  requestFirebaseOTP(finalNumber) {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new window.firebase.auth.RecaptchaVerifier(
        'sign-in-button',
        {
          size: 'invisible',
          callback: function(response) {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
          },
        },
      );
    }

    const appVerifier = window.recaptchaVerifier;

    window.firebase
      .auth()
      .signInWithPhoneNumber(finalNumber, appVerifier)
      .then(confirmationResult => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        this.setOtpDialog(true);
      })
      .catch(function(error) {
        // Error; SMS not sent
        // ...
        if (error.code === 'auth/invalid-phone-number') {
          alert(`Invalid Phone Number: ${error.message}`);
        } else alert(`Too many tries. Please try login with Google or Facebook instead.`);
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  async requestServerOTP(finalNumber) {
    const resp = await getOTPBySMS(finalNumber);
    if (resp['code'] === '200') {
      setTimeout(() => {
        this.setOtpDialog(true);
        this.setLoading(false);
        clearTimeout();
      }, 2000);
    } else {
      this.setLoading(false);
      alert(resp['message'] || 'Something Went Wrong. Please try again later.');
    }
  }

  async onOtpSubmit(otpCode, uuid) {
    if (!otpCode) {
      alert('Otp code is required');
    }

    let phoneNumber = this.getPhoneNumberWithCountryCode();
    this.setLoading(true);

    if (shouldRequestServerOTP(phoneNumber)) {
      await this.loginUsingServerOTP(phoneNumber, otpCode, uuid);
    } else {
      this.loginUsingFirebaseToken(otpCode, uuid);
    }
  }

  async onPhoneNumberRequestOTP() {
    const finalNumber = this.getPhoneNumberWithCountryCode();

    if (!finalNumber) {
      alert('Phone number is required.');
      return;
    } else if (!isValidPhoneNumber(finalNumber)) {
      alert('Invalid Phone Number');
      return;
    }

    this.setLoading(true);

    if (shouldRequestServerOTP(finalNumber)) {
      await this.requestServerOTP(finalNumber);
    } else {
      this.requestFirebaseOTP(finalNumber);
    }
  }

  setCustomerCashback(obj) {
    this.setState({
      cashbackSuccessDialog: obj,
    });
  }

  setCashbackErrorMsg(error) {
    this.setState({
      cashbackErrorMsg: error,
    });
  }

  setLoading(isLoading) {
    this.setState({
      isLoading: isLoading,
    });
  }

  setOtpDialog(open) {
    this.setState({
      requestOtp: open,
    });
  }

  getPhoneNumberWithCountryCode(): string {
    const phoneNumber = this.state.phoneNumber;
    if (phoneNumber.startsWith('+')) return phoneNumber;
    else if (phoneNumber.startsWith('601')) return '+' + phoneNumber;
    else if (phoneNumber.startsWith('01')) return '+6' + phoneNumber;
    else if (phoneNumber.startsWith('1')) return '+60' + phoneNumber;
    else return '';
  }

  getBottomPoweredByAliments(textColor) {
    return (
      <div className='bottom'>
        <div className='powered-by'>
          <a style={{ color: textColor }} href='https://aliments.my'>
            Powered by HUBBO POS
          </a>
        </div>
      </div>
    );
  }

  render() {
    const {
      cashbackSuccessDialog,
      phoneNumber,
      cashbackErrorMsg,
      requestOtp,
      isLoading,
      apiKey,
      cashback,
      merchant,
    } = this.state;
    const { settingsApi } = this.props;
    return (
      <div
        className={'cashback-landing-page'}
        style={{
          backgroundColor: `${merchant ? null : 'grey'}`,
          backgroundImage: `${
            merchant ? `url(${merchant.landingBackgroundImg})` : null
          }`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'auto 100%',
          backgroundPosition: 'center',
          boxShadow: 'inset 0 0 0 2000px rgba(0,0,0,0.5)',
        }}
      >
        {isLoading && <Loading />}
        <div className='center'>
          <div className='center-contain'>
            {merchant ? (
              <div>
                <div
                  className={'logo'}
                  style={{ backgroundImage: `url(${merchant.primeLogo})` }}
                />
                <div className='text-primary text-2xl'>TOTAL CASHBACK</div>
                <div className='text-6xl'>
                  {merchant.currency} {(cashback ? cashback.amount : 0).toFixed(2)}
                </div>
                <div>{merchant.name}</div>
                <div style={{ height: '24px' }} />
              </div>
            ) : (
              <div />
            )}
            {cashbackErrorMsg ? (
              <div className='flex flex-col center'>
                <div style={{ fontWeight: '600', padding: '10px 30px' }}>
                  {cashbackErrorMsg}
                </div>
                <PrimaryButton
                  rounded={true}
                  primaryColor={true}
                  className='w-auto'
                  onClick={() =>
                    this.props.history.replace(`/cashback-history`)
                  }
                >
                  <div>Go to My Cashback</div>
                </PrimaryButton>
              </div>
            ) : (
              <div />
            )}
            {apiKey && cashback && !cashbackErrorMsg ? (
              <PrimaryButton
                rounded={true}
                primaryColor={true}
                onClick={() => this.postClaimCashback(cashback.uuid)}
              >
                <div>Claim Now</div>
              </PrimaryButton>
            ) : (
              <div />
            )}
          </div>
        </div>

        {apiKey || cashbackErrorMsg ? (
          this.getBottomPoweredByAliments('white')
        ) : (
          <div
            style={{
              backgroundColor: 'whitesmoke',
              borderRadius: '30px 30px 0px 0px',
              display: 'flex',
              flexDirection: 'column',
              padding: '10px',
              textAlign: 'center',
            }}
          >
            <div
              style={{ color: 'black', fontWeight: 'bold', padding: '10px' }}
            >
              Claim with your phone number
            </div>
            <LoginTextInput
              margin='0px 10px'
              placeholder='Phone number'
              value={phoneNumber}
              onChange={this.onPhoneNumberChange}
            />
            <Button
              text={'CONTINUE'}
              className='rounded-full uppercase'
              style={{
                margin: '10px',
              }}
              onClick={async () => await this.onPhoneNumberRequestOTP()}
            />
            <p className='text-sm mx-4 text-gray-500'>
              By continuing, you agree to our T&Cs* and{' '}
              <a
                target='_blank'
                className='text-gray-500'
                href='https://docs.google.com/document/d/e/2PACX-1vTvbWFdaobxK9c7vU3T_nwrcoIlhRp3tLv1vZjYE1jjyRxgQ85w2ZICGbxhtgw7E0qA3oLARMPJKzAItmTqT74/pub'
              >
                Privacy Policy
              </a>
              , and your data may be shared with our affliated companies in the
              Grab group for data analytics to improve service offerings and
              personalize your experience on Hubbo and Grab. For more
              information, please refer to <br />
              <a
                target='_blank'
                className='text-gray-500'
                href='https://www.grab.com/my/terms-policies/privacy-notice/'
              >
                Grab's Privacy Notice
              </a>
              .
            </p>
            {this.getBottomPoweredByAliments()}
          </div>
        )}
        <div id='sign-in-button' className='hidden' />

        <ModalComponent
          closeIconColor={'black'}
          styleWidth='auto'
          styleHeight='auto'
          stylePadding='unset'
          close={() => {
            this.setLoading(false);
            this.setOtpDialog(false);
          }}
          openWhen={requestOtp}
        >
          <OtpDialog
            onOtpSubmit={async otp => this.onOtpSubmit(otp, cashback.uuid)}
            onResentOtpClick={async () => {
              this.setOtpDialog(false);
              await this.onPhoneNumberRequestOTP();
            }}
            phoneNumber={this.getPhoneNumberWithCountryCode()}
          />
        </ModalComponent>

        {cashbackSuccessDialog && (
          <ModalComponent
            styleWidth='auto'
            styleHeight='auto'
            openWhen={cashbackSuccessDialog}
          >
            <div className='center flex flex-col'>
              <div className='text-black text-3xl p-4'>
                Hurray, Claimed Successfully!!!
              </div>
              <div className='text-black pt-8 pb-8'>
                You've claimed {cashbackSuccessDialog.currency}{' '}
                {cashback.amount.toFixed(2)} of the cashback, valid until{' '}
                {getDateFromNow(
                  cashbackSuccessDialog.validTill,
                  0,
                  'YYYY-MM-DD',
                )}
              </div>
              <PrimaryButton
                rounded={true}
                primaryColor={true}
                onClick={() => {
                  this.setCustomerCashback();
                  this.props.history.replace(`/cashback-history`);
                }}
              >
                <div>Okay</div>
              </PrimaryButton>
            </div>
          </ModalComponent>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.Auth,
    settingsApi: state.Settings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    authAction: bindActionCreators(authAction, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CashbackLandingPage);
