import TYPES from '../actions/actionTypes';

const initialState = {
  eInvoice: {
    status: undefined,
    invoiceUrl: undefined,
  },
  error: {
    message: undefined,
  },
  merchant: {
    currency: undefined,
    taxLabel: undefined,
  },
  order: {
    subtotal: undefined,
    discount: undefined,
    serviceCharge: undefined,
    serviceChargeRate: undefined,
    rounding: undefined,
    grandTotal: undefined,
    taxes: {
      salesTaxRate: undefined,
      salesTaxAmount: undefined,
    },
  },
  isFetching: true,
  errorFetching: undefined,
  isSubmitting: false,
  errorSubmitting: undefined,
  isSubmitted: false,
};

export default function EInvoice(state = initialState, action) {
  switch (action.type) {
    case TYPES.GET_E_INVOICE:
      return { ...state, isFetching: true, errorFetching: undefined };
    case TYPES.GET_E_INVOICE_IN_BACKGROUND:
      return { ...state, isFetching: false, errorFetching: undefined };
    case TYPES.GET_E_INVOICE_SUCCESS:
      return { ...state, ...action.data, isFetching: false };
    case TYPES.GET_E_INVOICE_FAILED:
      return { ...state, isFetching: false, errorFetching: action.data };
    case TYPES.SUBMIT_E_INVOICE:
      return { ...state, isSubmitting: true, errorSubmitting: undefined };
    case TYPES.SUBMIT_E_INVOICE_SUCCESS:
      return { ...state, isSubmitting: false, isSubmitted: true };
    case TYPES.SUBMIT_E_INVOICE_FAILED:
      return { ...state, isSubmitting: false, errorSubmitting: action.data };
    default:
      return state;
  }
}
