import TYPES from './actionTypes';

export function addToCart(data) {
  return dispatch => {
    dispatch({
      type: TYPES.ADD_TO_CART,
      data,
    });
    updateSession(dispatch);
  };
}

function updateSession(dispatch) {
  dispatch({
    type: TYPES.UPDATE_SESSION_STORAGE,
  });
}

export function changeQuantity(data, merchantId) {
  return dispatch => {
    dispatch({
      type: TYPES.CHANGE_QUANTITY,
      data,
      merchantId,
    });

    updateSession(dispatch);
  };
}

export function checkboxOnClick(data, merchantId) {
  return dispatch => {
    dispatch({
      type: TYPES.CHECK_BOX_CLICK,
      data,
      merchantId,
    });
    updateSession(dispatch);
  };
}

export function clearAll(merchantId) {
  return dispatch => {
    dispatch({ type: TYPES.CLEAR_ALL, merchantId });
    updateSession(dispatch);
  };
}

export function restoreCart(data) {
  return {
    type: TYPES.RESTORE_CART,
    data,
  };
}

export function saveCheckOutCount(checkOutCount = 0) {
  return {
    type: TYPES.SAVE_CHECKOUT_COUNT,
    data: checkOutCount,
  };
}

export function resetCheckOutCount() {
  return {
    type: TYPES.RESET_CHECKOUT_COUNT,
  };
}
