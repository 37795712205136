import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import './appBar.scss';

class AppBar extends PureComponent {
  render() {
    const { goBack } = this.props;
    return (
      <div className={'app-bar-component'}>
        <div className='app-bar-left'>
          <div className='back-button'>
            <FontAwesomeIcon
              icon={'arrow-left'}
              onClick={goBack}
              className={'text-left'}
            />
          </div>
        </div>

        <div className='app-bar-center'>{this.props.children}</div>

        <div className='app-bar-right' />
      </div>
    );
  }
}

AppBar.propTypes = {
  goBack: PropTypes.func,
};

export default AppBar;
