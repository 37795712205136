import TYPES from './actionTypes';
import ApiService from '../services/api';

export function addToCart(data, merchantId) {
  return dispatch => {
    dispatch({
      type: TYPES.ADD_TO_CART,
      data,
      merchantId,
    });
    dispatch({
      type: TYPES.UPDATE_SESSION_STORAGE,
    });
  };
}

export function updateCart(uuid, data, merchantId) {
  return dispatch => {
    dispatch({
      type: TYPES.UPDATE_CART,
      uuid,
      data,
      merchantId,
    });
    dispatch({
      type: TYPES.UPDATE_SESSION_STORAGE,
    });
  };
}

///Use for package dinner
export function filterMenu(menu, orderItemList: Array) {
  return dispatch => {
    menu.categories = menu.categories
      .map(category => {
        category.list = category.list.filter(item => {
          let dependency: String = item.dependencyMenuId;

          if (dependency) {
            let result = orderItemList.find(order => {
              return dependency.includes(order[1].id);
            });

            return result != null;
          }

          return true;
        });
        return category;
      })
      .filter(category => category.list.length !== 0);

    dispatch({
      type: TYPES.FILTER_MENU,
      data: menu,
    });
  };
}

export function getMenu(id, dinningMethod) {
  return async dispatch => {
    let parameter = '';

    if (dinningMethod) {
      parameter = `?diningMethod=${dinningMethod}`;
    }

    const resp = await ApiService.get(`api/customer/menu/${id}${parameter}`);

    if (resp.code === '500') {
      dispatch({ type: TYPES.GET_MENU_FAIL, data: { ...resp } });
      return;
    }

    const menuList = {};

    // group by categories
    resp.menu.forEach(m => {
      m.subCategories.forEach(subCat => {
        const subCatName = subCat.name;
        if (!menuList[subCatName]) {
          menuList[subCatName] = [];
        }

        menuList[subCatName].push(m);
      });
    });

    dispatch({
      type: TYPES.GET_MENU_SUCCESS,
      data: {
        ...resp,
        categories: resp.categories
          .map(category => {
            return {
              ...category,
              timeAllows: category.timeAllows.sort((current, prev) => {
                if (current.dayOfWeek > prev.dayOfWeek) {
                  return 1;
                } else if (current.dayOfWeek < prev.dayOfWeek) {
                  return -1;
                }

                return 0;
              }),
              list: category.subCategories
                .filter(s => menuList[s.name])
                .flatMap(s => menuList[s.name]),
            };
          })
          .filter(category => category.list && !!category.list.length),
        addOnCategories: resp.addOnCategories
          ? resp.addOnCategories
              .map((category, index) => ({ ...category, index }))
              .reduce((result, category) => {
                result[category.id] = category;
                return result;
              }, {})
          : [],
      },
    });
  };
}

export function getBrainTreeToken(merchantId) {
  return async dispatch => {
    const resp = await ApiService.get(
      `/api/customer/braintree/clientToken/${merchantId}`,
      true,
    );
    console.log(`resp`, resp);
    dispatch({
      type: TYPES.GET_BRAIN_TREE_TOKEN_SUCCESS,
      data: resp.clientToken,
    });

    return Promise.resolve(resp.clientToken);
  };
}

export function saveScrollPosition(id, scrollPosition) {
  return dispatch => {
    dispatch({
      type: TYPES.SAVE_SCROLL_POSITION,
      data: {
        id,
        value: scrollPosition,
      },
    });
  };
}

export function toggleIsShowPromotion() {
  return {
    type: TYPES.TOGGLE_IS_SHOW_PROMOTION,
  };
}
