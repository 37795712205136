import React, { Component } from 'react';
import ModalComponent from '../modal/modal';
import './promotionDialog.scss';
import {connect} from "react-redux";
import {HistoryPage} from "../../page/history/history";
import api from "../../services/api";

class PromotionDialog extends Component {

  getValueByKeyFromSettings = (key:String) => {
    let value = ''
    if (this.props.settings.object) {
      const list = this.props.settings.object.settings
      for (let index in list) {
        let obj = list[index]
        if (obj['key'].replace(`${api.getWLCode()}.`, '') === key) {
          value = obj['value'];
          break;
        }
      }
    }
    return value;
  }

  render() {
    return (
      <ModalComponent
        openWhen={this.props.isShowDialog && !!this.getValueByKeyFromSettings('inviteFriendDescription') && !!this.getValueByKeyFromSettings('inviteFriendDescription')}
        className={'promotion'}>
        <div className='promotion-dialog'>
          <div
            className='promotion-picture'
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/image/refer_n_earn_3.png)`,
            }}></div>

          <div className='promotion-message'>
            <div className='promotion-title'>{this.getValueByKeyFromSettings('inviteFriendTitle')}</div>
            <div className='promotion-content'>
              {this.getValueByKeyFromSettings('inviteFriendDescription')}
            </div>
          </div>
          <div className='promotion-button'>
            <div className='btn btn-cancel' onClick={this.props.onCancelClick}>
              Not Now
            </div>
            <div className='btn btn-submit' onClick={this.props.onSubmitClick}>
              Proceed
            </div>
          </div>
        </div>
      </ModalComponent>
    );
  }
}

function mapStateToProps(state) {
  return {
    settings: state.Settings
  };
}

export default connect(
    mapStateToProps,
)(PromotionDialog);
