import TYPES from "./actionTypes";

export function saveMerchantList(merchantList, headerImg) {
    return dispatch => {
        dispatch({
            type: TYPES.SAVE_MERCHANT_LIST,
            data: {merchantList, headerImg}
        })
    }
}

export function saveOutletScrollPosition(merchantId) {
    return dispatch => {
        dispatch({
            type: TYPES.SAVE_OUTLET_SCROLL_POSITION,
            data: merchantId
        })
    }
}

export function saveLastSearchText(text) {
    return dispatch => {
        dispatch({
            type: TYPES.SAVE_LAST_SEARCH_TEXT,
            data: text
        })
    }
}