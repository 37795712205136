import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import './modal.scss';

export default class ModalComponent extends Component {
  renderPopUp = () => {
    const { closeIconColor, children, close, openWhen, staticHeight, className, styleHeight, styleBorderRadius, backgroundColor, stylePadding, styleWidth } = this.props;
    return (
      <div className='modal-overlay'>
        <div
            style={{height: `${styleHeight?styleHeight:null}`, padding: `${stylePadding}`, width:`${styleWidth}`,backgroundColor:`${backgroundColor? backgroundColor : null }`, borderRadius: `${styleBorderRadius?styleBorderRadius:null}`}}
          className={`modal-body ${openWhen && 'smooth-touch'} ${staticHeight &&
            'modal-body-static-height'} ${className}`}
        >
          {close && <FontAwesomeIcon icon='times' style={{color:`${closeIconColor}`, borderColor:`${closeIconColor}` }} onClick={close} />}
          {children}
        </div>
      </div>
    );
  };

  render() {
    const { openWhen, transitionClass } = this.props;

    return (
      <div>
        <div className={`modal ${openWhen && 'modal-expand'}`} />
        <CSSTransitionGroup
          transitionName={transitionClass || 'popup'}
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}
        >
          {openWhen && this.renderPopUp()}
        </CSSTransitionGroup>
      </div>
    );
  }
}

ModalComponent.propTypes = {
  close: PropTypes.func,
  openWhen: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  staticHeight: PropTypes.bool,
  className: PropTypes.string,
  transitionClass: PropTypes.string,
    styleHeight: PropTypes.string,
    stylePadding: PropTypes.string,
    styleWidth: PropTypes.string,
    styleBorderRadius: PropTypes.string,
    backgroundColor: PropTypes.string,
    closeIconColor: PropTypes.string,
};
