import PropTypes from 'prop-types';
import React, { Component } from 'react';
import './primaryButton.scss';

class PrimaryButton extends Component {
  render() {
    const rounded = this.props.rounded ? 'rounded' : '';
    const smBtn = this.props.smBtn ? 'sm-btn' : '';
    const bgBtn = this.props.bgBtn ? 'bg-btn' : '';
    const primaryColor = this.props.primaryColor ? 'primary-color' : '';
    const { className, onClick } = this.props;
    const addClass = [className, smBtn, rounded, primaryColor, bgBtn]
      .join(' ')
      .trim();

    return (
      <div className={`button-component ${addClass}`} onClick={onClick}>
        {this.props.children}
      </div>
    );
  }
}

PrimaryButton.propTypes = {
  rounded: PropTypes.bool,
  smBtn: PropTypes.bool,
  bgBtn: PropTypes.bool,
  primaryColor: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

PrimaryButton.defaultProps = {
  className: '',
};

export default PrimaryButton;
