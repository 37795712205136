import BaseResp from './baseResp';

export default class GetAddressResp extends BaseResp {
  addresses = [];

  constructor({ apiKey, message, code, addresses = [] }) {
    super({ apiKey, message, code });
    this.addresses = addresses;
  }
}
