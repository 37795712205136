import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as queryString from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ModalComponent from '../../component/modal/modal';
import ApiService from '../../services/api';
import { getDateFromNow, path, parsePrice } from '../../services/utils';
import './history.scss';
import HistoryDetailPopup from './historyDetailPopup';
import api from '../../services/api';
import { store } from '../../store/store';

export class HistoryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedHistory: '',
      receiptEmail: '',
      loading: false,
      showSuccessAfterEmailSent: false,
    };
  }

  componentDidMount() {
    this.isShowHistory(this.props);
    this.timerId = setInterval(() => {
      window.location.reload();
    }, 60 * 1000);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.isShowHistory(nextProps);
  }

  componentWillUnmount() {
    if (this.timerId) clearInterval(this.timerId);
  }

  isShowHistory(props) {
    if (!this.state.selectedHistory) {
      const query = queryString.parse(props.location.search);

      const isShowLastHistory = !!query.showLastResult;

      if (
        isShowLastHistory &&
        !!props.user &&
        !!props.user.orders &&
        !!props.user.orders[0]
      ) {
        this.toggleSelectedOrderHistory(props.user.orders[0])();
      }
    }
  }

  confirmEmail = () => {
    const { receiptEmail, selectedHistory } = this.state;
    this.setState(
      {
        loading: true,
        selectedHistory: '',
        receiptEmail: '',
      },
      async () => {
        await ApiService.post('api/customer/customerInvoice', {
          email: receiptEmail,
          transId: selectedHistory.transId,
        });

        this.setState({
          loading: false,
          showSuccessAfterEmailSent: true,
        });
      },
    );
  };

  emailOnChange = event => {
    this.setState({
      receiptEmail: event.target.value,
    });
  };

  toggleSelectedOrderHistory = (order = '') => () => {
    console.log(`toggleSelectedOrderHistory`);
    this.setState({
      selectedHistory: order,
    });
  };

  toggleRequestReceipt = () => {
    this.setState({
      selectedHistory: {
        ...this.state.selectedHistory,
        requestReceipt: !this.state.selectedHistory.requestReceipt,
      },
      receiptEmail: '',
    });
  };

  closeSuccessModal = () => {
    this.setState({
      showSuccessAfterEmailSent: false,
    });
  };

  renderUserDetails = () => {
    const { me } = this.props.user;

    return (
      <div className='user-container'>
        <div className='top title'>ME</div>
        <FontAwesomeIcon icon='envelope' />
        <div className='user-detail'>
          <div className='user-detail-row'>
            <div>
              <img className='profile-pic' src={me.image} alt='profile' />
            </div>
            <div className='detail'>
              <div className='name'>{me.name}</div>
              <div className='point'>{me.point} pts</div>
            </div>
          </div>
        </div>
        <div className='id-row'>Member ID: {me.id}</div>
      </div>
    );
  };

  renderOrderHistory = () => {
    const { orders } = this.props.user;
    const { menu } = this.props;

    if (!menu.menuList) return;

    return (
      <div className='order-history'>
        {/*<div className="title">History</div>*/}
        {orders && orders.length ? (
          <div className='order-history-list'>
            {orders.map(order => (
              <div
                className={
                  'order-row ' +
                  ((order.paymentStatus === 'PENDING' ||
                    order.paymentStatus === 'FAILED') &&
                    `order-${order.paymentStatus.toLowerCase()}`)
                }
                key={order.transId}
                onClick={this.toggleSelectedOrderHistory(order)}
              >
                <div>{path('merchant.name', order)}</div>
                <div className='price'>
                  <span>
                    {order.byPoint
                      ? `${(order.totalPaymentWithTax * 100).toFixed(0)} pts`
                      : `${order.merchant.currency} ${parsePrice(
                          order.totalPaymentWithTax,
                        )}`}
                  </span>
                  {(order.paymentStatus === 'PENDING' ||
                    order.paymentStatus === 'FAILED') && (
                    <span className={order.paymentStatus.toLowerCase()}>
                      (Payment {order.paymentStatus.toLowerCase()})
                    </span>
                  )}
                </div>
                <div className='font-weight-light'>
                  {getDateFromNow(order.dateCreated, 30, 'YYYY-MM-DD')}
                </div>
                <FontAwesomeIcon icon='chevron-right' />
              </div>
            ))}
          </div>
        ) : (
          <div className='no-result'>No order history</div>
        )}
      </div>
    );
  };

  renderHistoryDetail = settingsObj => {
    if (!this.props.menu.menuList) return;

    let contact = '';
    if (settingsObj) {
      for (let index in settingsObj.settings) {
        let obj = settingsObj.settings[index];
        if (obj['key'].replace(`${api.getWLCode()}.`, '') === 'cs.contact') {
          contact = obj['value'];
          break;
        }
      }
    }

    return (
      <ModalComponent
        close={this.toggleSelectedOrderHistory()}
        openWhen={!!this.state.selectedHistory}
      >
        {!!this.state.selectedHistory && (
          <HistoryDetailPopup
            alimentsContact={contact}
            selectedHistory={this.state.selectedHistory}
            toggleRequestReceipt={this.toggleRequestReceipt}
          />
        )}
      </ModalComponent>
    );
  };

  renderSuccessAfterEmailSent = () => {
    const { showSuccessAfterEmailSent, loading } = this.state;

    return (
      <ModalComponent openWhen={showSuccessAfterEmailSent || loading}>
        {loading ? (
          <div className='email-loading'>
            <FontAwesomeIcon icon='spinner' pulse />
          </div>
        ) : (
          <React.Fragment>
            <div className='content-container'>
              <div className='title'>HUBBO POS</div>
              <div>
                Your invoice is ready and has been sent to your email. Thank
                you.
              </div>
            </div>
            <div className='confirmation-button-container'>
              <div className='confirm-button' onClick={this.closeSuccessModal}>
                OK
              </div>
            </div>
          </React.Fragment>
        )}
      </ModalComponent>
    );
  };

  renderEmailContainer = () => {
    const { selectedHistory, receiptEmail } = this.state;
    const isEmail = () => {
      return /\S+@\S+\.\S+/.test(receiptEmail);
    };

    return (
      <ModalComponent
        close={this.toggleRequestReceipt}
        openWhen={!!selectedHistory && !!selectedHistory.requestReceipt}
      >
        <div className='email-container'>
          <div className='title'>HUBBO POS</div>
          <input
            type='email'
            placeholder='your@email.com'
            value={receiptEmail}
            onChange={this.emailOnChange}
          />
        </div>
        <div className='confirmation-button-container'>
          <div className='cancel-button' onClick={this.toggleRequestReceipt}>
            Cancel
          </div>
          <div
            className={'confirm-button ' + (!isEmail() && 'disabled')}
            onClick={this.confirmEmail}
          >
            Confirm
          </div>
        </div>
      </ModalComponent>
    );
  };

  render() {
    const { selectedHistory } = this.state;
    const { settings } = this.props;

    return (
      <div
        className={
          'history-page ' +
          (selectedHistory ? 'disable-scroll' : 'smooth-touch')
        }
      >
        {this.props.user ? (
          <React.Fragment>
            {/*{this.renderUserDetails()}*/}
            {this.renderOrderHistory()}
          </React.Fragment>
        ) : (
          <div className={'no-result'}>Loading...</div>
        )}
        <div className='modal-order-container'>
          {this.renderHistoryDetail(settings.object)}
        </div>
        <div className='modal-email-container'>
          {this.renderEmailContainer()}
        </div>
        <div className='modal-success-email-sent'>
          {this.renderSuccessAfterEmailSent()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.Auth.user,
    settings: state.Settings,
    menu: state.Menu,
  };
}

export default connect(mapStateToProps)(HistoryPage);
