import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { debounce } from '../../services/utils';
import TextInput from '../addNewAddress/textInput';
import './addressSelectionPage.scss';
import ModalComponent from "../../component/modal/modal";
import Button from "../../component/button/button";
import {Loading} from "../../component/loading/loading";

class AddressSelectionPage extends Component {
  map = undefined;
  geocoder = undefined;
  autocomplete = undefined;
  infoWindow = undefined;
  timer = undefined;

  state = {
    suggestionList: [],
    isLoading: true,
    result: undefined,
    lock: false,
    address: '',
    firstAddress: this.props.history.location.state? this.props.history.location.state.noAddress : false
  };

  componentDidMount() {
    const { google } = window;

    const center = { lat: parseFloat(process.env.REACT_APP_LATCENTER), lng: parseFloat(process.env.REACT_APP_LNGCENTER)};

    // Init
    const mapElement = document.getElementById('maps');
    const inputValue = document.getElementById('autocomplete');

    this.infoWindow = new google.maps.InfoWindow();

    this.geocoder = new google.maps.Geocoder();
    this.map = new google.maps.Map(mapElement, {
      center: center,
      zoom: 16,
      disableDefaultUI: true,
    });

    inputValue.addEventListener('keyup',  (event) =>{
      // Number 13 is the "Enter" key on the keyboard
      if (event.code === 'Enter' || event.key === 'Enter' || event.keyCode === 13 || event.which === 13) {
        // Cancel the default action, if needed
        event.preventDefault();
        // Trigger the button element with a click
        if(!this.state.address){
          return;
        }

        this.getSuggestion(this.state.address);
      }
    })

    this.onCenterChanged();

    // Center change
    this.map.addListener(
      'center_changed',
      debounce(this.onCenterChanged, 1000),
    );

    window.addEventListener('click', () => {
      this.setState({
        suggestionList: []
      })
    });
    // Try HTML5 geolocation.
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          this.map.setCenter(pos);
          this.setState({
            isLoading: false
          })
        },
        () => {
          this.setState({
            isLoading: false
          })
          this.handleLocationError(true, this.infoWindow, this.map.getCenter());
        },
      );
    } else {
      this.setState({
        isLoading: false
      })
      // Browser doesn't support Geolocation
      this.handleLocationError(false, this.infoWindow, this.map.getCenter());
    }
  }

  render() {
    const { address, firstAddress, isLoading, suggestionList} = this.state;
    return (
      <div className='h-full w-full address-selection-page'>
        {isLoading && <Loading/>}
        <div id='maps' className='min-h-full min-w-full' />
        <div className='autocomplete-input flex flex-row bg-white items-center p-1 rounded-md deep-shadow'>
          <FontAwesomeIcon icon={'search'} className='mx-2' />
          <TextInput
            id={'autocomplete'}
            className='h-full'
            onChange={this.onTextChange}
            disabled={isLoading}
            value={address}
            onFocus={() => {
              this.setState({ address: '', suggestionList: [] });
            }}
          />
          <ul style={{display: `${suggestionList.length !== 0 ? 'block' : 'none'}`}} className='custom-pac-container'>
            {
              suggestionList.map((element, key) =>
                  <li key={key} onClick={() => this.onSelect(element.place_id)}>
                    <img src="./image/Vector.png"/>
                    {element.description}
                  </li>
              )
            }
          </ul>
        </div>

        <div style={{display: `${suggestionList.length !== 0 ? 'none' : 'block'}`}} className='map-marker-container'>
          <FontAwesomeIcon
            className='map-marker text-red-500 text-3xl lg:text-base'
            icon={'map-marker-alt'}
          />
        </div>
        <this.addNewButton
          className='continue-btn'
          onClick={() => {
            if(isLoading){
              return ;
            }else if(!address){
              this.setState({
                firstAddress: true
              })
              return;
            }else if(!this.state.result){
              alert('Please select a suggested location before continue.');
              return;
            }

            const result = this.processResult(this.state.result);

            this.props.history.push('/addressForm', {
              resp: { ...result },
            });
          }}
        />
        <ModalComponent styleHeight="auto" styleBorderRadius="10px" openWhen={firstAddress}>
          <div className="flex flex-col justify-between p-5">
            <div className="center text-4xl font-bold pb-18">
              Dear customer, please select a location before proceed to order.
            </div>
            <Button
                text={'Ok'}
                className='rounded-full uppercase'
                style={{
                  minWidth: '160px',
                  marginTop:'30px'
                }}
                onClick={()=>this.close(firstAddress)}
            />
          </div>
        </ModalComponent>
      </div>
    );
  }

  close(firstAddress){
    this.setState({
      firstAddress: !firstAddress
    })
  }

  addNewButton = ({ className, onClick }) => (
    <div
      className={`cursor-pointer add-new-btn rounded-full bg-primary p-4 m-4 font-bold text-center ${className}`}
      onClick={onClick}
    >
      <div>Continue</div>
    </div>
  );

  onCenterChanged = () => {
    if (this.state.lock) {
      this.setState({
        lock: false,
      });
      return;
    }

    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      const center = this.map.getCenter();
      this.geocoder.geocode({ location: center }, this.onCenterChangedCallback);
    }, 2000);
  };

  onCenterChangedCallback = (results, status) => {
    const [address] = results;
    if (status === window.google.maps.GeocoderStatus.OK) {
      this.setState({
        result: address,
        address: address.formatted_address,
      });
    }
  };

  getSuggestion = (result) => {
    const {google} = window;
    const displaySuggestions = (predictions, status) => {
      if (status !== google.maps.places.PlacesServiceStatus.OK || !predictions) {
        return;
      }

      this.setState({
        suggestionList: predictions
      })
    };

    const service = new google.maps.places.AutocompleteService();
    service.getPlacePredictions({
      input: result,
      componentRestrictions: {
        country: process.env.REACT_APP_COUNTRY,
      }
    }, displaySuggestions);
  };



  onTextChange = (event) => {
    clearTimeout(this.timer)
    this.setState({
      suggestionList: [],
      result: null,
      address: event.target.value,
    });

    if(event.target.value){
      let result = event.target.value;
      this.timer = setTimeout( () => {
        this.getSuggestion(result);
      }, 5000);
    }
  };

  onSelect = (placeId = '') => {
    if(!placeId){
      return;
    }
    const {google} = window;
    const callback = (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.setState(
            {
              suggestionList:[],
              lock: true,
              result: place,
              address: place.formatted_address,
            },
            () => {
              this.map.setZoom(19);
              this.map.setCenter(place.geometry.location);
            },
        );
      }
    }

    const request = {
      placeId: placeId,
      componentRestrictions:  {
        country: "my",
      },
      fields: ["formatted_address", "address_components", "geometry"]
    };

    const serviceGetPlace = new google.maps.places.PlacesService(this.map);
    serviceGetPlace.getDetails(request, callback);
  }

  handleLocationError = (browserHasGeolocation, infoWindow, pos) => {
    infoWindow.setPosition(pos);
    infoWindow.setContent(
      browserHasGeolocation
        ? 'Error: The Geolocation service failed.'
        : "Error: Your browser doesn't support geolocation.",
    );
    infoWindow.open(this.state.map);
  };

  processResult = ({
    formatted_address: address = '',
    address_components = [],
    geometry: { location = {} } = {},
  }) => {
    console.log(`address_components`, address_components);
    const findFrom = (arr = []) => (key = '') =>
      arr.find(obj => obj.types.some(type => type === key));

    const values = Object.values(address_components);
    const findFromResult = findFrom(values);

    const {long_name: postcode} = findFromResult('postal_code') ? findFromResult('postal_code') : {long_name: ''};
    const {long_name: country} = findFromResult('country');
    const {long_name: city} = findFromResult('locality') || findFromResult('sublocality') ?
        findFromResult('locality') || findFromResult('sublocality') : {long_name: ''};
    const { long_name: state } = findFromResult('administrative_area_level_1') ? findFromResult('administrative_area_level_1') : {long_name: ''};

    return {
      address,
      lat: location.lat(),
      lng: location.lng(),
      city,
      country,
      postcode,
      state,
    };
  };
}

AddressSelectionPage.propTypes = {};

export default AddressSelectionPage;
