import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from '../reducers/index';
import thunk from 'redux-thunk';
import * as queryString from 'query-string';
import * as R from 'ramda';
import { getStorage, setStorage } from '../services/utils';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export function configureStore(initialState) {
  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk))
  );
}

const initialRedux = {};

// When init, check URL is contain param
// Have param in url, mean user came from QR Code
if (
  !!window.location.search &&
  (window.location.pathname !== '/wechatVerify' &&
    window.location.pathname !== '/checkout' &&
    window.location.pathname !== '/dynamic/checkout')
) {
  // Get current url query
  const { code, state, ...urlQuery } = queryString.parse(
    window.location.search,
  );

  if (code && !getStorage('apiKey')) {
    setStorage('code', code, 1);
  }

  const cookieQuery = getStorage('query');
  const parsedQuery = cookieQuery ? JSON.parse(cookieQuery) : {};

  // When url query is not same with query in memory
  // Clear all data in cookieStorage
  // if (JSON.stringify(urlQuery) !== cookieStorage.getItem('query')) {
  if (urlQuery.merchantId !== parsedQuery.merchantId) {
    setStorage('query', '', 0);
  }
  // Save current query to cookieStorage
  setStorage('query', JSON.stringify(urlQuery));
} else {
  // Do not have param, user refresh the page
  // Retrieve data from cookieStorage
  const sessionQuery = getStorage('query');

  // Assign redux initialValue here.
  // Higher priority when assign initialValue when calling configureStore.
  // But individual reducer's initial value also need to set because they will be execute first.
  initialRedux.Global = {
    query: sessionQuery ? { ...JSON.parse(sessionQuery) } : undefined,
  };
}

// retrieve checkout items when init
const items = getStorage('items');
initialRedux.Checkout = {
  items: !R.isEmpty(items) ? { ...JSON.parse(items) } : {},
  checkOutCount: 0,
};

export const store = configureStore(initialRedux);
