import PropTypes from 'prop-types';
import React from 'react';

import './landingPageButton.scss';

const LandingPageButton = ({ title, src, onClick }) => {
  const normalClass = 'rounded-lg p-3 m-2 items-center';
  const lgClass = 'lg:rounded lg:p-2 lg:m-2';

  return (
    <div
      className={`landing-page-button flex flex-row ${normalClass} ${lgClass}`}
      onClick={onClick}
    >
      <img src={src} alt={title} />
      <div className={'ml-2 font-bold w-full'}>{title}</div>
    </div>
  );
};

LandingPageButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  src: PropTypes.string,
};

export default LandingPageButton;
