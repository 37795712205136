import ModalComponent from "../../../component/modal/modal";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import React, {useState} from "react";
import './preOrderTime.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {showPreOrderTime} from "../../../services/utils";
import PreOrderModal from "./preOrderModal";

// Apply for take away and delivery only.
// To use this function required object below for selectedTime,
// preOrderDate = {
//   isAsap: true,
//   start: new Date(),
//   end: new Date().setHours(new Date().getHours() + 1, 0)
//   isToday: true
// }
// selectedTime will be null when merchant has pauseTill
export default function ShowPreOrderTime({selectedSlot, merchant , onSelect, padding}) {
    const [isOpen, setIsOpen] = useState(false);
    return (<>
        <div className={`bg-white`} style={{
            borderBottom: '1px #f7f7f7 solid',
            display: 'flex',
            alignItems: 'center',
            padding: `${padding ? padding : '1.25rem'}`
        }}
             onClick={() => {
                 setIsOpen(true);
             }}>
            <FontAwesomeIcon icon={'clock'}
                             className={'primary-color pr-4'}/>{showPreOrderTime(selectedSlot, true)}
        </div>
        <PreOrderModal close={() => {
            setIsOpen(false);
        }} isOpen={isOpen} merchant={merchant} onSelect={(slot) => {
            onSelect(slot);
            setIsOpen(false);
        }} selectedSlot={selectedSlot}/>
    </>)
}