import queryString from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authAction from '../../actions/authAction';
import * as checkoutAction from '../../actions/checkoutAction';
import './thankyou.scss';

class ThankYou extends Component {
  componentDidMount() {
    this.props.authAction.getUserAccount();
    this.props.global.query.merchantId &&
      this.props.checkoutAction.clearAll(this.props.global.query.merchantId);
  }

  spinAndWinOnClick = () => {
    alert('Spin');
  };

  okOnClick = () => {
    const havePickupNo =
      !!this.props.merchant && !!this.props.merchant.selfOrderPickupNoStart;
    this.props.history.replace(
      `/menu?${queryString.stringify(this.props.global.query)}${
        havePickupNo ? '&showLastResult=1' : ''
      }`
    );
  };

  render() {
    const showSpin = false;
    return (
      <div className={'thankyou-page'}>
        {/*<div>Thanks, <span className="name">{this.props.name || "REX ZI YONG".toUpperCase()}</span>!</div>*/}
        <div>Thank you!</div>
        <div>
          Thanks for your purchase from{' '}
          {this.props.merchant && this.props.merchant.name}. Your order will be
          ready shortly!
        </div>
        {!!showSpin && <div>Join Spin & Win now and get awesome reward!</div>}

        {showSpin ? (
          <div className='btn' onClick={this.spinAndWinOnClick}>
            SPIN & WIN
          </div>
        ) : (
          <div className='btn' onClick={this.okOnClick}>
            OK
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    // menu: state.Menu,
    // checkout: state.Checkout,
    merchant: state.Menu.menuList && state.Menu.menuList.merchant,
    global: state.Global,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    checkoutAction: bindActionCreators(checkoutAction, dispatch),
    authAction: bindActionCreators(authAction, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThankYou);
