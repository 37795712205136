import React, { Component } from 'react';
import { parsePrice } from '../../services/utils';
import CircleButton from '../circleButton/circleButton';
import PriceBox from '../priceBox/priceBox';
import QuantityBox from '../quantityBox/quantityBox';
import './orderItem.scss';
import { connect } from 'react-redux';

class OrderItem extends Component {
  handleQuantityChange = quantity => {
    return () => {
      this.props.quantityOnClick({
        id: this.props.id,
        name: this.props.name,
        addon: this.props.addon,
        remark: this.props.remark,
        price: this.props.price,
        quantity,
      });
    };
  };

  checkboxOnClick = () => {
    this.props.checkboxOnClick({
      id: this.props.id,
      addon: this.props.addon,
      remark: this.props.remark,
      price: this.props.price,
      deselected: this.props.deselected,
    });
  };

  render() {
    if (!this.props.name) return null;
    const { isDisplayMode, merchant } = this.props;

    const addOn = (props => {
      if (props.addon) {
        return props.addon;
      } else if (!props.addon && props.selectedAddOns) {
        return props.selectedAddOns.map(addOn => addOn.name).join(', ');
      }
    })(this.props);

    const addOnPrice = (props => {
      if (props.addonPrice) {
        return props.addonPrice;
      } else if (props.selectedAddOns && props.selectedAddOns.length) {
        return props.selectedAddOns.reduce((pre, cur) => pre + cur.price, 0);
      }
    })(this.props);

    return (
      <div className={'order-item-container'}>
        {!isDisplayMode && (
          <CircleButton
            className={`circle ${!this.props.deselected && 'primary-bg'}`}
            icon={'check'}
            iconStyle={{ color: '#FFFFFF' }}
            // onClick={this.checkboxOnClick}
          />
        )}
        <div className='item-container' onClick={this.props.handleOnClicked}>
          <div className='item-name-container'>
            <span className={'item-name'}>{this.props.name}</span>
            {!!isDisplayMode && (
              <span className='item-name'>
                &nbsp;{'x' + this.props.quantity}
              </span>
            )}
            {/* NOTE:: TEMP HIDE THIS FILE, WILL IMPLEMENT LATER
                        {this.props.badge && <div className={'item-badge'}>{this.props.badge}</div>}*/}
          </div>
          {!!isDisplayMode &&
            this.props.ticketUpdateTime &&
            new Date(this.props.ticketUpdateTime).toLocaleTimeString('en-US')}
          {addOn && <div className={'item-desc'}>{addOn}</div>}
          {this.props.remark && (
            <div className={'item-desc'}>{this.props.remark}</div>
          )}
        </div>
        <div className='price-container'>
          <PriceBox
            units={merchant.currency}
            price={
              isDisplayMode
                ? this.props.price * this.props.quantity
                : this.props.price
            }
          />
          {!!addOnPrice && (
            <div className={'add-on-price'}>{`incl. ${
              merchant.currency
            } ${parsePrice(addOnPrice * this.props.quantity)} add-on`}</div>
          )}
        </div>
        {!isDisplayMode && (
          <QuantityBox
            quantity={this.props.quantity}
            onClick={this.handleQuantityChange}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    merchant: state.Menu.menuList.merchant,
  };
}

export default connect(mapStateToProps)(OrderItem);
