import { parsePrice } from '../../services/utils';
import React, { PureComponent } from 'react';
import './priceBox.scss';

class PriceBox extends PureComponent {
  render() {
    return (
      <div className='price-box-container'>
        {!!this.props.showNegative && <div>-&nbsp;</div>}
        {!!this.props.originalPrice && <div className='line-through price-unit text-gray-800'>{this.props.units}&nbsp;</div>}
        {!!this.props.originalPrice && <div className='line-through price text-gray-800'>{parsePrice(this.props.originalPrice)}</div>}
        &nbsp;
        <div className='price-unit'>{this.props.units}&nbsp;</div>
        <div className='price'>{parsePrice(this.props.price)}</div>
      </div>
    );
  }
}

export default PriceBox;
