import * as R from 'ramda';
import { CONSTANTS } from './constants';
import api from "./api";

function isEqual(string) {
  return R.compose(
    R.equals(R.toLower(string)),
    R.toLower
  );
}

export const isEqualWeChatPay = R.or(
  isEqual(CONSTANTS.PARTNER.WECHAT_PAY),
  isEqual('WECHATPAY')
);

export const isEqualBoost = isEqual(CONSTANTS.PARTNER.BOOST);
export const isEqualLinks = isEqual(CONSTANTS.PARTNER.LINKS);
export const isEqualIpay88GrabPay = isEqual(CONSTANTS.PARTNER.IPAY88_GRABPAY);
export const isEqualIpay88Tng = isEqual(CONSTANTS.PARTNER.IPAY88_TNG);
export const isEqualTouchAndGo = isEqual(CONSTANTS.PARTNER.TOUCH_AND_GO);
export const isEqualFinexus = isEqual(CONSTANTS.PARTNER.FINEXUS);
export const isEqualTwoc2p_GrabPay = isEqual(CONSTANTS.PARTNER.TWOC2P_GRABPAY);
export const isEqualTwoC2p_FPX = isEqual(CONSTANTS.PARTNER.TWOC2P_FPX);
export const isEqualTwoc2p_Tng = isEqual(CONSTANTS.PARTNER.TWOC2P_TNG);
export const isEqualTwoc2p_CC = isEqual(CONSTANTS.PARTNER.TWOC2P_CC);
export const isEqualTwoc2p_ShopeePay = isEqual(CONSTANTS.PARTNER.TWOC2P_SHOPEEPAY);
export const isEqualTwoc2p_Alipay = isEqual(CONSTANTS.PARTNER.TWOC2P_ALIPAY);
export const isEqualTwoc2p_Wechat = isEqual(CONSTANTS.PARTNER.TWOC2P_WECHAT);
export const isEqualIpay88ShopeePay = isEqual(CONSTANTS.PARTNER.IPAY88_SHOPEEPAY);

export const getGlobalPartnerOrEmpty = R.pathOr('', [
  'global',
  'query',
  'partner',
]);

export function isValidPhoneNumber(phoneNumber = '') {
  if (phoneNumber.startsWith('+601') || phoneNumber.startsWith('601')) {
    /* malaysia */
    if (phoneNumber.startsWith('+6011') || phoneNumber.startsWith('+6015') || phoneNumber.startsWith('6011') || phoneNumber.startsWith('6015')) {
      return phoneNumber.length >= 12 && phoneNumber.length < 15;
    }

    return phoneNumber.length >= 11 && phoneNumber.length <= 12;
  } else if (phoneNumber.startsWith('+65') || phoneNumber.startsWith('65')) {
    /* singapore */
    return phoneNumber.length > 8;
  } else {
    return phoneNumber.length > 8;
  }
}

export function getValueFromKeyAtSetting(key: '', settingObj: {}){
  let link = '';
  if(settingObj.object){
    for (let index in settingObj.object.settings) {
      const setting = settingObj.object.settings[index];
      if (setting['key'].replace(`${api.getWLCode()}.`,'') === key) {
        link = setting['value'];
        break;
      }
    }
  }
  return link;
}

