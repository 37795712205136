import React from 'react';
import { bindActionCreators } from 'redux';
import * as eInvoiceAction from '../../../actions/eInvoiceAction';
import { connect } from 'react-redux';
import { toTwoDecimal } from '../../../services/utils';

function OrderDetails(props) {
  const { einvoice: state } = props;
  const { merchant, order } = state;

  const getTax = () => {
    return order.taxes.length ? order.taxes[0] : {};
  };

  return (
    <div className='flex flex-col pb-3'>
      <div className='flex flex-row'>
        <div className='flex-grow-1'>Subtotal :</div>
        <div className='flex-grow text-right'>
          {merchant.currency} {toTwoDecimal(order.subtotal)}
        </div>
      </div>
      <div className='flex flex-row'>
        <div className='flex-grow-1'>Discount :</div>
        <div className='flex-grow text-right'>
          {merchant.currency} {toTwoDecimal(order.discount)}
        </div>
      </div>
      <div className='flex flex-row'>
        <div className='flex-grow-1'>
          Service Charge @ {toTwoDecimal(order.serviceChargeRate)}% :
        </div>
        <div className='flex-grow text-right'>
          {merchant.currency} {toTwoDecimal(order.serviceCharge)}
        </div>
      </div>
      <div className='flex flex-row'>
        <div className='flex-grow-1'>
          {merchant.taxLabel} @ {toTwoDecimal(getTax().salesTaxRate)}% :
        </div>
        <div className='flex-grow text-right'>
          {merchant.currency} {toTwoDecimal(getTax().salesTaxAmount)}
        </div>
      </div>
      <div className='flex flex-row'>
        <div className='flex-grow-1'>Rounding :</div>
        <div className='flex-grow text-right'>
          {merchant.currency} {toTwoDecimal(order.rounding)}
        </div>
      </div>
      <div className='flex flex-row border-0 border-b-2 border-black border-solid border-opacity-25 pb-3'>
        <div className='flex-grow-1 font-bold'>Grand Total :</div>
        <div className='flex-grow text-right font-bold'>
          {merchant.currency} {toTwoDecimal(order.grandTotal)}
        </div>
      </div>
      <div className='flex flex-row pt-3'>
        <div className='flex-grow-1 font-bold'>HUBBO POS APP :</div>
        <div className='flex-grow text-right font-bold'>
          {merchant.currency} {toTwoDecimal(order.grandTotal)}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  einvoice: state.EInvoice,
});

export default connect(mapStateToProps)(OrderDetails);
