import React, { Component } from 'react';
import * as queryString from 'query-string';
import ApiService from '../../services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class BoostVerify extends Component {
  state = { submitted: true };
  redirectTo = this.props.history.push;

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    const { refNo } = query;

    if (refNo) {
      ApiService.get(`api/customer/boost/status/${refNo}`).then(res => {
        if (res.code === '200') this.redirectTo('/thankyou');
      });
    }
  }

  render() {
    return (
      <div>
        <div className={'loading'} onTouchMove={e => e.preventDefault()}>
          <FontAwesomeIcon icon={'circle-notch'} spin />
        </div>
      </div>
    );
  }
}

export default BoostVerify;
