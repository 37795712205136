import TYPES from '../actions/actionTypes';

const initialState = {
    object: undefined,
    scrollPosition: undefined,
    searchText: undefined
};

export default function Outlet(state = initialState, action) {
    switch (action.type) {
        case TYPES.SAVE_MERCHANT_LIST:
            return { ...state, object: action.data}
        case TYPES.SAVE_OUTLET_SCROLL_POSITION:
            return {...state, scrollPosition: action.data}
        case TYPES.SAVE_LAST_SEARCH_TEXT:
            return {...state, searchText: action.data}
        default:
            return state;
    }
}
