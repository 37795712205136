import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getDayFromNumber } from '../../services/utils';
import ModalComponent from '../modal/modal';
import './operatingHours.scss';

export default class OperatingHoursComponent extends Component {
  render() {
    const { openWhen, selectedMerchant, toggle } = this.props;

    return (
      <div className='operating-hour-modal'>
        <ModalComponent openWhen={openWhen} close={toggle}>
          {selectedMerchant && (
            <div className='body text-black'>
              <div className='name font-weight-bold'>
                {selectedMerchant.name}
              </div>
              <div className='subtitle'>This is our operating hour:</div>
              <table className='m-auto'>
                <tbody>
                {[1, 2, 3, 4, 5, 6, 0].map(
                  this.makeOperatingHourItem(selectedMerchant),
                )}
                </tbody>
              </table>
            </div>
          )}
          <div className='btn-confirm' onClick={toggle}>
            OK
          </div>
        </ModalComponent>
      </div>
    );
  }

  makeOperatingHourItem = selectedMerchant => day => {
    let operatingHour = selectedMerchant.operatingHours.filter(
      hour => hour.dayOfWeek === day,
    );

    const dateFromNumber = getDayFromNumber(day).toUpperCase();

    return (
      <tr key={day}>
        <td>{dateFromNumber}</td>
        <td>:</td>
        <td>
          {operatingHour && operatingHour.length
            ? operatingHour.map(this.mapOperatingHourToTime)
            : 'Closed'}
        </td>
      </tr>
    );
  };

  mapOperatingHourToTime = ({ dayOfWeek, timeEnd, timeStart }) => {
    const formatTime = time => moment(time, 'HH:mm').format('h:mm A');

    const startTime = formatTime(timeStart);
    const endTime = formatTime(timeEnd);
    return (
      <div key={`${dayOfWeek}${timeStart}`}>
        {startTime} ~ {endTime}
      </div>
    );
  };
}

OperatingHoursComponent.propTypes = {
  openWhen: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  selectedMerchant: PropTypes.object,
  toggle: PropTypes.func,
};
