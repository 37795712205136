import TYPES from '../actions/actionTypes';

const initialState = {
  user: {}
};

export default function Global(state = initialState, action) {
  switch (action.type) {
    case TYPES.GET_USER_ACCOUNT:
      return {
        ...initialState,
        user: action.data,
      };
    case TYPES.TOGGLE_LOGIN_DIALOG:
      return {
        ...initialState,
        showLoginDialog: action.data,
      };
    default:
      return state;
  }
}
