import React, { Component } from 'react';
import './about.scss';
import ModalComponent from '../../component/modal/modal';
import { connect } from 'react-redux';
import api from '../../services/api';

class AboutPage extends Component {
  constructor() {
    super();
    this.state = {
      showContactUsModal: false,
      contact: {
        reason: 'Inquiry',
        phone: '',
        message: '',
      },
    };
  }

  toggleContactUs = () => {
    this.setState({
      showContactUsModal: !this.state.showContactUsModal,
    });
  };

  contactOnChange = type => event => {
    this.setState({
      contact: { ...this.state.contact, [type]: event.target.value },
    });
  };

  submit = () => {
    const { contact } = this.state;
    const body = `${contact.message}\n\n----------------------------------\nUser id:${this.props.user.me.id}\nFrom web\n`;
    window.location.href = `mailto:${this.getValueFromKeyAtSetting(
      'contactEmail',
      this.props.settingsApi,
    )}?subject=${contact.reason}&body=${encodeURIComponent(body).trim()}`;

    this.setState({
      contact: {
        reason: 'Inquiry',
        phone: '',
        message: '',
      },
    });
  };

  renderContactUsModal = () => {
    const { showContactUsModal, contact } = this.state;

    return (
      <ModalComponent
        openWhen={showContactUsModal}
        close={this.toggleContactUs}
      >
        <div className='container'>
          <div className='title'>Contact Us</div>
          <div>
            <div>I WANT TO</div>
            <div>
              <select
                value={contact.reason}
                onChange={this.contactOnChange('reason')}
                className='form-control'
              >
                <option value='Inquiry'>Inquiry</option>
                <option value='Feedback'>Feedback</option>
                <option value='Refund'>Refund</option>
                <option value='Complain'>Complain</option>
              </select>
            </div>
          </div>
          <div>
            <div>YOUR CONTACT</div>
            <div>
              <input
                type='tel'
                className='form-control'
                value={contact.phone}
                placeholder='eg. 0123456789'
                maxLength='13'
                onChange={this.contactOnChange('phone')}
              />
            </div>
          </div>
          <div>
            <div>YOUR MESSAGE *</div>
            <div>
              <textarea
                className='form-control'
                value={contact.message}
                placeholder='Eg. Feedback App or Restaurant'
                rows='3'
                onChange={this.contactOnChange('message')}
              />
            </div>
          </div>
        </div>
        <div
          className={
            'confirmation-button-container ' + (!contact.message && 'disabled')
          }
        >
          <div className='confirm-button' onClick={this.submit}>
            SEND
          </div>
        </div>
      </ModalComponent>
    );
  };

  getValueFromKeyAtSetting(key: '', settingObj: {}) {
    let link = '';
    if (settingObj.object) {
      for (let index in settingObj.object.settings) {
        const setting = settingObj.object.settings[index];
        if (setting['key'].replace(`${api.getWLCode()}.`, '') === key) {
          link = setting['value'];
          break;
        }
      }
    }
    return link;
  }

  render() {
    const { settingsApi } = this.props;
    return (
      <div className='about-page'>
        <div className='mb'>
          <div className='title'>
            <img
              src={`${process.env.PUBLIC_URL}/image/logo_tagline.png`}
              alt='aliment title'
            />
          </div>
          {/*<div className="version">v1.10.1</div>*/}
        </div>
        <div className='contain container'>
          {/*<div>Aliments [noun: al i ment], the mobile food ordering platform brought to you by a team of food*/}
          {/*    lovers.*/}
          {/*</div>*/}
          <div>Why wait to be served? Why queue at the counter?</div>
          <div>
            Sit back & relax with HUBBO POS. Ordering and payment is just few
            clicks away.
          </div>
        </div>
        <div className='action container'>
          <div className='action-row border'>
            <div className='btn' onClick={this.toggleContactUs}>
              CONTACT US
            </div>
            <a
              className='middle'
              href={this.getValueFromKeyAtSetting('faq', settingsApi)}
              target='_blank'
              rel='noopener noreferrer'
            >
              <div className='btn'>FAQ</div>
            </a>
            <a
              href={this.getValueFromKeyAtSetting('website', settingsApi)}
              target='_blank'
              rel='noopener noreferrer'
            >
              <div className='btn'>BUSINESS OWNER?</div>
            </a>
          </div>
          <div className='action-row'>
            <a
              href={this.getValueFromKeyAtSetting('termNPrivacy', settingsApi)}
              target='_blank'
              rel='noopener noreferrer'
            >
              <div className='btn btn-first'>TERMS & PRIVACY POLICY</div>
            </a>
            <a
              href={this.getValueFromKeyAtSetting('refundPolicy', settingsApi)}
              target='_blank'
              rel='noopener noreferrer'
            >
              <div className='btn btn-last'>REFUND POLICY</div>
            </a>
          </div>
        </div>
        <div className='contact-us-modal'>{this.renderContactUsModal()}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.Auth.user,
    settingsApi: state.Settings,
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutPage);
