import React, {useState} from "react";
import PropTypes from "prop-types";
import ModalComponent from "../modal/modal";
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";

function EnlargeButton(props) {
    const [isShow, setShow] = useState(false)

    return (
        <React.Fragment>
            <ModalComponent
                stylePadding='unset'
                backgroundColor='black'
                closeIconColor='white'
                className={'flex flex-col-reverse justify-center items-center'}
                close={
                    () => {
                        setShow(false)
                    }
                }
                openWhen={
                    isShow
                }>

                <TransformWrapper>
                    <TransformComponent>
                        <img className={'modal-body'} style={{minHeight:'500px', objectFit:'contain', backgroundColor:'black'}} src={props.imageUrl}
                             alt="enlarge-image"/>
                    </TransformComponent>
                </TransformWrapper>
            </ModalComponent>
            <div onClick={
                () => {
                    setShow(true)
                }
            } style={{
                position: 'absolute',
                zIndex: '100',
                right: 8,
                bottom: 8,
                borderRadius: '20px',
                padding: '8px',
                backgroundColor: "rgba(255,255,255,0.5)",
                height: "24px",
                width: "24px"
            }}>
                <img height="24px" width="24px"
                     src='https://cdn-icons-png.flaticon.com/512/545/545651.png'
                     alt="enlarge-icon"/>
            </div>
        </React.Fragment>
    )
}

EnlargeButton.propsType = {
    imageUrl: PropTypes.string.isRequired
}

export default EnlargeButton;