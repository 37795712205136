import React, { PureComponent } from 'react';
import './imageLoader.scss';

class ImageLoader extends PureComponent {
  render() {
    return (
      <div className='lds-facebook'>
        <div />
        <div />
        <div />
      </div>
    );
  }
}

export default ImageLoader;
