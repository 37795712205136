import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CircleButton extends PureComponent {
  static propTypes = {
    iconClassName: PropTypes.string,
    iconStyle: PropTypes.object,
    icon: PropTypes.string,
  };

  render() {
    const { iconClassName, icon, iconStyle, ...rest } = this.props;
    return (
      <div className={this.props.className} {...rest}>
        <FontAwesomeIcon
          className={iconClassName}
          icon={icon}
          style={iconStyle}
        />
      </div>
    );
  }
}

export default CircleButton;
