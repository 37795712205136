import PropTypes, {func} from 'prop-types';
import React, {useState} from "react";
import CircleButton from "../circleButton/circleButton";
import TextInput from "../../page/addNewAddress/textInput";
import {getStorage, setStorage} from "../../services/utils";

const SelectPickUpDialog = ({...props}) =>{
    const pickUpObj = {
        image: '/image/ic_pickup.png',
        imageHeight: '50px',
        headerText: 'Counter Pickup',
        bodyText: 'Pick up your orders at the store counter.',
        id: 'PickUp',
        isPickUp: true,
    }
    const driveThruObj = {
        image: '/image/ic_drive_thru.png',
        imageHeight: '40px',
        headerText: 'Drive Thru',
        bodyText: 'Restaurant Staff will send the orders to your car.',
        id: 'DriveThru',
        isPickUp: false,
    }

    const pickUpList = [
        pickUpObj, driveThruObj
    ]
    const[isPickUp, setPickUp] = useState(true);
    const[isDriveThru, setDriveThru] = useState(false);
    const[contact, setPhoneNumber] = useState(getStorage('phoneNumber'));
    const[carPlateNumber, setCarPlateNumber] = useState(getStorage('carPlateNumber'));
    const[description, setDescription] = useState(getStorage('carDescription'));
    //TOP
    const setOuterBorder = 'm-5 flex flex-col content-between text-black';
    const setPickUpContainer = 'mt-5';
    const setPickUpText = 'text-4xl font-bold';

    //CENTER
    const setPickUpAndDriveThruContainer = 'mt-10 flex flex-col';
    const setEachOfContainerOfPickUpType = 'flex items-center space-between-row';
    const setPickUpTypeImageContainer = 'flex bg-white items-center rounded-lg justify-center';
    const setPickUpTypeText = 'flex flex-col mr-2 ml-5 justify-start flex-1';

    function clickDriveThru(){
        setDriveThru(true);
        setPickUp(false);
    }

    function clickPickUp(){
        setDriveThru(false);
        setPickUp(true);
    }

    function populateCircleButton(forPickUp){
        return(
            <CircleButton
                className={`circle ${(forPickUp ? isPickUp : isDriveThru) && 'primary-bg'}`}
                icon={'check'}
                iconStyle={{ color: '#FFFFFF' }}
                onClick={() => forPickUp ? clickPickUp() : clickDriveThru()}
            />
        )
    }

    function populateThePickUpList(obj){
        return(
            <div key={obj.id} className={`${obj.isPickUp ? setEachOfContainerOfPickUpType : setEachOfContainerOfPickUpType + ' mt-2'}`} onClick={() => obj.isPickUp ? clickPickUp() : clickDriveThru()}>
                <div className={setPickUpTypeImageContainer} style={{minHeight:'70px', minWidth:'70px'}}>
                    <img src={obj.image} alt={obj.id} height={obj.imageHeight} />
                </div>
                <div className={setPickUpTypeText}>
                    <div className='font-bold'>
                        {obj.headerText}
                    </div>
                    <div className='text-sm' style={{minWidth:'115px', maxWidth:'240px'}}>
                        {obj.bodyText}
                    </div>
                </div>
                <div className='font-bold'>
                    {populateCircleButton(obj.isPickUp)}
                </div>
            </div>
        )
    }

    function populateTextFieldInput(){
        return(
            <React.Fragment>
                <div>
                    <div className={`font-bold ${isPickUp || isDriveThru ? '' :  'text-gray-200'}`}>Contact No</div>
                    <TextInput
                        type='text'
                        placeholder={'Eg. 01123456789'}
                        onChange={({target: {value}}) => {
                            value = value.replace('+', '')
                            if (!isNaN(value)) {
                                if (value.startsWith('01')) {
                                    setPhoneNumber(`+6${value}`)
                                } else {
                                    setPhoneNumber(`+${value}`)
                                }
                            }
                        }}
                        value={contact}
                        required
                    />
                </div>
                <div className={`${isPickUp ? 'hidden' : ''}`}>
                    <div className='font-bold mt-3'>Car Plate No</div>
                    <TextInput
                        type='text'
                        placeholder={'WAA 123'}
                        onChange={({target:{value}})=>{
                            setCarPlateNumber(value)
                        }}
                        value={carPlateNumber}
                    />
                </div>
                <div className={`${isPickUp ? 'hidden' : ''}`}>
                    <div className='font-bold mt-3'>Describe your car</div>
                    <TextInput
                        type='text'
                        placeholder={'Myvi, Red Color'}
                        onChange={({target:{value}})=>{
                            setDescription(value)
                        }}
                        value={description}
                    />
                </div>
            </React.Fragment>
        )
    }

    function validation(){
        let pickUpObj = {
            name:'',
            merchantId: props.merchantId,
            carPlateNumber:'',
        }
        if(isPickUp){
            if(!contact){
                alert('Please provide your contact no.')
                return
            }else{
                pickUpObj.name = 'self-pick-up'
            }
        }else if (isDriveThru){
            if(!contact || !carPlateNumber){
                alert('Please provide your contact no and car plate no.')
                return
            }else{
                setStorage('carPlateNumber', carPlateNumber)
                setStorage('carDescription', description)
                pickUpObj.name = 'drive-thru'
                pickUpObj.carPlateNumber = carPlateNumber
            }
        }

        setStorage('phoneNumber', contact)
        props.returnPickUpType(pickUpObj)
    }

    return(
        <div className={setOuterBorder}>
            <div className={setPickUpContainer}>
                <div className={setPickUpText}>Pickup</div>
                <div>Please select your pickup method</div>
            </div>
            <div className={setPickUpAndDriveThruContainer}>
                {
                    pickUpList.map((obj,index) =>
                        {
                           return(populateThePickUpList(obj))
                        }
                    )
                }
            </div>
            <div className='mt-10'>
                {populateTextFieldInput()}
                <button onClick={() => validation()}
                        className='p-2 mt-10 m-auto w-full bg-primary text-base text-white font-bold block border-none rounded-full uppercase'>
                    Next
                </button>
            </div>
        </div>
    )
}

SelectPickUpDialog.prototype = {
    merchantId: PropTypes.string,
    returnPickUpType: PropTypes.func
}

export default SelectPickUpDialog;