export default class DeliveryAddress {
  id;
  customerId;
  recipientName;
  contact;
  deliveryNoteToDriver;
  unitNumber;
  address;
  postcode;
  city;
  state;
  country;
  lat;
  lng;
  enabled;
  created_at;
  updated_at;

  constructor({
    id,
    customerId,
    recipientName,
    contact,
    deliveryNoteToDriver,
    unitNumber,
    address,
    postcode,
    city,
    state,
    country,
    lat,
    lng,
    enabled,
    created_at,
    updated_at,
  }) {
    this.id = id;
    this.customerId = customerId;
    this.recipientName = recipientName;
    this.contact = contact;
    this.deliveryNoteToDriver = deliveryNoteToDriver;
    this.unitNumber = unitNumber;
    this.address = address;
    this.postcode = postcode;
    this.city = city;
    this.state = state;
    this.country = country;
    this.lat = lat;
    this.lng = lng;
    this.enabled = enabled;
    this.created_at = created_at;
    this.updated_at = updated_at;
  }
}
