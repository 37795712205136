import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Button from '../button/button';
import LoginTextInput from './loginTextInput';

function OtpDialog(props) {
  const [otpCode, setOtpCode] = useState('');
  const [counter, setCounter] = useState(60);
  const [enabledResent, setEnabledResent] = useState(false);

  useEffect(() => {
    let timerId;
    if (counter > 0)
      timerId = setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
    else if (counter === 0) {
        setEnabledResent(true);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [counter]);

  const onOtpChange = event => {
    const value = event.target.value.trim();
    if (!isNaN(value)) {
      setOtpCode(value);
    }
  };
  return (
    <div className='login-dialog'>
      <div className='text-aliments text-4xl font-bold pt-24 pb-8'>
        Confirm OTP
      </div>
      <p className='pb-6 pl-6 pr-6'>
        We've sent you a verification SMS to your phone ({props.phoneNumber})
        with a 6-digit code
      </p>

      <div className='text-aliments text-lg'>
        0:{counter.toString().padStart(2, '0')}
      </div>
      <LoginTextInput
        className='my-4'
        placeholder='6-digit OTP'
        maxLength={6}
        value={otpCode}
        onChange={onOtpChange}
      />

      <Button
        text={'Login'}
        className='rounded-full uppercase'
        style={{
          minWidth: '160px',
        }}
        onClick={() => props.onOtpSubmit(otpCode)}
      />

      <div className='pb-16'/>

        { enabledResent && <p className='text-sm mx-4 text-gray-500'>
            Did not receive SMS?{' '}
            <span
                className='text-gray-500 cursor-pointer underline'
                onClick={() => {
                    setEnabledResent(false);
                    props.onResentOtpClick()
                }}
            >
          RESENT OTP
        </span>
        </p>}
    </div>
  );
}

OtpDialog.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  onOtpSubmit: PropTypes.func.isRequired,
  onResentOtpClick: PropTypes.func.isRequired,
};

export default OtpDialog;
