import React, { Component } from 'react';
import './nearbyMerchant.scss';
import ApiService from '../../services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalComponent from '../../component/modal/modal';
import { Loading } from '../../component/loading/loading';
import moment from 'moment';
import { isClosingNow, isWechat } from '../../services/utils';
import OperatingHoursComponent from '../../component/operatingHours/operatingHours';
import * as sha from 'js-sha1';
import * as queryString from 'query-string';

const debugMode = !!process.env.REACT_APP_DEBUG;
export default class NearbyMerchantPage extends Component {
  constructor() {
    super();
    this.state = {
      nearbyMerchants: [],
      selectedMerchant: null,
      showMoreOperatingHour: false,
      partner: '',
    };

    this.stateless = {
      timer: '',
    };
  }

  componentWillUnmount() {
    this.stateless.timer && window.clearTimeout(this.stateless.timer);
  }

  componentDidMount() {
    const parsedQuery = queryString.parse(this.props.location.search);
    if (parsedQuery.partner) {
      this.setState({ partner: parsedQuery.partner });
    }

    this.getNearybyMerchant();
  }

  getDistanceFromTwoLocation = (lat1, lon1, lat2, lon2) => {
    const deg2rad = deg => {
      return deg * (Math.PI / 180);
    };

    // Radius of the earth in km
    const radius = 6371000;

    // deg2rad below
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return (radius * c).toFixed(0);
  };

  setGeoLocation = (selfLat, selfLng) => {
    this.setState({
      nearbyMerchants: this.state.nearbyMerchants.map(merchant => {
        const [lat, lng] = merchant.coordinate.split(',');
        const distance = this.getDistanceFromTwoLocation(
          lat,
          lng,
          selfLat,
          selfLng,
        );
        const distanceWithUnit =
          distance / 1000 > 1
            ? `${(Number(distance) / 1000).toFixed(1)} km`
            : `${distance}m`;
        return {
          ...merchant,
          distance,
          distanceWithUnit,
        };
      }),
      loading: false,
    });
  };

  getGeoLocation = async () => {
    try {
      const location = await new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            position => {
              resolve(position);
            },
            error => {
              reject(error);
            },
          );
        } else {
          reject('no support');
        }
      });

      this.setGeoLocation(location.coords.latitude, location.coords.longitude);
    } catch (err) {
      this.setState({ loading: false });
    }
  };

  getNearybyMerchant = async isTimeUp => {
    let nextClosestMerchantClosingTime = '';
    let { merchants } = await (isTimeUp
      ? Promise.resolve({ merchants: this.state.nearbyMerchants })
      : ApiService.get('/api/merchant'));
    const nearbyMerchants = [
      ...merchants.map(merchant => {
        const isClosing = isClosingNow(merchant.operatingHours); // true for coming soon, object for next operating hour
        if (isClosing) {
          // has next operating hour
          if (isClosing.timeStart) {
            const currentClosingStartTime = Number(isClosing.timeStart);

            if (
              !nextClosestMerchantClosingTime ||
              (currentClosingStartTime <
                nextClosestMerchantClosingTime.timeStart &&
                ((isClosing.isFromToday &&
                  nextClosestMerchantClosingTime.isFromToday) ||
                  (!isClosing.isFromToday &&
                    !nextClosestMerchantClosingTime.isFromToday)))
            ) {
              nextClosestMerchantClosingTime = {
                ...isClosing,
                timeStart: currentClosingStartTime,
              };
            }
          }
        }

        return { ...merchant, isClosingNow: isClosing };
      }),
    ];

    if (nextClosestMerchantClosingTime) {
      const diff = moment(nextClosestMerchantClosingTime.timeStart, 'HHm').diff(
        moment(),
        'seconds',
      );
      if (diff && diff > 0) {
        this.stateless.timer = setTimeout(() => {
          this.getNearybyMerchant(true);
        }, (diff + 1) * 1000);
      }
    }

    this.setState(
      {
        nearbyMerchants,
        loading: true,
      },
      () => {
        isWechat() ? this.initWeChatSDK() : this.getGeoLocation();
      },
    );
  };

  initWeChatSDK = async () => {
    const date = new Date();
    const timestamp = Math.floor(date.getTime() / 1000);

    const nonceStr = Math.random()
      .toString(36)
      .substr(2, 15);
    const getAccessToken = async () => {
      const { ticket } = await ApiService.get('/api/customer/wechat/ticket');
      const string = `jsapi_ticket=${ticket}&noncestr=${nonceStr}&timestamp=${timestamp}&url=${
        window.location.href.split('#')[0]
      }`;
      return Promise.resolve(sha(string));
    };

    const token = await getAccessToken();

    window.wx.config({
      debug: debugMode,
      appId: 'wxa9d48c542aa1e260',
      timestamp,
      nonceStr,
      signature: token,
      jsApiList: ['getLocation'],
    });

    window.wx.ready(() => {
      window.wx.getLocation({
        success: position => {
          this.setGeoLocation(position.latitude, position.longitude);
        },
      });
    });

    window.wx.error(() => {
      this.setState({ loading: false });
    });
  };

  toggleSelectedMerchant = merchant => () => {
    this.setState({
      selectedMerchant: merchant,
    });
  };

  toggleSelctedMerchantOperatingHour = () => {
    this.setState({
      showMoreOperatingHour: !this.state.showMoreOperatingHour,
    });
  };

  renderCategory = merchant => {
    return (
      <React.Fragment>
        {[1, 2, 3, 4, 5].map((rate, index) => (
          <span
            key={`dollar-${rate}`}
            className={' ' + (index < merchant.priceRate && 'dollar-highlight')}
          >
            <FontAwesomeIcon icon='dollar-sign' />
          </span>
        ))}
      </React.Fragment>
    );
  };

  goToMerchant = isTakeAway => () => {
    const { selectedMerchant } = this.state;
    if (selectedMerchant.isClosingNow && selectedMerchant.isClosingNow.desc) {
      this.toggleSelctedMerchantOperatingHour();
      return;
    }

    const takeAwayParams = isTakeAway ? '&takeAway=1' : '';
    const partnerParams = this.state.partner
      ? `&partner=${this.state.partner}`
      : '';

    this.props.history.push(
      `/menu?merchantId=${selectedMerchant.id}${takeAwayParams}${partnerParams}`,
    );
  };

  renderOperatingHour = () => {
    const { selectedMerchant } = this.state;
    let currentOperatingHourStatus = '';
    let nextClosingOrOpeningHour = '';
    let isComingSoon = false;

    if (selectedMerchant.isClosingNow) {
      currentOperatingHourStatus = 'Closed';
      if (selectedMerchant.isClosingNow.desc) {
        nextClosingOrOpeningHour = `Open at ${selectedMerchant.isClosingNow.desc}`;
      } else {
        nextClosingOrOpeningHour = 'Coming Soon';
        isComingSoon = true;
      }
    } else {
      const closingTime = selectedMerchant.operatingHours.find(
        time => time.dayOfWeek === new Date().getDay(),
      )['timeEnd'];
      currentOperatingHourStatus = 'Open';
      nextClosingOrOpeningHour = `Closes ${moment(
        closingTime,
        'HH:mm A',
      ).format('h:mm A')}`;
    }

    const onOperatingHourClicked = () => {
      if (isComingSoon) {
        return;
      }

      this.toggleSelctedMerchantOperatingHour();
    };

    return (
      <div className='operating-hour-row' onClick={onOperatingHourClicked}>
        {!isComingSoon && (
          <span className='font-weight-bold'>{currentOperatingHourStatus}</span>
        )}
        {!isComingSoon && <FontAwesomeIcon icon='circle' />}
        <span>{nextClosingOrOpeningHour}</span>
      </div>
    );
  };

  renderClosingNowText = merchant => {
    return (
      <div className='closing-container'>
        {merchant.isClosingNow.desc ? (
          <React.Fragment>
            <div>Open at</div>
            <div>{merchant.isClosingNow.desc}</div>
          </React.Fragment>
        ) : (
          <div>Coming Soon</div>
        )}
      </div>
    );
  };

  renderNearByMerchants = nearbyMerchants => {
    return (
      <React.Fragment>
        {nearbyMerchants
          .filter(
            merchant =>
              process.env.REACT_APP_DEBUG ||
              (!process.env.REACT_APP_DEBUG && !merchant.devOnly),
          )
          .sort((a, b) => {
            return a.distance ? a.distance - b.distance : 0;
          })
          .map(merchant => {
            return (
              <div
                key={merchant.id}
                onClick={this.toggleSelectedMerchant(merchant)}
              >
                <div
                  className='merchant-image'
                  style={{ backgroundImage: `url(${merchant.featuredImg})` }}
                >
                  {merchant.isClosingNow && this.renderClosingNowText(merchant)}
                </div>
                <div className='merchant-row'>
                  <div className='merchant-title'>
                    <div>{merchant.name}</div>
                    {merchant.distance && (
                      <div>{merchant.distanceWithUnit}</div>
                    )}
                  </div>
                  <div className='font-sm'>{merchant.addr}</div>
                  <div>
                    {merchant.categories && (
                      <span className='merchant-category'>
                        {merchant.categories.toUpperCase()}
                      </span>
                    )}
                    {this.renderCategory(merchant)}
                  </div>
                  <FontAwesomeIcon icon='info' />
                </div>
              </div>
            );
          })}
      </React.Fragment>
    );
  };

  render() {
    window.a = this;
    const {
      selectedMerchant,
      nearbyMerchants,
      loading,
      showMoreOperatingHour,
    } = this.state;
    let isOpeningNearbyMerchants = [];
    let isClosingNearbyMerchants = [];

    nearbyMerchants.forEach(merchant => {
      if (merchant.isClosingNow) {
        isClosingNearbyMerchants.push(merchant);
      } else {
        isOpeningNearbyMerchants.push(merchant);
      }
    });

    return (
      <div
        className={
          'nearby-merchant-page ' + (!selectedMerchant && 'smooth-touch')
        }
      >
        {loading ? (
          <Loading />
        ) : (
          <React.Fragment>
            <div className='title'>Merchant Nearby</div>
            <div className='merchant-details'>
              {this.renderNearByMerchants(isOpeningNearbyMerchants)}
              {this.renderNearByMerchants(isClosingNearbyMerchants)}
            </div>
            <ModalComponent
              openWhen={selectedMerchant}
              close={this.toggleSelectedMerchant()}
            >
              {selectedMerchant && (
                <div className='coffee-modal'>
                  <div>
                    <div
                      className='cover-image-container'
                      style={{
                        backgroundImage: `url(${selectedMerchant.featuredImg})`,
                      }}
                    >
                      {selectedMerchant.isClosingNow &&
                        this.renderClosingNowText(selectedMerchant)}
                      <div className='detail'>
                        <div className='merchant-title'>
                          {selectedMerchant.name}
                        </div>
                        <div className='merchant-detail-flex'>
                          <div className='merchant-detail-flex'>
                            <div>{selectedMerchant.addr}</div>
                            <FontAwesomeIcon icon='circle' />
                            {selectedMerchant.categories && (
                              <span className='merchant-category'>
                                {selectedMerchant.categories.toUpperCase()}
                              </span>
                            )}
                            {this.renderCategory(selectedMerchant)}
                          </div>
                          {selectedMerchant.distance && (
                            <div className='distance-text'>
                              {selectedMerchant.distanceWithUnit}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='detail-container'>
                      <div className='detail-flex'>
                        {selectedMerchant.contact && (
                          <a
                            href={`tel:${selectedMerchant.contact}`}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            <div>
                              <div className='square'>
                                <FontAwesomeIcon icon='phone' />
                              </div>
                              <span className='sm-text'>CALL</span>
                            </div>
                          </a>
                        )}
                        {selectedMerchant.coordinate && (
                          <a
                            href={`https://www.google.com/maps/dir/?api=1&destination=${selectedMerchant.coordinate}`}
                            rel='noopener noreferrer'
                            target='_blank'
                          >
                            <div>
                              <div className='square'>
                                <FontAwesomeIcon icon='map-marker-alt' />
                              </div>
                              <span className='sm-text'>DIRECTIONS</span>
                            </div>
                          </a>
                        )}
                        {selectedMerchant.facebook && (
                          <a
                            href={selectedMerchant.facebook}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            <div>
                              <div className='square'>
                                <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                              </div>
                              <span className='sm-text'>FACEBOOK</span>
                            </div>
                          </a>
                        )}
                        {selectedMerchant.website && (
                          <a
                            href={selectedMerchant.website}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            <div>
                              <div className='square'>
                                <FontAwesomeIcon icon='globe-asia' />
                              </div>
                              <span className='sm-text'>WEBSITE</span>
                            </div>
                          </a>
                        )}
                      </div>
                      {selectedMerchant.fullAddr && (
                        <a
                          href={`https://www.google.com/maps/dir/?api=1&destination=${selectedMerchant.coordinate}`}
                          rel='noopener noreferrer'
                          target='_blank'
                        >
                          <div className='detail-row'>
                            <FontAwesomeIcon icon='map-marker-alt' />
                            <div>{selectedMerchant.fullAddr}</div>
                          </div>
                        </a>
                      )}
                      <div className='detail-row'>
                        <FontAwesomeIcon icon='clock' />
                        {this.renderOperatingHour()}
                      </div>
                      {(selectedMerchant.contact || selectedMerchant.email) && (
                        <div className='detail-row'>
                          <FontAwesomeIcon icon='phone' />
                          <div>
                            <div>{selectedMerchant.contact}</div>
                            {selectedMerchant.email && (
                              <a
                                href={`mailto:${selectedMerchant.email}`}
                                rel='noopener noreferrer'
                              >
                                <div>{selectedMerchant.email}</div>
                              </a>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={
                      'button-container ' +
                      (selectedMerchant.isClosingNow &&
                        !selectedMerchant.isClosingNow.desc &&
                        'btn-disabled')
                    }
                  >
                    {/*<div className="btn btn-submit" onClick={this.goToMerchant()}>DINE IN</div>*/}
                    <div
                      className='btn btn-submit'
                      onClick={this.goToMerchant(true)}
                    >
                      ORDER TAKE AWAY
                    </div>
                  </div>
                </div>
              )}
            </ModalComponent>
            <OperatingHoursComponent
              openWhen={showMoreOperatingHour}
              toggle={this.toggleSelctedMerchantOperatingHour}
              selectedMerchant={selectedMerchant}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}
