import AddAddressResp from '../model/response/addAddressResp';
import BaseResp from '../model/response/baseResp';
import GetAddressResp from '../model/response/getAddressResp';
import { GetQuotationResp } from '../model/response/getQuotationResp';
import ApiService from '../services/api';
import TYPES from './actionTypes';

export function postAddress(data) {
  return async dispatch => {
    dispatch({
      type: TYPES.ADD_ADDRESS,
    });

    return ApiService.post('api/customer/delivery/address', data)
      .then(value => {
        const resp = new AddAddressResp(value);

        dispatch({
          type: resp.isSuccess()
            ? TYPES.ADD_ADDRESS_SUCCESS
            : TYPES.ADD_ADDRESS_FAIL,
          data: resp.message,
        });

        return Promise.resolve(resp);
      })
      .catch(reason =>
        dispatch({
          type: TYPES.ADD_ADDRESS_FAIL,
          data: reason,
        }),
      );
  };
}

export function getAddress() {
  return async dispatch => {
    dispatch({ type: TYPES.GET_ADDRESS });

    return ApiService.get('api/customer/delivery/address', true).then(
      result => {
        const resp = new GetAddressResp(result);

        if (resp.isSuccess()) {
          dispatch({
            type: TYPES.GET_ADDRESS_SUCCESS,
            data: resp.addresses,
          });
        } else {
          dispatch({
            type: TYPES.GET_ADDRESS_FAIL,
            data: resp.message,
          });
        }

        return Promise.resolve(resp);
      },
    );
  };
}

export function deleteAddress(id) {
  return async dispatch => {
    ApiService.delete(`api/customer/delivery/address/${id}`).then(value => {
      console.log(`deleteAddress ${id}`, value);
      const resp = new BaseResp(value);

      if (resp.isSuccess()) {
        getAddress()(dispatch);
      }
      dispatch({
        type: resp.isSuccess()
          ? TYPES.DELETE_ADDRESS_SUCCESS
          : TYPES.DELETE_ADDRESS_FAIL,
      });
    });

    dispatch({ type: TYPES.DELETE_ADDRESS });
  };
}

export function selectAddress(address) {
  return {
    type: TYPES.SELECT_ADDRESS,
    data: address,
  };
}

export function getQuotation(merchantId, addressId, totalPrice, subTotal, quotationId) {
  return async dispatch => {
    dispatch({
      type: TYPES.GET_QUOTATION,
    });

    return ApiService.get(
      `/api/customer/delivery/quotation/${merchantId}/${addressId}?totalPrice=${totalPrice}&subTotal=${subTotal}${quotationId?`&quotationId=${quotationId}`:""}`,
      true,
    ).then(value => {
      const resp = new GetQuotationResp(value);

      if (resp.isSuccess())
        dispatch({
          type: TYPES.GET_QUOTATION_SUCCESS,
          data: { ...resp.deliveryQuotation },
        });
      else
        dispatch({
          type: TYPES.GET_QUOTATION_FAIL,
          data: { ...resp.message },
        });

      return Promise.resolve(resp);
    });
  };
}

export function selectFirstAddress() {
  return { type: TYPES.SELECT_FIRST_ADDRESS };
}

export function removeSelectedAddress() {
  return { type: TYPES.REMOVE_SELECTED_ADDRESS };
}
