import TYPES from '../actions/actionTypes';
import ua from 'universal-analytics';
import ReactPixel from 'react-facebook-pixel';
import { getStorage, setStorage } from '../services/utils';
import uuid from 'uuid';

const initialState = {
  isShowPromotionDialog: true,
};

export class GA {
  static visitor;

  static sendPageView(title = '') {
    if (this.visitor) {
      GA.visitor.pageview({ dp: window.location.pathname, dt: title }).send();
    }
  }
}

export class FBPixel {
  static isSet = false;

  static sendPageView(title = '') {
    if (this.isSet) {
      ReactPixel.track(title);
    }
  }
}

export default function Menu(state = initialState, action) {
  switch (action.type) {
    case TYPES.GET_MENU_SUCCESS:
    case TYPES.GET_MENU_FAIL:
      let deviceId = getStorage('uuid');

      if (!deviceId) {
        deviceId = uuid.v4();
        setStorage('uuid', deviceId);
      }
      // console.log(deviceId)
      if (
        action.data &&
        action.data.merchant &&
        action.data.merchant.googleAnalytic
      ) {
        // console.log(action.data.merchant.googleAnalytic)
        if (!GA.visitor) {
          // console.log(GA.visitor);
          GA.visitor = ua(action.data.merchant.googleAnalytic, deviceId, {
            http: true,
          });
        }
      }

      if (
        action.data &&
        action.data.merchant &&
        action.data.merchant.facebookPixel
      ) {
        // console.log(action.data.merchant.facebookPixel)
        if (!FBPixel.isSet) {
          ReactPixel.init(
            action.data.merchant.facebookPixel,
            {},
            {
              debug: true,
              autoConfig: true,
            },
          );
          FBPixel.isSet = true;
        }
      }
      return { ...state, menuList: action.data };
    case TYPES.FILTER_MENU:
      return { ...state, menuList: action.data };
    case TYPES.GET_BRAIN_TREE_TOKEN_SUCCESS:
      return { ...state, brainTreeToken: action.data };
    case TYPES.SAVE_SCROLL_POSITION:
      return { ...state, scrollPosition: action.data };
    case TYPES.TOGGLE_IS_SHOW_PROMOTION:
      return { ...state, isShowPromotionDialog: false };
    default:
      return state;
  }
}
