export default class BaseResp {
  apiKey;
  message;
  code;

  constructor({ apiKey, message, code }) {
    this.apiKey = apiKey;
    this.message = message;
    this.code = code;
  }

  isSuccess = () => this.code === '200';
}
