import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Base64 } from 'js-base64';
import * as queryString from 'query-string';
import * as R from 'ramda';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as checkoutAction from '../../actions/checkoutAction';
import * as globalAction from '../../actions/globalAction';
import * as menuAction from '../../actions/menuAction';
import { Loading } from '../../component/loading/loading';
import PromotionDialog from '../../component/promotionDialog/promotionDialog';
import login, {
  FIREBASE_REDIRECT_RESULT,
  firebaseGetRedirectResult,
} from '../../services/auth';
import {
  getStorage,
  isClosingNow,
  isWechat,
  openUrl,
  parsePrice,
  setImageUrlDimension,
} from '../../services/utils';
import './menu.scss';
import * as authAction from '../../actions/authAction';
import * as deliveryAction from '../../actions/deliveryAction';
import { FBPixel, GA } from '../../reducers/menu';
import MenuPromoList, {
  MenuPromo,
} from '../../component/menuPromoList/menuPromoList';
import SearchBar from '../../component/searchBar/searchBar';
import LoginByDialog from '../../component/loginByDialog/loginByDialog';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import MenuDetailModal from './MenuDetailModal';
import { isNotSnoozed, isSnoozed } from '../../utils/orderUtils';

export const ImagePlaceholder = () => (
  <div className='lds-facebook'>
    <div />
    <div />
    <div />
  </div>
);

class MenuPage extends Component {
  constructor() {
    super();

    this.state = {
      selectedCategory: 0,
      isRemarkFocusing: false,
      loading: false,
      shouldExpandCategory: '',
      searchMenu: '',
      showLoginDialog: false,
      submitted: false,
    };

    this.stateless = {
      clickFromSideBar: false,
      isScrollPositionChecked: false,
    };
  }

  componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    snapshot: SS,
  ) {
    GA.sendPageView('Menu Page');
    FBPixel.sendPageView('Menu Page');
  }

  componentWillReceiveProps(nextProps, nextContext) {
    // restore scroll position
    if (
      nextProps.menu.scrollPosition &&
      this.coffeeListContainer &&
      nextProps.global.query &&
      nextProps.menu.scrollPosition.id ===
        Number(nextProps.global.query.merchantId) &&
      !this.stateless.isScrollPositionChecked
    ) {
      this.stateless.isScrollPositionChecked = true;
      this.coffeeListContainer.scrollTop = nextProps.menu.scrollPosition.value;
    }
  }

  componentDidMount() {
    const { showLastResult, ...parsedQuery } = queryString.parse(
      this.props.location.search,
    );

    if (showLastResult) {
      this.props.history.push(`/history?showLastResult=1`);
    }

    if (!!parsedQuery.noPopup && parsedQuery.noPopup === '1') {
      this.props.menuAction.toggleIsShowPromotion();
    }

    if (!this.props.location.search && this.props.global.query) {
      this.props.history.replace(
        `/menu?${queryString.stringify(this.props.global.query)}`,
      );
    }

    // get braintreeToken when init
    // this.getBrainTreeToken(merchantId)

    // Handle weChat login api redirect
    // const wechatLogin = async wechatCode => {
    //   if (!wechatCode) {
    //     return null;
    //   }
    //   this.setState({ loading: true });
    //   const PROVIDER = 'wechatLogin';
    //   const res = await login(PROVIDER, wechatCode);
    //   setStorage('code', '', 0);
    //
    //   // this.getBrainTreeToken(merchantId)
    //
    //   if (res.code === '200') {
    //     this.goToCheckoutPage();
    //   }
    // };
    // wechatLogin(getStorage('code'));

    // When there are no apiKey, try check if any fb redirect result.

    const fbRedirectLogin = async () => {
      if (!getStorage('apiKey')) {
        console.log('do not have apiKey, checking redirectResult');
        this.setState({ loading: true });

        const result = await firebaseGetRedirectResult();

        this.setState({ loading: false });

        console.log('redirect result');

        if (result) {
          const res = await login(FIREBASE_REDIRECT_RESULT);
          if (res.code === '200') {
            //call account api after login api been called.
            await this.props.authAction.getUserAccount();
            this.goToCheckoutPage();
          }
        }
      }
    };
    fbRedirectLogin();
  }

  getCheckOutItems = () => {
    if (
      !this.props.global.query.merchantId ||
      !this.props.checkout.items[this.props.global.query.merchantId]
    ) {
      return [];
    }

    return this.props.checkout.items[this.props.global.query.merchantId];
  };

  getBrainTreeToken = merchantId => {
    // no token, has merchant id, has apiKey
    !this.props.menu.brainTreeToken &&
      merchantId &&
      getStorage('apiKey') &&
      this.props.menuAction.getBrainTreeToken(merchantId);
  };

  openCoffeeModal = coffee => {
    let addOnByCategory = {};
    let totalAddOnPrice = 0;
    const { addOnCategories } = this.props.menu.menuList;

    coffee.addOns.forEach(addOn => {
      const haveAddOnCategory =
        addOn.addOnCategoryId && addOnCategories[addOn.addOnCategoryId];
      const addOnId = haveAddOnCategory
        ? addOnCategories[addOn.addOnCategoryId].name
        : 'General';
      if (!addOnByCategory[addOnId]) {
        addOnByCategory[addOnId] = { list: [], minOrder: 0 };
      }

      addOnByCategory[addOnId].list.push(addOn);
      addOnByCategory[addOnId].minOrder = haveAddOnCategory
        ? addOnCategories[addOn.addOnCategoryId].minOrder
        : 0;
    });

    // handle addOn that min selected is 1, and only 1 option available.
    Object.entries(addOnByCategory).forEach(([addOnName, value]) => {
      if (addOnName === 'General' || value.minOrder !== 1) {
        return;
      }

      const notSnoozedAddOns = value.list.filter(o =>
        isNotSnoozed(o.snoozeTill),
      );

      if (notSnoozedAddOns.length === 1) {
        const first = notSnoozedAddOns[0];
        first.checked = true;
        totalAddOnPrice += first.price;
      }
    });

    this.setState({
      selectedCoffee: {
        ...coffee,
        quantity: 1,
        otherRequest: '',
        addOns: addOnByCategory,
        totalAddOnPrice,
      },
    });
  };

  closeCoffeeModal = () => {
    this.setState({
      selectedCoffee: null,
      shouldExpandCategory: '',
    });
  };

  onCoffeeUpdated = coffee => {
    this.setState({
      selectedCoffee: { ...this.state.selectedCoffee, ...coffee },
    });
  };

  getAddOnCategoryByName = name => {
    const { addOnCategories } = this.props.menu.menuList;
    let category;
    Object.keys(addOnCategories).forEach(c => {
      if (addOnCategories[c].name === name) {
        category = addOnCategories[c];
      }
    });

    return category;
  };

  renderEachItemAmountOrder(item) {
    let list = this.getCheckOutItems();
    list = list.filter(e => {
      return e.id === item.id;
    });

    let total = 0;
    for (const index in list) {
      total += list[index].quantity;
    }

    if (total < 1) {
      return <FontAwesomeIcon icon='plus' />;
    }

    return (
      <div className={'order-amount'}>
        <div className={'amount'}>{total}</div>
      </div>
    );
  }

  scrollTo = (index, coffee) => {
    this.stateless.clickFromSideBar = true;

    this.setState({
      selectedCategory: index,
    });
    this.coffeeListContainer.scrollTop = this[coffee.name].offsetTop - 70; //to remove extra height from search bar
    setTimeout(() => {
      this.stateless.clickFromSideBar = false;
    });
  };

  listOnScroll = menuList => {
    if (this.stateless.clickFromSideBar) {
      return;
    }

    const listScrollTop = this.coffeeListContainer.scrollTop;
    const { categories } = menuList;

    for (let i = 0; i < categories.length; i++) {
      const current = categories[i];
      const currentRefTop = this[current.name].offsetTop;
      let next, nextRefTop, selected;

      // not last index
      if (i < categories.length - 1) {
        next = categories[i + 1];
        nextRefTop = this[next.name].offsetTop;
      }

      // last index
      if (!next && listScrollTop >= currentRefTop) {
        selected = i;
      } else if (
        next &&
        listScrollTop >= currentRefTop &&
        listScrollTop < nextRefTop
      ) {
        selected = i;
      }

      if (selected >= 0) {
        this.setState({
          selectedCategory: selected,
        });
      }
    }
  };

  renderSideBar = menuList => {
    const { selectedCategory } = this.state;

    return (
      <React.Fragment>
        {menuList.categories.map((coffee, index) => (
          <div
            key={`${coffee.name}-${index}`}
            className={' ' + (selectedCategory === index && 'selected')}
            onClick={() => this.scrollTo(index, coffee)}
          >
            {coffee.name}
          </div>
        ))}
      </React.Fragment>
    );
  };

  renderCoffeeList = menuList => {
    return (
      <React.Fragment>
        {menuList.categories.map(coffee => (
          <div
            key={coffee.id}
            className='list-container'
            ref={ref => (this[coffee.name] = ref)}
          >
            <div className='header'>{coffee.name}</div>
            {coffee.list.map(list => this.renderCoffeeDetails(coffee, list))}
          </div>
        ))}
      </React.Fragment>
    );
  };

  renderCoffeeDetails = (coffee, list) => {
    const currency = this.props.menu.menuList.merchant.currency;
    const { isClosing } = coffee;
    const isSnoozing = isSnoozed(list.snoozeTill);
    const menuImage = list.image
      ? list.image
      : this.props.menu.menuList.merchant.logo;

    return (
      <div
        key={list.id}
        className={
          'detail-container ' + ((isClosing || isSnoozing) && 'detail-overlay')
        }
        onClick={() =>
          isClosing || isSnoozing ? {} : this.openCoffeeModal(list)
        }
      >
        <div className='image-container'>
          <LazyLoadImage
            src={setImageUrlDimension(menuImage, 100)}
            placeholder={<ImagePlaceholder />}
          />
        </div>
        <div className='detail'>
          <div className='title-en'>{list.name}</div>
          <div className='title-cn'>{list.shortDesc}</div>
          <div className='price'>
            {currency} {parsePrice(list.price)}{' '}
            <span className={`${list.normalPrice ? 'normal-price' : 'hidden'}`}>
              {currency} {parsePrice(list.normalPrice)}
            </span>
          </div>
          {this.renderEachItemAmountOrder(list)}
        </div>
        {coffee.isClosing && (
          <div className='text'>
            <div>Available at</div>
            <div>{coffee.isClosing.desc}</div>
          </div>
        )}
      </div>
    );
  };

  isDelivery() {
    return !!Number(this.props.global.query.delivery);
  }

  goToCheckoutPage = () => {
    if (!getStorage('apiKey')) {
      this.setState({
        showLoginDialog: true,
      });
      return;
    }

    const checkOutItem = this.props.checkout.items[
      this.props.global.query.merchantId
    ];
    const params = Base64.encode(JSON.stringify(checkOutItem));

    // save scroll position when go to checkout page only
    this.props.menuAction.saveScrollPosition(
      this.props.menu.menuList.merchant.id,
      this.coffeeListContainer.scrollTop,
    );

    //If is delivery and have login check address,
    // if address list is not empty push to addresslist
    // else push to checkout
    if (this.isDelivery() && getStorage('apiKey')) {
      this.props.deliveryAction.getAddress().then(resp => {
        if (resp.addresses.length) {
          this.props.history.push(`/addresslist?orders=${params}`);
        } else {
          this.props.history.push(`/checkout?orders=${params}`);
        }
      });
    } else {
      // added orders params for mobile side
      this.props.history.push(`/checkout?orders=${params}`);
    }
  };

  onPromotionCancel = () => this.props.menuAction.toggleIsShowPromotion();

  onPromotionSubmit = () => openUrl('https://app.aliments.my/');

  ////
  renderCheckout = () => {
    const length = this.getCheckOutItems()
      .map(item => item.quantity)
      .reduce((item, total) => {
        return item + total;
      }, 0);

    return (
      <div
        className={'checkout-footer ' + (!!length && 'expand')}
        onClick={this.goToCheckoutPage}
      >
        <span>
          {length} Order{length > 1 && 's'}
        </span>
        <FontAwesomeIcon icon='chevron-right' />
      </div>
    );
  };

  loginOnClick = async res => {
    this.setLoading(true);
    // get response from account
    await this.props.authAction.getUserAccount();

    if (res.code === '500') {
      alert(`Login Error: ${res.message}`);
      return;
    }

    this.setLoading(false);
    this.goToCheckoutPage();
  };

  setLoading(loading) {
    this.setState({
      submitted: loading,
    });
  }

  render() {
    window.a = this;
    const { menu, location } = this.props;
    const { searchMenu } = this.state;
    const parsedQuery = queryString.parse(location.search);
    const {qrCompulsoryLogin = null, isQRLoginOrGuest = null} = menu.menuList ? menu.menuList.merchant : {};

    // Error Message show when:

    // i. Scan table code
    // no menu list
    // menulist not found
    // no merchantId

    // ii. Loading
    // got menu but merchantId not match with current list
    const errorMessage =
      (menu.menuList && menu.menuList.code === '500') ||
      !parsedQuery ||
      !parsedQuery.merchantId ? (
        'Please scan table QR Code'
      ) : !menu.menuList ||
        (parsedQuery &&
          parsedQuery.merchantId &&
          menu.menuList &&
          Number(parsedQuery.merchantId.replace('/', '')) !==
            menu.menuList.merchant.id) ? (
        <Loading>Loading...</Loading>
      ) : (
        ''
      );

    if (errorMessage) {
      return (
        <div className='coffee-page'>
          <div className={'scan-qr'}>{errorMessage}</div>
        </div>
      );
    }

    if (this.state.loading) {
      return <Loading>Logging in...</Loading>;
    }

    if (
      !isWechat() &&
      R.compose(
        R.equals('wechat pay'),
        R.toLower,
        R.pathOr('', ['global', 'query', 'partner']),
      )(this.props)
    ) {
      return (
        <div className='coffee-page'>
          <dit className={'scan-qr'}>
            Please scan table QR Code in WeChat app
          </dit>
        </div>
      );
    }

    let menuList = {
      ...this.props.menu.menuList,
      categories: this.props.menu.menuList.categories
        .map(menu => {
          return { ...menu, isClosing: isClosingNow(menu.timeAllows, true) };
        })
        .sort((next, current) => {
          if (current.isClosing) {
            return -1;
          }
          return 0;
        }),
    };

    menuList.categories.map(e => {
      e.list = e.list.filter(
        item =>
          !searchMenu ||
          item.name.toLowerCase().includes(searchMenu.toLowerCase()),
      );

      return e;
    });
    menuList.categories = menuList.categories.filter(e => e.list.length !== 0);

    const promos: [MenuPromo] = this.props.menu.menuList.promos;
    let { allowRemark } = this.props.menu.menuList.merchant;

    if (allowRemark === null || allowRemark === undefined) {
      allowRemark = true;
    }

    return (
      <div className='coffee-page'>
        {this.state.submitted && <Loading />}
        <div
          className={
            'coffee-container ' +
            (this.getCheckOutItems().length && 'coffee-container-expand')
          }
        >
          <div
            className={`side-bar ${!this.state.selectedCoffee &&
              'smooth-touch'}`}
          >
            {this.renderSideBar(menuList)}
          </div>
          <div className={'coffee-section'}>
            <SearchBar
              style={{ margin: '0px 15px' }}
              value={searchMenu}
              hint={'Search Menu'}
              onChange={e => {
                this.setState({
                  searchMenu: e.target.value,
                });
              }}
            />
            <div
              className={`coffee-list ${!this.state.selectedCoffee &&
                'smooth-touch'}`}
              onScroll={() => this.listOnScroll(menuList)}
              ref={ref => (this.coffeeListContainer = ref)}
            >
              <MenuPromoList list={promos} />
              {this.renderCoffeeList(menuList)}
            </div>
          </div>
        </div>
        {this.renderCheckout()}

        <MenuDetailModal
          allowRemark={allowRemark}
          menu={menu}
          selectedCoffee={this.state.selectedCoffee}
          onCoffeeUpdated={this.onCoffeeUpdated}
          onCancel={this.closeCoffeeModal}
          onConfirm={coffee => {
            this.props.menuAction.addToCart(
              coffee,
              this.props.global.query.merchantId,
            );
            this.props.checkoutAction.resetCheckOutCount();
          }}
        />

        <PromotionDialog
          isShowDialog={this.props.menu.isShowPromotionDialog}
          onCancelClick={this.onPromotionCancel}
          onSubmitClick={this.onPromotionSubmit}
        />
        <div className='menu-login'>
          <LoginByDialog
            openWhen={this.state.showLoginDialog}
            close={() => {
              this.setState({
                showLoginDialog: false,
              });
            }}
            isDining={
              //is dine in
              !(
                !!Number(this.props.global.query.takeAway) ||
                !!Number(this.props.global.query.delivery)
              )
            }
            qrCompulsoryLogin={qrCompulsoryLogin}
            isQRLoginOrGuest={isQRLoginOrGuest}
            loginOnClick={this.loginOnClick}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    menu: state.Menu,
    checkout: state.Checkout,
    global: state.Global,
    auth: state.Auth,
    delivery: state.Delivery,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    menuAction: bindActionCreators(menuAction, dispatch),
    globalAction: bindActionCreators(globalAction, dispatch),
    checkoutAction: bindActionCreators(checkoutAction, dispatch),
    authAction: bindActionCreators(authAction, dispatch),
    deliveryAction: bindActionCreators(deliveryAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuPage);
