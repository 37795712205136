import PropTypes from 'prop-types';
import React from 'react';

const Button = ({
  children,
  className,
  disabled,
  isOutline,
  onClick,
  text,
  ...props
}) => {
  const solidBtn = 'bg-aliments border-none text-white';
  const outlineBtn =
    'border-aliments border-solid bg-transparent text-aliments';
  return (
    <button
      className={`px-2 py-3 text-base rounded-lg font-bold cursor-pointer ${
        isOutline ? outlineBtn : solidBtn
      } ${className}`}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {children ? children : text}
    </button>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  isOutline: PropTypes.bool,
};

export default Button;
