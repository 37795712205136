import TYPES from './actionTypes';

export function saveParsedQuery(data) {
  return {
    type: TYPES.SAVE_PARSED_QUERY,
    data,
  };
}

export function saveApiKey(data) {
  return {
    type: TYPES.SAVE_API_KEY,
    data,
  };
}
