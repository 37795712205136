import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import EnlargeButton from '../../component/enlargeButton/enlargeButton';
import ModalComponent from '../../component/modal/modal';
import RightArrowButton from '../../component/rightArrowButton/rightArrowButton';
import { parsePrice, setImageUrlDimension } from '../../services/utils';
import { now } from 'moment';
import {isSnoozed} from "../../utils/orderUtils";

export default function MenuDetailModal(props) {
  const {
    mode = 'addToCart', // addToCart || updateCart
    allowRemark,
    menu,
    selectedCoffee,
    onCoffeeUpdated,
    onCancel,
    onConfirm,
  } = props;
  const merchant = menu.menuList.merchant;

  const [isRemarkFocusing, setIsRemarkFocusing] = useState(false);
  const [remark, setRemark] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [shouldExpandCategory, setShouldExpandCategory] = useState('');
  const [isInvalidAddOn, setIsInvalidAddOn] = useState(false);

  let totalAddOnPrice = selectedCoffee ? selectedCoffee.totalAddOnPrice : 0;

  useEffect(() => {
    if (selectedCoffee) {
      setQuantity(selectedCoffee.quantity);
    }
  }, [selectedCoffee]);

  const onRemarkFocus = () => {
    // setTimeout to prevent container height change before keyboard showup
    setTimeout(() => {
      setIsRemarkFocusing(true);
    });
  };

  const onRemarkBlur = () => {
    setTimeout(() => {
      setIsRemarkFocusing(false);
    }, 10);
  };

  const onRemarkChange = value => {
    setRemark(value);
    onCoffeeUpdated({
      ...selectedCoffee,
      otherRequest: value,
    });
  };

  const toggleBy = (opt, index, type) => {
    let optType;
    const addOnCategory = menu.menuList.addOnCategories[opt.addOnCategoryId];
    const addOnCategoryName = addOnCategory ? addOnCategory.name : 'General';

    // change checked to unchecked, uncheck to checked
    const setOptType = () => {
      optType[index] = { ...opt, checked: !opt.checked };

      if (type === 'addOns')
        opt.checked
          ? (totalAddOnPrice -= opt.price)
          : (totalAddOnPrice += opt.price);
    };

    // only selected become checked, other become unchecked
    const setOptTypeByOnlyOne = () => {
      const lastCheckedOpt = optType.find(type => !!type.checked);

      // Update Selected Option
      // CLICK SAME ID -> UNSELECT
      // DIFF ID -> Select current opt, deselect others.
      optType = optType.map(type => ({
        ...type,
        checked:
          lastCheckedOpt && type.id === lastCheckedOpt.id
            ? false
            : type.id === opt.id,
      }));

      // delete last checked opt price and add current checked opt price
      if (type === 'addOns') {
        let priceToAdd = opt.price;

        if (lastCheckedOpt) {
          const isSameOpt = lastCheckedOpt.id === opt.id;
          priceToAdd = isSameOpt
            ? -opt.price
            : opt.price - lastCheckedOpt.price;
        }

        totalAddOnPrice += priceToAdd;
      }
    };

    if (type === 'addOns') {
      // get list instead
      optType = [...selectedCoffee[type][addOnCategoryName].list];
    } else {
      optType = [...selectedCoffee[type]];
    }

    if (type === 'addOns' && addOnCategory) {
      if (addOnCategory.maxOrder === 1) {
        setOptTypeByOnlyOne();
      } else {
        setOptType();
      }
    }
    // allow only one options to be selected
    else if (opt.singleSelect) {
      setOptTypeByOnlyOne();
    } else {
      setOptType();
    }

    if (type === 'addOns') {
      optType = Object.assign(selectedCoffee.addOns, {
        [addOnCategoryName]: { list: optType },
      });
    }

    onCoffeeUpdated({ ...selectedCoffee, [type]: optType, totalAddOnPrice });
  };

  const addQuantity = () => {
    setQuantity(quantity + 1);
  };

  const minusQuantity = () => {
    setQuantity(quantity === 1 ? 1 : quantity - 1);
  };

  const addToCart = () => {
    const { addOns } = selectedCoffee;

    const addOnsInArr = Object.keys(addOns)
      .map(e => getAddOnCategoryByName(e))
      .sort((a, b) => b.sort - a.sort)
      .map(e => e.name);

    const newAddOn = {};
    let hasInvalidAddon = false;

    const toggleInvalidAddOn = isInvalid => {
      setIsInvalidAddOn(isInvalid);

      setTimeout(() => {
        setIsInvalidAddOn(false);
      }, 2500);
    };

    // check whether addOnCategory required min selection on any option
    // if got, check that option has any required minimum selection
    addOnsInArr.forEach(
      key => (newAddOn[key] = addOns[key].list.filter(l => l.checked).length),
    );
    addOnsInArr.forEach(key => {
      const category = getAddOnCategoryByName(key);
      if (category && category.minOrder && newAddOn[key] < category.minOrder) {
        hasInvalidAddon = { name: key, minOrder: category.minOrder };
      }
    });

    if (hasInvalidAddon) {
      // prevent spamming
      if (!isInvalidAddOn) {
        toggleInvalidAddOn(hasInvalidAddon);
      }
      return;
    }

    const coffee = {
      ...selectedCoffee,
      addOns: addOnsInArr.flatMap(addon => addOns[addon].list),
      selectedCoffee: selectedCoffee,
      quantity,
      createdDate: Date.now()
    };

    onConfirm(coffee);
    onCancel();
  };

  const isMaxOrder = (data, current) => {
    const { length } = data.list.filter(l => l.checked);
    const addOnCategory =
      menu.menuList.addOnCategories[data.list[0].addOnCategoryId];

    return (
      addOnCategory &&
      addOnCategory.maxOrder !== 1 &&
      length === addOnCategory.maxOrder &&
      !current.checked
    );
  };

  const renderSelectedCoffeeAddOns = addOns => {
    return Object.keys(addOns)
      .map(key => getAddOnCategoryByName(key))
      .sort((a, b) => b.sort - a.sort)
      .map(category => {
        // const category = this.getAddOnCategoryByName(key);
        const key = category ? category.name : 'General';
        const isSingleSelect = !!selectedCoffee.addOns[key].list[0]
          .singleSelect;
        const isExpandCurrentCategory = shouldExpandCategory === key;

        const toggleAddOnItemVisible = key => {
          setShouldExpandCategory(shouldExpandCategory === key ? '' : key);
        };

        const getCurrentCategorySelectedAddOnsNumber = currentCategory => {
          const getIsChecked = addOn => !!addOn.checked;
          return currentCategory
            ? currentCategory.list.filter(getIsChecked).length
            : 0;
        };

        return (
          <div key={key} className='addOn-container'>
            <div
              className='addOn-category'
              onClick={() => toggleAddOnItemVisible(key)}
            >
              <div>
                <div className='title'>{key}</div>
                <div className='category-order-tips'>
                  {renderAddOnText(category, isSingleSelect)}
                </div>
              </div>
              <div className='selected-addon-counter'>
                {getCurrentCategorySelectedAddOnsNumber(
                  selectedCoffee.addOns[key],
                )}{' '}
                Selected
              </div>
              <div className={'hide-button'}>
                <RightArrowButton open={isExpandCurrentCategory} />
              </div>
            </div>
            {selectedCoffee.addOns[key] && isExpandCurrentCategory && (
              <div className='addOn-item'>
                {selectedCoffee.addOns[key].list.map((addOn, i) => {
                  let isSnoozing = isSnoozed(addOn.snoozeTill)
                  return (
                    <div
                      className={
                        'item ' +
                        ((isMaxOrder(selectedCoffee.addOns[key], addOn) ||
                          isSnoozing) &&
                          'item-disabled')
                      }
                      key={addOn.id}
                      onClick={() =>
                        isSnoozing ? {} : toggleBy(addOn, i, 'addOns')
                      }
                    >
                      <div>{addOn.name}</div>
                      <div>
                        {merchant.currency} {parsePrice(addOn.price)}
                      </div>
                      <FontAwesomeIcon
                        icon='check'
                        className={'' + (addOn.checked && ' checked')}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      });
  };

  const getAddOnCategoryByName = name => {
    const { addOnCategories } = menu.menuList;
    let category;
    Object.keys(addOnCategories).forEach(c => {
      if (addOnCategories[c].name === name) {
        category = addOnCategories[c];
      }
    });

    return category;
  };

  const renderAddOnText = (category, isSingleSelect) => {
    const singleSelectText = 'Choose 1 option';
    const multiSelectText = 'Choose your add-on among options below';

    if (!category) {
      return <div>{isSingleSelect ? singleSelectText : multiSelectText}</div>;
    } else {
      const minOrderText = `At least ${category.minOrder} option(s)`;
      const maxOrderText = `No more than ${category.maxOrder} option(s)`;

      if (!!category.maxOrder && !!category.minOrder) {
        return (
          <React.Fragment>
            <div>{minOrderText}</div>
            <div>{maxOrderText}</div>
          </React.Fragment>
        );
      } else if (category.maxOrder) {
        return (
          <div>{category.maxOrder === 1 ? singleSelectText : maxOrderText}</div>
        );
      } else {
        if (!category.minOrder || category.minOrder === 0) {
          return (
            <div>{isSingleSelect ? singleSelectText : multiSelectText}</div>
          );
        }

        return <div>{minOrderText}</div>;
      }
    }
  };

  return (
    <ModalComponent
      className={'coffee-modal-body'}
      staticHeight={!!isRemarkFocusing}
      openWhen={selectedCoffee}
    >
      {selectedCoffee && (
        <div className='select-coffee-popup coffee-modal smooth-touch'>
          <div
            className='cover-image-container'
            style={{
              backgroundImage: `url(${setImageUrlDimension(
                selectedCoffee.image,
                512,
              )}), url(${setImageUrlDimension(
                menu.menuList.merchant.logo,
                512,
              )})`,
            }}
          >
            <EnlargeButton
              imageUrl={setImageUrlDimension(selectedCoffee.image, 512)}
            />
            <div className='overlay' />
            <div className='detail screen-over-flow'>
              <div className='title'>{selectedCoffee.name}</div>
              <div
                className='font-weight-light'
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {selectedCoffee.desc}
              </div>
              <div className='font-weight-light'>
                {merchant.currency} {parsePrice(selectedCoffee.price)}
              </div>
            </div>
          </div>
          <div className='body'>
            <Tabs>
              <TabList>
                {!!Object.keys(selectedCoffee.addOns).length && (
                  <Tab>Options</Tab>
                )}
                <Tab>Remarks</Tab>
              </TabList>
              {!!Object.keys(selectedCoffee.addOns).length && (
                <TabPanel>
                  <div className='tab-content'>
                    {renderSelectedCoffeeAddOns(selectedCoffee.addOns)}
                  </div>
                </TabPanel>
              )}
              <TabPanel>
                {selectedCoffee.remarks && !!selectedCoffee.remarks.length && (
                  <div className='tab-content'>
                    {selectedCoffee.remarks &&
                      selectedCoffee.remarks.map((remarks, i) => (
                        <div
                          key={remarks.id}
                          onClick={() => toggleBy(remarks, i, 'remarks')}
                        >
                          <div>{remarks.name}</div>
                          <FontAwesomeIcon
                            icon='check'
                            className={'' + (remarks.checked && ' checked')}
                          />
                        </div>
                      ))}
                  </div>
                )}
                <div className='other-request'>
                  {allowRemark && (
                    <input
                      onFocus={onRemarkFocus}
                      onBlur={onRemarkBlur}
                      type='text'
                      onChange={e => onRemarkChange(e.target.value)}
                      value={selectedCoffee.otherRequest}
                      placeholder='Other additional request'
                    />
                  )}
                  {!allowRemark && (
                    <div style={{ color: 'red', fontSize: '12px' }}>
                      Remark is disabled. Please inform restaurant for
                      additional request.
                    </div>
                  )}
                </div>
              </TabPanel>
            </Tabs>
          </div>
          <div className='footer'>
            <div
              className={'min-order ' + (isInvalidAddOn && 'invalid-min-order')}
            >
              {isInvalidAddOn && (
                <React.Fragment>
                  <FontAwesomeIcon icon='info' />
                  <div>
                    Choose at least {isInvalidAddOn.minOrder} option(s) from
                    <span> {isInvalidAddOn.name}</span>
                  </div>
                </React.Fragment>
              )}
            </div>
            <div className='footer-detail container-border-top'>
              <div>
                <div className='price'>
                  {merchant.currency}{' '}
                  {parsePrice(
                    (selectedCoffee.price + selectedCoffee.totalAddOnPrice) *
                      quantity,
                  )}
                </div>
                <div className='font-sm'>{selectedCoffee.name}</div>
              </div>
              {mode === 'addToCart' && (
                <div className='quatity-container'>
                  <FontAwesomeIcon icon='minus' onClick={minusQuantity} />
                  <div className='text'>{quantity}</div>
                  <FontAwesomeIcon icon='plus' onClick={addQuantity} />
                </div>
              )}
            </div>
            <div className='footer-btn-list container-border-top'>
              <div className='btn btn-cancel' onClick={onCancel}>
                <FontAwesomeIcon icon='ban' />
                Cancel
              </div>
              <div className='btn btn-submit' onClick={addToCart}>
                <FontAwesomeIcon icon='shopping-cart' />
                {mode === 'addToCart' ? 'Add to cart' : 'Update cart'}
              </div>
            </div>
          </div>
        </div>
      )}
    </ModalComponent>
  );
}
