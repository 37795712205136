import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as checkoutAction from '../../actions/checkoutAction';
import * as globalAction from '../../actions/globalAction';
import * as menuAction from '../../actions/menuAction';
import * as ticketAction from '../../actions/ticketAction';
import * as authAction from '../../actions/authAction';
import PrimaryButton from '../../component/button/primaryButton';
import { Loading } from '../../component/loading/loading';
import {
  getQueryFromPropsOrCache, getSessionStorage,
  getStorage,
  path,
  setImageUrlDimension, setSessionStorage, shouldRequestServerOTP,
} from '../../services/utils';
import './dynamicLandingPage.scss';
import LoginTextInput from '../../component/loginByDialog/loginTextInput';
import Button from '../../component/button/button';
import OtpDialog from '../../component/loginByDialog/otpDialog';
import ModalComponent from '../../component/modal/modal';
import {
  firebasePhoneLogin,
  getOTPBySMS,
  loginWithSmsOTP,
} from '../../services/auth';
import { LocalStorageKey } from '../../local_storage_key';
import { FBPixel, GA } from '../../reducers/menu';
import { isValidPhoneNumber } from '../../services/helper';
import {dynamicLandingRoute} from "../../routes";

class DynamicLandingPage extends PureComponent {
  state = {
    ticketId: undefined,
    customParam1: undefined,
    customParam2: undefined,
    customParam3: undefined,
    phoneNumber: '',
    requestOtp: false,
    isLoading: false,
    apiKey: getStorage('apiKey'),
  };

  constructor(props) {
    super(props);

    // Get query from url.
    const parsedQuery = getQueryFromPropsOrCache(props, [
      'merchantId',
      'ticketId',
      'customParam1',
      'customParam2',
      'customParam3',
    ]);

    /* Note: if local is equal to server ticket, call get firebase ticket api and send the auth to server, server will return if auth is expired or not*/
    const localTicket = getSessionStorage(LocalStorageKey.kTicketID);
    if (!localTicket) {
      // console.log('dont have')
      setSessionStorage(LocalStorageKey.kTicketID, parsedQuery.ticketId, 1);
      setSessionStorage(LocalStorageKey.kTicketAuth, '', 0);
    } else if (localTicket !== parsedQuery.ticketId) {
      // console.log('not same', localTicket, parsedQuery.ticketId)
      setSessionStorage(LocalStorageKey.kTicketAuth, '', 0);
      setSessionStorage(LocalStorageKey.kTicketID, parsedQuery.ticketId, 1);
    } else {
      // console.log('same')
    }

    // Save merchantId to redux
    if (parsedQuery.merchantId) {
      this.props.globalAction.saveParsedQuery(parsedQuery);
    }

    this.props.authAction.getUserAccount();
    const {
      merchantId,
      ticketId,
      customParam1,
      customParam2,
      customParam3,
    } = parsedQuery;

    let currentUrlParams = new URLSearchParams(window.location.search);

    if (merchantId && ticketId) {
      this.getMenu(merchantId);
      this.props.ticketAction.getTicket({ merchantId, ticketId, auth: currentUrlParams.get('uuid'), onSuccess: (auth) => {
        currentUrlParams.set('uuid', auth);
        localStorage.setItem('uuid', auth);
        this.props.history.push({
            pathname: dynamicLandingRoute,
            search: `?${currentUrlParams.toString()}`
          })
        }});

      this.state = {
        ticketId,
        customParam1,
        customParam2,
        customParam3,
      };
    }
  }

  componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    snapshot: SS,
  ) {
    GA.sendPageView('Dynamic Landing Page');
    FBPixel.sendPageView('Dynamic Landing Page');
  }

  getMenu = merchantId => {
    if (
      !this.props.menu.menuList ||
      (merchantId &&
        this.props.menu.menuList.merchant.id !== Number(merchantId))
    ) {
      this.props.menuAction.getMenu(merchantId, 'DINE_IN');
    }
  };

  onOrderClick = (
    merchantId,
    ticketId,
    customParam1,
    customParam2,
    customParam3,
  ) => () => {
    let param = '';

    if (customParam1) {
      param = param + '&customParam1=' + customParam1;
    }

    if (customParam2) {
      param = param + '&customParam2=' + customParam2;
    }

    if (customParam3) {
      param = param + '&customParam3=' + customParam3;
    }

    if (this.props.ticket && this.props.menu.menuList) {
      let orderList = Object.entries(this.props.ticket.ticket.ticketItemList);
      let menu = this.props.menu.menuList;
      this.props.menuAction.filterMenu(menu, orderList);
    }

    this.props.history.push(
      `/dynamic/menu?merchantId=${merchantId}&ticketId=${ticketId}${param ||
        ''}`,
    );
  };
  onMyOrderClick = () => this.props.history.push(`/dynamic/order`);

  onPhoneNumberChange = event => {
    let value = event.target.value.trim();
    value = value.replace('+', '');

    if (isNaN(value)) {
      return;
    }

    this.setState({
      phoneNumber: value.startsWith('01') ? `+6${value}` : `+${value}`,
    });
  };

  async onFirebaseOtpResult(result, onSuccess) {
    const { phoneNumber } = result.user;
    const token = await result.user.getIdToken();
    firebasePhoneLogin(phoneNumber, token).then(async resp => {
      this.setState({
        apiKey: getStorage('apiKey'),
      });
      await this.props.authAction.getUserAccount();
      this.setOtpDialog(false);
      onSuccess();
    });
  }

  loginUsingFirebaseToken(otpCode, onSuccess) {
    window.confirmationResult
      .confirm(otpCode)
      .then(result => this.onFirebaseOtpResult(result, onSuccess))
      .catch(e => {
        if (e.code) {
          switch (e.code) {
            case 'auth/invalid-verification-code':
              alert('Invalid OTP Code');
              break;

            default:
              alert('Sign-In Error \n' + e.message);
          }
        } else {
          alert('Sign-In Error, Please try again');
        }
      });
  }

  async loginUsingServerOTP(phoneNumber, otpCode, onSuccess) {
    const resp = await loginWithSmsOTP(phoneNumber, otpCode);
    if (resp['code'] === '200') {
      this.setState({
        apiKey: getStorage('apiKey'),
      });
      await this.props.authAction.getUserAccount();
      this.setOtpDialog(false);
      onSuccess();
    } else {
      alert(resp['message'] || 'Something went wrong. Please try again later');
    }
    this.setLoading(false);
  }

  requestFirebaseOTP(finalNumber) {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new window.firebase.auth.RecaptchaVerifier(
        'sign-in-button',
        {
          size: 'invisible',
          callback: function(response) {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
          },
        },
      );
    }
    const appVerifier = window.recaptchaVerifier;

    window.firebase
      .auth()
      .signInWithPhoneNumber(finalNumber, appVerifier)
      .then(confirmationResult => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        this.setOtpDialog(true);
      })
      .catch(function(error) {
        // Error; SMS not sent
        // ...
        if (error.code === 'auth/invalid-phone-number') {
          alert(`Invalid Phone Number: ${error.message}`);
        } else alert(`Something went wrong. \nError: ${error.message}`);
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  async requestServerOTP(finalNumber) {
    const resp = await getOTPBySMS(finalNumber);
    if (resp['code'] === '200') {
      setTimeout(() => {
        this.setOtpDialog(true);
        this.setLoading(false);
        clearTimeout();
      }, 2000);
    } else {
      this.setLoading(false);
      alert(resp['message'] || 'Something Went Wrong. Please try again later.');
    }
  }

  async onOtpSubmit(otpCode, onSuccess: () => {}) {
    if (!otpCode) {
      alert('Otp code is required');
    }

    let phoneNumber = this.getPhoneNumberWithCountryCode();
    this.setLoading(true);

    if (shouldRequestServerOTP(phoneNumber)) {
      await this.loginUsingServerOTP(phoneNumber, otpCode, onSuccess);
    } else {
      this.loginUsingFirebaseToken(otpCode, onSuccess);
    }
  }

  async onPhoneNumberRequestOTP() {
    const finalNumber = this.getPhoneNumberWithCountryCode();

    if (!finalNumber) {
      alert('Phone number is required.');
      return;
    } else if (!isValidPhoneNumber(finalNumber)) {
      alert('Invalid Phone Number');
      return;
    }

    this.setLoading(true);

    if (shouldRequestServerOTP(finalNumber)) {
      await this.requestServerOTP(finalNumber);
    } else {
      this.requestFirebaseOTP(finalNumber);
    }
  }

  setLoading(isLoading) {
    this.setState({
      isLoading: isLoading,
    });
  }

  setOtpDialog(open) {
    this.setState({
      requestOtp: open,
    });
  }

  getPhoneNumberWithCountryCode(): string {
    const phoneNumber = this.state.phoneNumber || '';
    if (phoneNumber.startsWith('+')) return phoneNumber;
    else if (phoneNumber.startsWith('601')) return '+' + phoneNumber;
    else if (phoneNumber.startsWith('01')) return '+6' + phoneNumber;
    else if (phoneNumber.startsWith('1')) return '+60' + phoneNumber;
    else return '';
  }

  getBottomPoweredByAliments(textColor) {
    return (
      <div className='bottom'>
        <div className='powered-by'>
          <a style={{ color: textColor }} href='https://aliments.my'>
            Powered by HUBBO POS
          </a>
        </div>
      </div>
    );
  }

  getCashbackBalance(list: [], id) {
    let merchantCashback;

    for (const i in list) {
      if (list[i].chain === id || list[i].merchantId === id) {
        merchantCashback = list[i];
        break;
      }
    }

    if (!merchantCashback) {
      return '';
    }

    return `Cashback Balance: ${
      merchantCashback.currency
    }${merchantCashback.balance.toFixed(2)}`;
  }

  renderPoweredByAliments(textColor) {
    return (
      <div className='bottom'>
        <div className='powered-by'>
          <a style={{ color: `${textColor}` }} href='https://aliments.my'>
            Powered by HUBBO POS
          </a>
        </div>
      </div>
    );
  }

  render() {
    const merchant = path('menu.menuList.merchant', this.props);
    const ticket = path('ticket.ticket', this.props);
    const {
      ticketId,
      customParam1,
      customParam2,
      customParam3,
      phoneNumber,
      isLoading,
      requestOtp,
    } = this.state;
    const { auth, settingsApi } = this.props;

    if (!merchant || !ticket) return <Loading>Loading...</Loading>;

    const logoStyle = {
      backgroundImage: `url(${setImageUrlDimension(merchant.primeLogo, 200)})`,
    };

    const haveTicket = !!ticket.description;
    const {qrCompulsoryLogin, isQRLoginOrGuest} = merchant;
    const shouldShowLoginBox = isQRLoginOrGuest ? true : qrCompulsoryLogin;

    return (
      <div
        className='dynamic-landing-page'
        style={{
          backgroundImage: `url(${setImageUrlDimension(
            merchant.landingBackgroundImg,
            512,
          )})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'auto 100%',
          backgroundPosition: 'center',
          boxShadow: 'inset 0 0 0 2000px rgba(0,0,0,0.5)',
        }}
      >
        {isLoading && <Loading />}
        <div className='top p-4'>
          <div className='top-left'>
            {haveTicket && auth.user.me && (
              <div>
                <div> Login as {auth.user.me.name}</div>
                <div>
                  {this.getCashbackBalance(
                    auth.user.cashbacks,
                    merchant.chain ? merchant.chain : merchant.id,
                  )}{' '}
                </div>
              </div>
            )}
          </div>
          <div className='top-right'>
            {haveTicket && (
              <PrimaryButton
                rounded={true}
                smBtn={true}
                onClick={this.onMyOrderClick}
              >
                <div>My Order</div>
              </PrimaryButton>
            )}
          </div>
        </div>
        <div className='center'>
          <div className='center-contain'>
            <div className={'logo'} style={logoStyle} />
            <div>{merchant.name}</div>
            <div style={{ height: '24px' }} />

            {!haveTicket ? (
              <div style={{ fontWeight: '600' }}>
                Ticket expired, please request at counter.
              </div>
            ) : (
              <div className='pb-8'>
                <div className={'sm-font'}>TABLE</div>
                <div>{ticket.description}</div>
                <div style={{ height: '24px' }} />
                {((haveTicket && auth.user.me) || !shouldShowLoginBox) && (
                  <PrimaryButton
                    rounded={true}
                    primaryColor={true}
                    onClick={this.onOrderClick(
                      merchant.id,
                      ticketId,
                      customParam1,
                      customParam2,
                      customParam3,
                    )}
                  >
                    <div>START ORDER</div>
                  </PrimaryButton>
                )}
              </div>
            )}
          </div>
        </div>

        {(haveTicket && !auth.user.me && shouldShowLoginBox) ? (
          <div
            style={{
              backgroundColor: 'whitesmoke',
              borderRadius: '30px 30px 0px 0px',
              display: 'flex',
              flexDirection: 'column',
              padding: '10px',
              color: 'black',
              textAlign: 'center',
            }}
          >
            <div style={{ fontWeight: 'bold', padding: '10px' }}>
              Login with your phone number to start order
            </div>
            <LoginTextInput
              margin='0px 10px'
              placeholder='Phone number'
              value={phoneNumber}
              onChange={this.onPhoneNumberChange}
            />
            <Button
              text={'CONTINUE'}
              className='uppercase rounded-full'
              isOutline={true}
              style={{
                margin: '10px',
              }}
              onClick={async () => {
                await this.onPhoneNumberRequestOTP();
              }}
            />
            {isQRLoginOrGuest && (
              <>
                <p className='text-sm my-0 text-gray-500'>OR</p>
                <Button
                  text={'ORDER AS GUEST'}
                  className='uppercase rounded-full'
                  style={{
                    margin: '10px',
                  }}
                  onClick={this.onOrderClick(
                    merchant.id,
                    ticketId,
                    customParam1,
                    customParam2,
                    customParam3,
                  )}
                />
              </>
            )}

            <p className='text-sm mx-4 text-gray-500'>
              By continuing, you agree to our T&Cs* and{' '}
              <a
                target='_blank'
                className='text-gray-500'
                href='https://docs.google.com/document/d/e/2PACX-1vTvbWFdaobxK9c7vU3T_nwrcoIlhRp3tLv1vZjYE1jjyRxgQ85w2ZICGbxhtgw7E0qA3oLARMPJKzAItmTqT74/pub'
              >
                Privacy Policy
              </a>
              , and your data may be shared with our affliated companies in the
              Grab group for data analytics to improve service offerings and
              personalize your experience on Hubbo and Grab. For more
              information, please refer to <br />
              <a
                target='_blank'
                className='text-gray-500'
                href='https://www.grab.com/my/terms-policies/privacy-notice/'
              >
                Grab's Privacy Notice
              </a>
              .
            </p>
            {this.renderPoweredByAliments('black')}
          </div>
        ) : (
          this.renderPoweredByAliments()
        )}
        <div id='sign-in-button' className='hidden' />

        <ModalComponent
          closeIconColor={'black'}
          styleWidth='auto'
          styleHeight='auto'
          stylePadding='unset'
          close={() => {
            this.setLoading(false);
            this.setOtpDialog(false);
          }}
          openWhen={requestOtp}
        >
          <OtpDialog
            onOtpSubmit={async otp =>
              this.onOtpSubmit(
                otp,
                this.onOrderClick(
                  merchant.id,
                  ticketId,
                  customParam1,
                  customParam2,
                  customParam3,
                ),
              )
            }
            onResentOtpClick={async () => {
              this.setOtpDialog(false);
              await this.onPhoneNumberRequestOTP();
            }}
            phoneNumber={this.getPhoneNumberWithCountryCode()}
            onCloseDialogClick={() => this.setOtpDialog(false)}
          />
        </ModalComponent>
      </div>
    );
  }
}

DynamicLandingPage.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  menu: PropTypes.object,
  ticket: PropTypes.object,

  globalAction: PropTypes.object,
  menuAction: PropTypes.object,
  ticketAction: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    menu: state.Menu,
    checkout: state.Checkout,
    global: state.Global,
    ticket: state.Ticket,
    settingsApi: state.Settings,
    auth: state.Auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    menuAction: bindActionCreators(menuAction, dispatch),
    globalAction: bindActionCreators(globalAction, dispatch),
    checkoutAction: bindActionCreators(checkoutAction, dispatch),
    ticketAction: bindActionCreators(ticketAction, dispatch),
    authAction: bindActionCreators(authAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DynamicLandingPage);
