import React, {Component} from "react";
import {isClosingNow} from "../../services/utils";
import {OutletSession, PreOrderSession} from "../../services/constants";
import {LazyLoadImage} from "react-lazy-load-image-component";
import PreOrderModal from "../checkout/preOrderTime/preOrderModal";
import {bindActionCreators} from "redux";
import * as outletAction from "../../actions/outletAction";
import {connect} from "react-redux";
import PropTypes from "prop-types";

class OutletMerchantCard extends Component {
  state = {
    openModal: false
  }

  constructor(props) {
    super(props);
    this.stateless = {
      merchant: props.merchant,
    }
  }

  navigateToLanding() {
    const {merchant} = this.stateless;
    const {tableNo, chain, searchText, props} = this.props;
    props.outletAction.saveLastSearchText(searchText)
    props.outletAction.saveOutletScrollPosition(merchant.id)
    if (tableNo && chain) {
      sessionStorage.setItem(OutletSession.chain, chain);
      sessionStorage.setItem(OutletSession.tableNo, tableNo);
      props.history.push(`/menu?merchantId=${merchant.id}&tableNo=${tableNo}&noPopup=1`)
      return;
    } else {
      //to clear chain and tableNo, to prevent preOrder been clear
      const chain = sessionStorage.getItem(OutletSession.chain);
      const tableNo = sessionStorage.getItem(OutletSession.tableNo);
      if (tableNo || chain) {
        sessionStorage.clear();
      }
    }

    props.history.push(`/landing?merchantId=${merchant.id}`)
  }

  render() {
    const lastPosition = this.props.outlet.scrollPosition;
    const {merchant} = this.stateless;
    const {openModal} = this.state;
    const {tableNo, onSelect, props, selectedSlot} = this.props;

    let id = null;
    if (lastPosition && lastPosition === merchant.id) {
      id = 'scroll-to-here'
    }

    //asap cant filter properly
    const _isClosingNow = isClosingNow(merchant.operatingHours, false, selectedSlot.isAsap ? null : selectedSlot.start);
    const temporaryClose = merchant.pauseTill && merchant.pauseTill >= selectedSlot.start;
    const supportPreOrder = merchant.supportPreOrder;
    const isClosing = _isClosingNow || temporaryClose;
    const title = merchant.name;
    const categories = merchant.desc;
    const location = merchant.addr;
    const tagList = merchant.tags;
    const image = merchant.primeLogo
    return (
        <React.Fragment>
          <div id={id} className={`merchant-card ${isClosing && `backgroundFilter`}`} onClick={
            () => {
              if (isClosing) {
                if(tableNo){
                  return;
                }else if (supportPreOrder) {
                  this.setState({
                    openModal: true
                  })
                } else {
                  alert('This merchant does not support pre-order');
                }
                return;
              } else if (!selectedSlot.isAsap && !supportPreOrder) {
                alert('This merchant does not support pre-order');
                return;
              }

              this.navigateToLanding(props);
            }
          }>
            {/*https://www.npmjs.com/package/react-lazyload*/}
            <div style={{position: 'relative'}}>
              <LazyLoadImage style={{width: "100px", height: "100px", borderRadius: "22px", objectFit: "cover"}}
                             src={image} wrapperClassName={'img-merchant'} effect="blur"/>
            </div>
            {/*<img className="img-merchant" src={image}/>*/}
            <div className="merchant-description">
              <div className="title">
                {title}
              </div>
              <div className="category">
                {categories}
              </div>
              {location && <div className="location">
                <img src="./image/Vector.png"/>
                {location}
              </div>}
              <div className="tag">
                {!tagList ? '' : tagList.map((tag, index) => {
                  return (
                      <div key={index}>
                        {tag.name}
                      </div>
                  )
                })}
                {
                    !tableNo && supportPreOrder && <div style={{backgroundColor: '#f8b601'}}>Preorder</div>
                }
              </div>
              {temporaryClose &&
                  (<div>Temporary Closed</div>
                  )}
              { _isClosingNow &&
                  (<div className={'flex flex-row'}>
                        <div style={{color:'red'}} className={'font-bold'}>Closed</div> &nbsp;
                        {
                            merchant.operatingHours.length !== 0 && <div>. Open at {isClosing.shortDesc}</div>
                        }

                      </div>
                  )}
            </div>
          </div>
          <PreOrderModal close={() => {
            this.setState({
              openModal: false
            })
          }} isOpen={openModal} merchant={merchant} onSelect={(slot) => {
            this.navigateToLanding();
            onSelect(slot)
            this.setState({
              openModal: false
            })
          }}/>
        </React.Fragment>
    );
  }
}

OutletMerchantCard.propTypes = {
  merchant: PropTypes.object,
  selectedSlot: PropTypes.object,
  onSelect: PropTypes.func,
  tableNo: PropTypes.string,
  chain: PropTypes.string,
  searchText: PropTypes.string,
  props: PropTypes.object
};

function mapStateToProps(state) {
  return {
    outlet: state.Outlet,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    outletAction: bindActionCreators(outletAction, dispatch)
  };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OutletMerchantCard);
