import React, {PureComponent} from "react";
import PropTypes from "prop-types";

export class MenuPromo {
    id: string;
    image: string;
}

class MenuPromoList extends PureComponent {
    render() {
        const list: [MenuPromo] = this.props.list;
        if (!list || list.length === 0) {
            return <div/>;
        }

        return (
            <div
                className='list-container'
            >
                <div className='header'>Promotions</div>
                <div style={{overflowX: "scroll", display: "flex"}}>
                    {
                        list.map((e) => {
                            return <img key={e.id} style={{
                                borderRadius: "10px",
                                margin: "10px 10px 20px 10px",
                                height: "100px",
                                boxShadow: "0px 10px 5px -5px grey"
                            }}
                                        src={e.image}
                                        alt='aliments'/>
                            }
                        )
                    }
                </div>
            </div>
        );
    }
}

MenuPromoList.propTypes = {
    list: PropTypes.arrayOf(PropTypes.shape(MenuPromo))
}

export default MenuPromoList;