import TYPES from '../actions/actionTypes';

const initialState = {
  addresses: [],
  selectedAddress: {},
  deliveryQuotation: {},
};

export default function Delivery(state = initialState, action) {
  switch (action.type) {
    case TYPES.GET_ADDRESS_SUCCESS:
      return {
        ...state,
        addresses: [...action.data],
      };
    case TYPES.SELECT_ADDRESS:
      return {
        ...state,
        selectedAddress: { ...action.data },
      };
    case TYPES.GET_QUOTATION_SUCCESS: {
      return {
        ...state,
        deliveryQuotation: { ...action.data },
      };
    }
    case TYPES.GET_QUOTATION: {
      return {
        ...state,
        deliveryQuotation: {},
      };
    }
    case TYPES.REMOVE_SELECTED_ADDRESS: {
      return {
        ...state,
        selectedAddress: {},
      };
    }
    case TYPES.SELECT_FIRST_ADDRESS:
      return {
        ...state,
        selectedAddress:
          (!Object.keys(state.selectedAddress).length &&
            state.addresses.length &&
            state.addresses[0]) ||
          state.selectedAddress,
      };
    default:
      return {
        ...state,
      };
  }
}
