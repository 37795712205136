import DeliveryQuotation from '../deliveryQuotation';
import BaseResp from './baseResp';

export class GetQuotationResp extends BaseResp {
  constructor({alertMsg, apiKey, message, code, deliveryQuotation }) {
    super({apiKey, message, code });
    if (deliveryQuotation)
      this.deliveryQuotation = new DeliveryQuotation(deliveryQuotation);

    if (alertMsg)
      this.alertMsg = alertMsg
  }
}
