import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Swipeout from 'rc-swipeout';
import 'rc-swipeout/assets/index.css';
import React from 'react';
import DeliveryAddress from '../../model/deliveryAddress';
import {isDevice} from "../../services/utils";

const AddressListTile = ({ address, onEditClick, onDeleteClick, onClick }) => {
  return (
    <Swipeout
      right={[
        {
          text: 'Delete',
          onPress: onDeleteClick,
          className: 'px-2 bg-red-500 text-white',
        },
        {
          text: 'Edit',
          onPress: onEditClick,
          className: 'px-2 bg-blue-500 text-white',
        },
      ]}
    >
      <div className='flex items-center py-2'>
        <div className='flex flex-grow' onClick={onClick}>
          <FontAwesomeIcon icon={'map-marker-alt'} className={'p-3'} />
          <div className='flex-auto items-center'>
            <div>{address.recipientName}</div>
            <div>{address.address}</div>
          </div>
          {isDevice() !== 'Web' && <FontAwesomeIcon icon={'chevron-right'} className={'p-3'}/>}
        </div>
        {isDevice() === 'Web' && <div className='flex'>
          <div onClick={onDeleteClick} className='p-2 bg-red-500 text-white'>
            Delete
          </div>
          <div onClick={onEditClick} className='p-2 bg-blue-500 text-white'>
            Edit
          </div>
        </div>}
      </div>
    </Swipeout>
  );
};
AddressListTile.defaultProps = {
  onEditClick: () => alert('edit'),
  onDeleteClick: () => alert('delete'),
};
AddressListTile.propTypes = {
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onClick: PropTypes.func,
  address: PropTypes.instanceOf(DeliveryAddress),
};

export default AddressListTile;
