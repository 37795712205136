import CircleButton from '../circleButton/circleButton';
import React, { PureComponent } from 'react';
import './quantityBox.scss';

class QuantityBox extends PureComponent {
  render() {
    return (
      <div className={`quantity-box-container ${this.props.className || ''}`}>
        <CircleButton
          className={'circle'}
          icon={'minus'}
          iconClassName={'primary-color'}
          onClick={this.props.onClick(this.props.quantity - 1)}
        />

        <div className={'quantity'}>{this.props.quantity}</div>

        <CircleButton
          className={'circle primary-bg'}
          icon={'plus'}
          iconStyle={{ color: '#FFFFFF' }}
          onClick={this.props.onClick(this.props.quantity + 1)}
        />
      </div>
    );
  }
}

export default QuantityBox;
