import TYPES from './actionTypes';
import ApiService from '../services/api';
import { ApiPath } from '../services/constants';
import { FNB } from '../page/einvoice/EInvoiceSubmissionPage';

export function getEInvoice(uuid, backgroundFetch = false, target = FNB) {
  const path = ApiPath.getEInvoicePath(uuid);
  const url =
    target === FNB ? process.env.REACT_APP_ENV : process.env.REACT_APP_FUEL_URL;

  return async dispatch => {
    dispatch({
      type: backgroundFetch
        ? TYPES.GET_E_INVOICE_IN_BACKGROUND
        : TYPES.GET_E_INVOICE,
    });

    try {
      const resp = await ApiService.getV2(`${url}${path}`, false, true);

      dispatch({
        type: TYPES.GET_E_INVOICE_SUCCESS,
        data: { ...resp.data },
      });
    } catch (error) {
      const data =
        error.response && error.response.data
          ? error.response.data
          : {
              code: 500,
              message: 'Something went wrong',
            };
      dispatch({ type: TYPES.GET_E_INVOICE_FAILED, data });
    }
  };
}

export function submitEInvoice(data, target = FNB) {
  const path = ApiPath.submitEInvoice;
  const url =
    target === FNB ? process.env.REACT_APP_ENV : process.env.REACT_APP_FUEL_URL;

  return async dispatch => {
    dispatch({ type: TYPES.SUBMIT_E_INVOICE });

    try {
      const resp = await ApiService.postV2(`${url}${path}`, data, false, true);

      dispatch({
        type: TYPES.SUBMIT_E_INVOICE_SUCCESS,
        data: { ...resp.data },
      });
    } catch (error) {
      const data =
        error.response && error.response.data
          ? error.response.data
          : {
              code: 500,
              message: 'Something went wrong',
            };
      dispatch({ type: TYPES.SUBMIT_E_INVOICE_FAILED, data });
    }
  };
}
