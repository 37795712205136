import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '../../component/button/button';
import DeliveryQuotation from '../../model/deliveryQuotation';
import { GetQuotationResp } from '../../model/response/getQuotationResp';
import ApiService from '../../services/api';
import { getDateFromNow, getStorage, parsePrice } from '../../services/utils';
import DeliveryMap from './deliveryMap';
import './historyDetailPopup.scss';

export default class HistoryDetailPopup extends React.Component {
  state = {};

  componentDidMount() {
    this.setState({
      selectedHistory: this.props.selectedHistory,
    });

    if (this.isDelivery()) {
      this.callApi();
      this.timerId = setInterval(() => {
        this.callApi();
      }, 10 * 1000);
    }
  }

  callApi() {
    const audio = new Audio('/sound/ding.mp3');

    ApiService.get(
      `/api/customer/delivery/update/${this.props.selectedHistory.id}`,
      true,
      true,
    ).then(value => {
      if (!value) return;

      const resp = new GetQuotationResp(value);

      if (resp.isSuccess()) {
        const isAllSame = (before = {}, after = {}, condition = []) => {
          return condition.every(value => {
            const beforeElement = before[value];
            const afterElement = after[value];
            console.log(
              `beforeElement, afterElement`,
              value,
              beforeElement,
              afterElement,
              beforeElement === afterElement,
            );
            return beforeElement === afterElement;
          });
        };

        const shouldSound = !isAllSame(
          { ...this.state.selectedHistory.deliveryQuotation },
          { ...resp.deliveryQuotation },
          [
            'orderReceivedDate',
            'driverFoundDate',
            'preparingDate',
            'driverPickedUpDate',
            'deliveredDate',
          ],
        );

        if (shouldSound) {
          audio.play();
        }

        this.setState({
          selectedHistory: {
            ...this.state.selectedHistory,
            deliveryQuotation: { ...resp.deliveryQuotation },
          },
        });
      }
    });
  }

  async callImHereDriveThruApi(orderId) {
    let request = {
      carPlate: getStorage('carPlateNumber'),
      describeYourCar: getStorage('carDescription'),
      orderId: orderId,
    };
    const result = await ApiService.post(
      'api/customer/driveThruNotify',
      request,
    );

    if (result.code === '200') {
      alert(
        'Your arrival already been noticed by our staff. Your order will be deliver to you soon. Thank you',
      );
    } else if (result.message === 'err.beep.limit') {
      alert(
        'You already reach the limit of calling, please try again after 5 minutes. Thank you',
      );
    } else {
      alert(`Error: ${result.message}`);
    }
  }

  isDelivery() {
    const { selectedHistory } = this.props;

    return (
      !!selectedHistory.deliveryQuotation &&
      selectedHistory.deliveryQuotation.id
    );
  }

  cancelOrderButton(selectedHistoryId) {
    return (
      <Button
        isOutline={true}
        className='flex-1 mx-3'
        onClick={async () => {
          let answer = await window.confirm(
            'Are you sure you want to cancel your order?',
          );
          if (answer) {
            await ApiService.get(
              `api/customer/delivery/cancel/${selectedHistoryId}`,
              true,
            ).then(result => {
              if (result.code === '200') {
                this.setState({
                  selectedHistory: {
                    ...this.state.selectedHistory,
                    deliveryQuotation: { ...result.deliveryQuotation },
                  },
                });
                window.alert('Your order has been cancelled.');
              } else {
                window.alert(
                  'This order is not allow to cancel now. Please contact us for any inquiry',
                );
              }
            });
          }
        }}
        text={'CANCEL ORDER'}
      >
        CANCEL ORDER
      </Button>
    );
  }

  componentWillUnmount() {
    if (this.timerId) clearInterval(this.timerId);
  }

  render() {
    let { selectedHistory, toggleRequestReceipt } = this.props;
    const currency = selectedHistory.merchant.currency;
    if (this.state.selectedHistory && this.state.selectedHistory.id) {
      selectedHistory = this.state.selectedHistory;
    }
    console.log(`selectedHistory`, selectedHistory);

    const dateCreated = selectedHistory.dateCreated;
    const isMoreThan2Hour =
      Math.abs(moment().diff(moment(dateCreated), 'hours')) >= 2;

    const isReadyToCollect =
      selectedHistory.paymentStatus === 'SUCCESS' &&
      selectedHistory.status === 'COMPLETED';

    const hasQuotationId = Number(selectedHistory.deliveryQuotationId);

    let isOrderCanceled = false;
    if (!!selectedHistory.deliveryQuotation) {
      if (selectedHistory.deliveryQuotation.status === 'CANCELLED') {
        isOrderCanceled = true;
      }
    }

    return (
      <div className='modal-order'>
        <div className='modal-order-container'>
          <div className='modal-order-detail'>
            <div className='font-lg'>
              {selectedHistory && selectedHistory.merchant.name}
            </div>
            <div>
              {selectedHistory.byPoint ? (
                <React.Fragment>
                  <div>
                    <span className='font-lg'>
                      {(selectedHistory.totalPaymentWithTax * 100).toFixed(0)}
                    </span>
                    <span className='pts'>pts</span>
                  </div>
                  <div className='date-margin font-sm'>
                    was {currency}{' '}
                    {parsePrice(selectedHistory.totalPaymentWithTax)}
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className={'equal-size'}>
                    {' '}
                    <span className='font-sm currency'>{currency}</span>
                    <span className='font-lg'>
                      {parsePrice(selectedHistory.totalPaymentWithTax)}
                    </span>
                  </div>
                </React.Fragment>
              )}
              {!!selectedHistory.pickupNo &&
                !isMoreThan2Hour &&
                !hasQuotationId && (
                  <div className={'equal-size'}>
                    <span className='font-sm currency'>PICKUP NO</span>
                    <span className='font-lg pickup-no blink_me'>
                      {selectedHistory.pickupNo}
                    </span>
                  </div>
                )}
              {!!(
                selectedHistory.totalMerchantDiscount ||
                selectedHistory.totalDiscount
              ) && (
                <div className='font-sm date-margin'>
                  saved {currency}{' '}
                  {parsePrice(
                    selectedHistory.totalMerchantDiscount +
                      selectedHistory.totalDiscount,
                  )}
                </div>
              )}
            </div>
            <div className='font-sm'>
              <div>{selectedHistory.transId}</div>
              <div className='date-margin'>
                {getDateFromNow(selectedHistory.dateCreated, 0)}
              </div>
            </div>
            {(selectedHistory.paymentStatus === 'PENDING' ||
              selectedHistory.paymentStatus === 'FAILED') && (
              <div className={selectedHistory.paymentStatus.toLowerCase()}>
                Payment {selectedHistory.paymentStatus.toLowerCase()}
              </div>
            )}
            {!hasQuotationId && isReadyToCollect && (
              <div style={{ color: 'green' }}>
                Your item is ready to collect
              </div>
            )}
          </div>

          {hasQuotationId ? (
            <DeliveryDetail detail={selectedHistory} />
          ) : (
            <div className='order-history'>
              <div className='order-history-list'>
                {selectedHistory.items &&
                  selectedHistory.items.map(item => (
                    <div className='order-row' key={item.id}>
                      <div>{item.menuName}</div>
                      <div>
                        {currency} {parsePrice(item.amount)}
                      </div>
                      <div className='font-weight-light'>
                        {item.addOn.toString()}
                      </div>
                      <div className='font-weight-light'>
                        {item.remark.toString()}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
        {this.setupDisplayCancelOrderForDelivery(
          hasQuotationId,
          selectedHistory,
        )}
        <div className='flex justify-between'>
          {this.setupAlimentsCallButton(this.props.alimentsContact)}
          {this.setupRestaurantButton(
            selectedHistory.merchant.contact,
            selectedHistory.dinningMethod,
          )}
          {this.setupOpenMapButton(
            selectedHistory.merchant.coordinate,
            selectedHistory.dinningMethod,
          )}
        </div>
        {['SUCCESS_ON_HOLD', 'SUCCESS'].find(
          value => value === selectedHistory.paymentStatus,
        ) &&
          (isOrderCanceled ? null : (
            <div className='btn btn-receipt' onClick={toggleRequestReceipt}>
              REQUEST RECEIPT
            </div>
          ))}
        {this.setupDriveThruButton(
          selectedHistory.dinningMethod,
          selectedHistory.id,
        )}
      </div>
    );
  }

  setupDriveThruButton(dinningMethod, orderId) {
    return (
      <React.Fragment>
        {dinningMethod && dinningMethod === 'DRIVE_THRU' ? (
          <div
            className={`btn btn-receipt mt-1`}
            onClick={() => this.callImHereDriveThruApi(orderId)}
          >
            I'M HERE
          </div>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  }

  setupAlimentsCallButton(alimentsContact) {
    return (
      <React.Fragment>
        {alimentsContact ? (
          <a
            href={`tel:+${alimentsContact}`}
            className='flex-1 btn tab-btn m-1 flex flex-col'
            style={{ borderRadius: '5px', textDecoration: 'unset' }}
          >
            <img src='/image/phone_icon.png' className='tab-img' />
            HUBBO POS
          </a>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  }

  setupOpenMapButton(coordinate, dinningMethod) {
    // https://waze.com/ul?ll=45.6906304,-120.810983&
    // "https://maps.google.com?q="
    return (
      <React.Fragment>
        {dinningMethod &&
        dinningMethod !== 'DELIVERY' &&
        dinningMethod !== 'DINING' ? (
          <div
            className='btn tab-btn m-1 flex-1 flex flex-col'
            style={{ borderRadius: '5px' }}
            onClick={() =>
              window.open('https://maps.google.com?q=' + coordinate)
            }
          >
            <img src='/image/map_pointer_icon.png' className='tab-img' />
            MAP
          </div>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  }

  setupRestaurantButton(restaurantContact, dinningMethod) {
    return (
      <React.Fragment>
        {dinningMethod && dinningMethod !== 'DELIVERY' ? (
          <div
            className='btn tab-btn m-1 flex-1 flex flex-col'
            style={{ borderRadius: '5px' }}
            onClick={() => {
              window.location.href = `tel:${restaurantContact}`;
            }}
          >
            <img src='/image/phone_icon.png' className='tab-img' />
            RESTAURANT
          </div>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  }

  setupDisplayCancelOrderForDelivery(hasQuotationId, selectedHistory) {
    return (
      // Cancel Button applicable when the paymentStatus is success and deliveryQuotation.orderReceivedDate
      <React.Fragment>
        {hasQuotationId ? (
          //take away or pick up wont go this condition
          (selectedHistory.paymentStatus === 'SUCCESS' ||
            selectedHistory.paymentStatus === 'SUCCESS_ON_HOLD') &&
          !!selectedHistory.deliveryQuotation.orderReceivedDate ? (
            //display when delivery are not cancelled
            !selectedHistory.deliveryQuotation.cancelledDate ? (
              //have own delivery
              selectedHistory.deliveryQuotation.ownDelivery ? (
                <div className='flex mb-4 mt-6'>
                  {selectedHistory.merchant.contact && (
                    <Button
                      isOutline={true}
                      className='flex-1 mx-3'
                      onClick={() => {
                        window.location.href = `tel:${selectedHistory.merchant.contact}`;
                      }}
                      text={'RESTAURANT'}
                    >
                      <FontAwesomeIcon icon={'phone-alt'} className='mr-3' />
                      RESTAURANT
                    </Button>
                  )}
                </div>
              ) : !selectedHistory.deliveryQuotation.driverFoundDate ? ( //display when driver are not found
                <div className='flex mb-4 mt-6'>
                  {this.cancelOrderButton(selectedHistory.id)}
                </div>
              ) : null
            ) : null
          ) : null
        ) : null}
      </React.Fragment>
    );
  }
}
function DeliveryDetail(props) {
  function getDateTimeOrFalse(value) {
    return value && moment(value).format('h:mmA');
  }

  function getDisplayString(list) {
    return list.filter(value => !!value).join(' - ');
  }

  console.log('renderDeliveryDetail', props);
  const quotation = new DeliveryQuotation(props.detail.deliveryQuotation);
  const orderReceivedTime = getDateTimeOrFalse(quotation.orderReceivedDate);
  const driverFoundDate = getDateTimeOrFalse(quotation.driverFoundDate);
  const preparingDate = getDateTimeOrFalse(quotation.preparingDate);
  const driverPickedUpDate = getDateTimeOrFalse(quotation.driverPickedUpDate);
  const deliveredDate = getDateTimeOrFalse(quotation.deliveredDate);
  const ownDelivery = quotation.ownDelivery;
  const customerAddress =
    quotation.customerDeliveryAddress.address +
    ', ' +
    quotation.customerDeliveryAddress.postcode +
    ' ' +
    quotation.customerDeliveryAddress.city +
    ', ' +
    quotation.customerDeliveryAddress.state;

  const cancelDate = getDateTimeOrFalse(quotation.cancelledDate);
  if (cancelDate) {
    return (
      <div className={'p-2'}>
        <DeliveryListTile
          title={'Cancelled'}
          subtitle={getDisplayString([
            cancelDate,
            `We are sorry, your order has not been picked up by our delivery partner,
             Refund will be performed in 2 business days.
             Thank you.`,
          ])}
          isCheck={true}
        />
      </div>
    );
  }

  if (ownDelivery) {
    return (
      <div className='p-2'>
        <DeliveryListTile
          title={'Order Received'}
          subtitle={getDisplayString([
            orderReceivedTime,
            `Your order is received. 
                This order will be directly fulfill by merchant transport team. 
                Kindly liaise with merchant for your order estimated delivery time`,
          ])}
          isCheck={true}
        />
      </div>
    );
  }

  let driverSubTitle = 'Yay! Rider heading to get your order.';

  if (driverFoundDate) {
    driverSubTitle = `${driverFoundDate} - Yay! Rider heading to get your order: ${quotation.partner}(${quotation.driverName})`;
  }

  return (
    <div>
      <div className='p-2'>
        <DeliveryListTile
          title={'Assigning Rider'}
          subtitle={getDisplayString([
            orderReceivedTime,
            'Your order is created successfully, we are looking for a rider',
          ])}
          isCheck={!!orderReceivedTime}
        />
        <Divider />
        <DeliveryListTile
          title={'Rider Found'}
          subtitle={driverSubTitle}
          isCheck={!!driverFoundDate}
        />

        <Divider />
        <DeliveryListTile
          title={'Preparing'}
          subtitle={getDisplayString([
            preparingDate,
            'Your order is being prepared by the restaurant.',
          ])}
          isCheck={!!preparingDate}
        />
        <Divider />
        <DeliveryListTile
          title={'Rider Picked Up'}
          subtitle={getDisplayString([
            driverPickedUpDate,
            'Your order is being delivered to you. ' +
              'Address: ' +
              customerAddress,
          ])}
          isCheck={!!driverPickedUpDate}
        />
        <Divider />
        <Divider />
        <DeliveryListTile
          title={'Delivered'}
          subtitle={getDisplayString([
            deliveredDate,
            'Enjoy your meal and thank you for choosing us.',
          ])}
          isCheck={!!deliveredDate}
        />
      </div>
      {quotation.driverLastLat && quotation.driverLastLng && (
        <DeliveryMap
          driverLatLng={{
            lat: Number(quotation.driverLastLat),
            lng: Number(quotation.driverLastLng),
          }}
          customerLatLng={{
            lat: Number(quotation.customerDeliveryAddress.lat),
            lng: Number(quotation.customerDeliveryAddress.lng),
          }}
        />
      )}
      {!!driverFoundDate ? (
        <div className='flex mb-4 mt-6'>
          {props.detail.merchant.contact && (
            <Button
              isOutline={true}
              className='flex-1 mx-3'
              onClick={() => {
                window.location.href = `tel:${props.detail.merchant.contact}`;
              }}
              text={'RESTAURANT'}
            >
              <FontAwesomeIcon icon={'phone-alt'} className='mr-3' />
              RESTAURANT
            </Button>
          )}
          {quotation.driverContact && (
            <Button
              isOutline={true}
              className='flex-1 mx-3'
              onClick={() => {
                window.location.href = `tel:${quotation.driverContact}`;
              }}
              text={'DRIVER'}
            >
              <FontAwesomeIcon icon={'phone-alt'} className='mr-3' />
              DRIVER
            </Button>
          )}
        </div>
      ) : null}
    </div>
  );
}

function Divider() {
  return (
    <div className='flex items-center justify-center'>
      <div className={'flex items-center justify-center w-5'}>
        <div className='h-3 border-0 border-l lg:border-l-2 border-aliments border-solid'>
          {' '}
        </div>
      </div>
      <div className='flex-1'></div>
    </div>
  );
}

function DeliveryListTile({ className, title, subtitle, isCheck = false }) {
  const icon = isCheck ? 'check-circle' : ['far', 'circle'];

  return (
    <div className='flex flex-row justify-center items-center'>
      <FontAwesomeIcon
        icon={icon}
        className='text-aliments flex-grow-0 text-xl'
      />
      <div className='flex-1 ml-2'>
        <div className='text-base font-bold'>{title}</div>
        <div className='text-sm text-gray-500'>{subtitle}</div>
      </div>
    </div>
  );
}

DeliveryListTile.prorTypes = {
  isCheck: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

HistoryDetailPopup.propTypes = {
  alimentsContact: PropTypes.string,
  selectedHistory: PropTypes.object.isRequired,
  toggleRequestReceipt: PropTypes.func.isRequired,
};
