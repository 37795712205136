import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AppBar from '../../component/appBar/appBar';
import OrderItem from '../../component/orderItem/orderItem';
import {FBPixel, GA} from "../../reducers/menu";
import {path} from "../../services/utils";
import {bindActionCreators} from "redux";
import * as authAction from "../../actions/authAction";

class DynamicOrderPage extends Component {

  constructor() {
    super();
    GA.sendPageView('Dynamic Order Page')
    FBPixel.sendPageView('Dynamic Order Page')
  }

  getCheckOutItems = bySelected => {
    if (!this.props.ticket.ticket) {
      return [];
    }
    const { ticketItemList: itemObjectList } = this.props.ticket.ticket;
    const items = Object.values(itemObjectList);
    return bySelected ? items.filter(i => !i.deselected) : items;
  };

  redirectToMenu = async () => {
    const query = localStorage.getItem('query');
    const {merchantId, ticketId} = JSON.parse(query);

    const merchant = path('menu.menuList.merchant', this.props);
    const menuPage = `/dynamic/menu?merchantId=${merchantId}&ticketId=${ticketId}`;
    const {qrCompulsoryLogin,isQRLoginOrGuest} = merchant;
    const shouldLoginToOrder = isQRLoginOrGuest ? false : qrCompulsoryLogin;

    if (!shouldLoginToOrder) {
      this.props.history.push(menuPage);
      return;
    }

    const user = await this.props.authAction.getUserAccount();

    if (user.code === '200') {
      this.props.history.push(menuPage);
      return;
    }

    this.props.history.goBack();
  };

  render() {
    if (!this.getCheckOutItems().length)
      return (
        <div className='empty-container'>
          <FontAwesomeIcon icon={'cart-plus'} className={'icon'}/>
          <div className='message'>
            Opss.... Your cart seems like empty. <br/>
            Go to menu list to checkout some delicious food.
          </div>
          <div className='button' onClick={this.redirectToMenu}>
            Go to Menu
          </div>
        </div>
      );

    return (
      <div className={'dynamic-order-page'}>
        <AppBar goBack={this.props.history.goBack}>
          <div>Order History</div>
        </AppBar>

        {this.getCheckOutItems().map(x => {
            const addOnPrice = x.selectedAddOns.map(x => x.price)
              .reduce((pre, cur) => pre + cur, 0.0);

            return { ...x, price: x.price + addOnPrice };
          }).sort((a, b) =>
            (a.createdDate > b.createdDate) ? 1 : -1
        ).map((x, i) => (
            <OrderItem
              key={x.name + x.desc + x.addon + i}
              {...x}
              isDisplayMode={true}
            />
          ))}
      </div>
    );
  }
}

DynamicOrderPage.propTypes = {
  ticket: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    ticket: state.Ticket,
    menu: state.Menu,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    authAction: bindActionCreators(authAction, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DynamicOrderPage);
